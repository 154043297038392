import { FC, memo, ReactElement } from "react";
import { IconProps, Icon as IconType, IconSvgProps } from "@Types/global.types";
import { LoadingDots } from "./icon-svgs/32px";
import {
  Hand,
  Zoom,
  Warning,
  QuestionMark,
  Autoscale,
  Crosshair,
  CrosshairGlyph,
  CursorValues,
  CursorValuesGlyph,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Plus,
  Cross,
  AddCircle,
  Chart,
  Scissors,
  EyeOpen,
  EyeClosed,
  Settings,
  Series,
  Minus,
  ChartIcon,
  DragHandle,
  Checkbox,
  CheckboxTicked,
  List,
  ArrowRight,
  Calendar,
  Info,
  DataExport,
  DragIcon,
  ChevronUp,
  AnnotateIcon,
  DrawRangeIcon,
  PinIcon,
  PinnedIcon,
} from "./icon-svgs/16px";
import {
  BankIcon,
  BarCode,
  Cart,
  CompanyIcon,
  DbIcon,
  ListbookWithPicIcon,
  NoteWithDollarMark,
  OrderListIcon,
  Payment,
  PeopleConnentIcon,
  POPPrinting,
  POSWithCardIcon,
  ReceiptIcon,
  WalletIcon,
} from "./icon-svgs/40px";

import { Padlock } from "./icon-svgs/Padlock";
import Heatmap from "./icon-svgs/Heatmap";
import Success from "./icon-svgs/16px/Success";
import Triangle from "./icon-svgs/Triangle";
import TickMark from "./icon-svgs/TickMark";
import { CircleWithArrow } from "./icon-svgs/CircleWithArrow";
import SalesmanIcon from "./icon-svgs/40px/SalesmanIcon";
import EditIcon from "./icon-svgs/40px/EditIcon";
import DeleteBinIcon from "./icon-svgs/16px/DeleteBinIcon";
import CustomerCareIcon from "./icon-svgs/40px/CustomerCareIcon";
import CashierIcon from "./icon-svgs/32px/CashierIcon";
import ReportStatisticsIcon from "./icon-svgs/32px/ReportStatisticsIcon";
import AnalysisSearchIcon from "./icon-svgs/40px/AnalysisSearchIcon";
import AnalyzeIcon from "./icon-svgs/40px/AnalyzeIcon";
import LogIcon from "./icon-svgs/40px/LogIcon";
import ExpireIcon from "./icon-svgs/40px/ExpireIcon";
import DownTrendIcon from "./icon-svgs/40px/DownTrendIcon";
import EmptyBox from "./icon-svgs/40px/EmptyBox";
import StockGraphIcon from "./icon-svgs/40px/StockGraphIcon";
import DashboardIcon from "./icon-svgs/32px/DashboardIcon";
import TruckIcon from "./icon-svgs/32px/TruckIcon";
import CartWithProductIcon from "./icon-svgs/32px/CartWithProductIcon";
import CategoriesIcon from "./icon-svgs/32px/CategoriesIcon";
import TaxLawIcon from "./icon-svgs/40px/TaxLawIcon";
import SalesReturnIcon from "./icon-svgs/40px/SalesReturnIcon";
import ShopIcon from "./icon-svgs/40px/ShopIcon";
import PurchaseOrderIcon from "./icon-svgs/40px/PurchaseOrderIcon";
import PrinterIcon from "./icon-svgs/40px/PrinterIcon";

const iconVariants: {
  [k in IconType]: FC<IconSvgProps> | ((p: IconSvgProps) => ReactElement);
} = {
  PrinterIcon,
  PurchaseOrderIcon,
  ShopIcon,
  SalesReturnIcon,
  TaxLawIcon,
  CategoriesIcon,
  CartWithProductIcon,
  TruckIcon,
  DashboardIcon,
  StockGraphIcon,
  EmptyBox,
  DownTrendIcon,
  ExpireIcon,
  LogIcon,
  AnalyzeIcon,
  AnalysisSearchIcon,
  ReportStatisticsIcon,
  CashierIcon,
  CustomerCareIcon,
  DeleteBinIcon,
  Cart,
  BarCode,
  bank: BankIcon,
  WalletIcon,
  SalesmanIcon,
  EditIcon,
  DbIcon,
  ReceiptIcon,
  POSWithCardIcon,
  POPPrinting,
  PeopleConnentIcon,
  Payment,
  OrderListIcon,
  NoteWithDollarMark,
  ListbookWithPicIcon,
  CompanyIcon,
  "loading-dots": LoadingDots,
  "magnifying-glass": Zoom,
  warning: Warning,
  scale: Autoscale,
  checkbox: Checkbox,
  "checkbox-ticked": CheckboxTicked,
  crosshair: Crosshair,
  "crosshair-glyph": CrosshairGlyph,
  "cursor-values": CursorValues,
  "cursor-values-glyph": CursorValuesGlyph,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  plus: Plus,
  cross: Cross,
  "add-circle": AddCircle,
  chart: Chart,
  hand: Hand,
  scissors: Scissors,
  "eye-open": EyeOpen,
  "eye-closed": EyeClosed,
  settings: Settings,
  series: Series,
  padlock: Padlock,
  success: Success,
  error: Warning,
  info: Info,
  minus: Minus,
  questionMark: QuestionMark,
  chartIcon: ChartIcon,
  dragHandle: DragHandle,
  list: List,
  "arrow-right": ArrowRight,
  heatmap: Heatmap,
  triangle: Triangle,
  "tick-mark": TickMark,
  "circle-with-arrow": CircleWithArrow,
  calendar: Calendar,
  "data-export": DataExport,
  drag: DragIcon,
  "chevron-up": ChevronUp,
  annotate: AnnotateIcon,
  "draw-range": DrawRangeIcon,
  pin: PinIcon,
  pinned: PinnedIcon,
};

const Icon = ({ icon = "hand", ...rest }: IconProps): ReactElement => {
  const IconSvg = iconVariants[icon];

  return <IconSvg {...rest} />;
};

export default memo(Icon);
