import styled from "styled-components";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";

export const CustomFieldSet = styled(Fieldset)`
  width: 100%;
`;

export const SearchCustomer = styled(InputText)`
  width: 100%;
  height: 50px;
  font-weight: bolder;
  font-size: 20px;
  color: ${({ theme }) => theme.fill1};
`;

export const SearchFieldWrapper = styled.div`
  width: 100%;
  margin: 7px 0;
`;
