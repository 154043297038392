import { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  IFilter,
  INetworkResponse,
  IProduct,
  ToastLife,
} from "@Types/global.types";
import { FilterMatchMode } from "primereact/api";
import { ISearcher, PageView } from "@Pages/Products/Products";
import Alert from "@Atoms/Alert/Alert";
import { setItem } from "@Utils/cache";
import { KEYS } from "@Hooks/useCashierCache/useCashierCache";

import { utils, write } from "xlsx";
import { saveAs } from "file-saver";
import { deleteRequest, getRequest, postRequest } from "../../axios/requests";

interface IUseProduct {
  toast?: MutableRefObject<null>;
  loading: boolean;
  productRef?: MutableRefObject<null>;
  products: IProduct[];
  filters: IFilter;
  setFilters: (value: ((prevState: IFilter) => IFilter) | IFilter) => void;
  handleDeleteProduct: (rowData: IProduct) => void;
  isDeleteLoading: boolean;
  activeId: string | null;
  handleFetchProducts: (cb?: (data: IProduct[]) => void) => void;
  exportCSV: (selectionOnly: boolean) => void;
  exportExcel: () => void;
}

export const useProducts = (
  options: { disableAPICall: boolean },
  toast?: MutableRefObject<null>,
  searcher?: ISearcher,
  refetchProducts?: boolean,
  type: string = PageView.viewAll
): IUseProduct => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const productRef = useRef(null);
  const [filters, setFilters] = useState<IFilter>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const deleteProductById = (id: string) => deleteRequest(`/products/${id}`);

  const fetchProducts = () => {
    if (type === PageView.outOfStock)
      return getRequest("/products/filter/out-of-stock");

    if (type === PageView.lowStock)
      return getRequest("/products/filter/low-in-stock");

    if (type === PageView.expiredProduct)
      return getRequest("/products/filter/expired-products");

    if (searcher && searcher.trigger && searcher.barcode)
      return postRequest("/products/search/by/barcode", {
        barcode: searcher.barcode,
      });

    return getRequest("/products");
  };

  const handleFetchProducts = (cb?: (data: IProduct[]) => void) => {
    setLoading(true);
    fetchProducts()
      .then((response) => {
        setProducts(response.data);
        cb?.(response.data);
        setItem(KEYS.PRODUCT, JSON.stringify(response.data));
        setLoading(false);
      })
      .catch((error) => {
        setProducts([]);
        setLoading(false);
        if (toast) {
          toast?.current.show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: ToastLife,
          });
        }
      });
  };

  const handleDeleteProduct = (rowData: IProduct) => {
    Alert(() => {
      setActiveId(rowData._id);
      setIsDeleteLoading(true);
      deleteProductById(rowData._id)
        .then((response) => {
          handleFetchProducts();
          setIsDeleteLoading(false);
          setActiveId(null);
          if (toast) {
            toast?.current.show({
              severity: "success",
              summary: "Success",
              detail: (response as INetworkResponse)?.message,
              life: ToastLife,
            });
          }
        })
        .catch((error) => {
          setActiveId(null);
          setIsDeleteLoading(false);
          if (toast) {
            toast?.current.show({
              severity: "error",
              summary: "Error",
              detail: error.message,
              life: ToastLife,
            });
          }
        });
    });
  };

  useEffect(() => {
    if (options.disableAPICall) return;
    handleFetchProducts();
  }, []);

  useEffect(() => {
    if (refetchProducts) {
      handleFetchProducts();
    }
  }, [refetchProducts]);

  useEffect(() => {
    if (searcher?.trigger && searcher.barcode) {
      handleFetchProducts();
    }
  }, [searcher?.trigger, searcher?.barcode]);

  const exportCSV = (selectionOnly: boolean) => {
    productRef.current.exportCSV({ selectionOnly });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });

    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`
    );
  };

  const exportExcel = () => {
    const worksheet = utils.json_to_sheet(products);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAsExcelFile(excelBuffer, `product-list-${new Date().toISOString()}`);
  };

  return {
    products,
    loading,
    toast,
    filters,
    activeId,
    isDeleteLoading,
    productRef,
    setFilters,
    handleDeleteProduct,
    handleFetchProducts,
    exportCSV,
    exportExcel,
  };
};
