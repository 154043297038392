import { getCompanyInfo } from "@Utils/get-login-user";

const CurrencyWrapper = ({ children, height = "40px" }) => {
  const { currency } = getCompanyInfo();

  return (
    <div className="p-inputgroup flex-1">
      <span className="p-inputgroup-addon" style={{ height }}>
        {currency?.symbol || "₦"}
      </span>
      {children}
    </div>
  );
};

export default CurrencyWrapper;
