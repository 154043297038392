import { IconSizes, IconSvgProps } from "@Types/global.types";
import { ReactElement } from "react";

const PrinterIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 108.36 122.88"
    fill={colour}>
    <path d="M62.64,64.67a3.57,3.57,0,1,1-3.58,3.57,3.57,3.57,0,0,1,3.58-3.57ZM17.32,0H91.05A7.17,7.17,0,0,1,98.2,7.15v9.74h3.26a6.87,6.87,0,0,1,4.87,2h0a6.86,6.86,0,0,1,2,4.86v39.6a6.9,6.9,0,0,1-6.9,6.9H92.09v48.49a4.1,4.1,0,0,1-1.2,2.9,3.27,3.27,0,0,1-.35.3,4.08,4.08,0,0,1-2.55.9H20.37a4.07,4.07,0,0,1-2.89-1.2,3.27,3.27,0,0,1-.3-.35,4,4,0,0,1-.91-2.55V70.29H6.9a6.86,6.86,0,0,1-4.87-2L1.82,68A6.85,6.85,0,0,1,0,63.39V23.79a6.93,6.93,0,0,1,2-4.88l.23-.2A6.85,6.85,0,0,1,6.9,16.89h3.27V7.15a7.13,7.13,0,0,1,2.1-5.05h0A7.09,7.09,0,0,1,17.32,0ZM92.7,16.89V7.15A1.67,1.67,0,0,0,91.05,5.5H17.32A1.64,1.64,0,0,0,16.16,6h0a1.6,1.6,0,0,0-.48,1.16v9.74ZM31.24,99.18a2.13,2.13,0,1,1,0-4.25H75.62a2.13,2.13,0,1,1,0,4.25Zm0,10.84a2.13,2.13,0,0,1,0-4.26H55.37a2.13,2.13,0,0,1,0,4.26Zm44.9-20.79H32.22A3,3,0,0,1,29.3,86.3V59.66a2.92,2.92,0,0,1,2.92-2.92H76.14a3,3,0,0,1,2.93,2.92V86.3a3,3,0,0,1-2.93,2.93Zm-43-3.88h6l8.63-14.09a1.65,1.65,0,0,1,2.8,0l8.82,14.09h1.3L57.4,79.93l2.93-4.7a1.42,1.42,0,0,1,2.4,0l6.34,10.12h6.12V60.62h-42V85.35ZM6.23,44.5h95.9V23.79a.65.65,0,0,0-.19-.47.68.68,0,0,0-.48-.2H6.9a.68.68,0,0,0-.41.14l-.06.06a.67.67,0,0,0-.2.47V44.5Zm95.9,6.24h-10V64.06h9.37a.68.68,0,0,0,.67-.67V50.74Zm-85.84,0H6.23V63.39a.64.64,0,0,0,.14.41l.06.06a.67.67,0,0,0,.47.2h9.37l0-13.32Zm70.31,0H21.76v65.91H86.6V50.74Z" />
  </svg>
);

export default PrinterIcon;
