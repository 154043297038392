import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const CreateForm = styled.form`
  padding: 0;
  width: 100%;
`;

export const ButtonWrapper = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.space(2)};
  padding: ${({ theme }) => theme.space(1)} 0;
  justify-content: center;
`;

export const InputTextWrapper = styled.div`
  margin: 30px 0 40px 0;
`;

export const FieldsWrapper = styled.div<{ isSpaced?: boolean }>`
  display: flex;
  gap: ${({ isSpaced }) => isSpaced && "20px"};
`;

export const FieldWrapper = styled.div`
  margin: 10px 0;
`;
