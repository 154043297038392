import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { chevronIconLabel } from "../../text";

const ChevronRight = ({ colour, className }: IconSvgProps): ReactElement => (
  <svg
    aria-label={chevronIconLabel}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}>
    <path fill={colour} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
);

export default ChevronRight;
