import { FC } from "react";
import { IconSvgProps } from "@Types/global.types";

export const CircleWithArrow: FC<IconSvgProps> = ({
  colour = "white",
  size = 16,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.71155 13.9966C0.777918 6.59632 6.83086 0.650986 14.2312 0.717353C21.6315 0.783722 27.5768 6.83667 27.5105 14.237C27.4441 21.6373 21.3912 27.5826 13.9908 27.5163C6.59052 27.4499 0.645181 21.397 0.71155 13.9966Z"
      fill="#0C0C0C"
      stroke={colour}
    />
    <path
      d="M14.2972 7.53143L22.0319 17.0924L6.57054 17.0989L14.2972 7.53143Z"
      fill={colour}
    />
    <path
      d="M0.71393 14.0796C0.732812 6.679 6.74748 0.694945 14.1481 0.713825C21.5487 0.732707 27.5327 6.74738 27.5138 14.148C27.495 21.5486 21.4803 27.5326 14.0797 27.5137C6.67911 27.4949 0.695048 21.4802 0.71393 14.0796Z"
      fill="#0C0C0C"
      stroke={colour}
    />
    <mask
      id="mask0_89_3724"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="21"
      height="21">
      <rect
        width="20"
        height="20"
        transform="matrix(-0.000759843 1 1 0.000759843 4.19946 4.02795)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_89_3724)">
      <path
        d="M22.5226 17.3752L14.1956 9.03551L5.85597 17.3625L7.33401 18.8428L14.1934 11.9938L21.0423 18.8532L22.5226 17.3752Z"
        fill={colour}
      />
    </g>
  </svg>
);
