import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { checkboxIconLabel } from "../../text";

const Checkbox = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    aria-label={checkboxIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 20"
    width={size}
    height={size}>
    <g fill={colour}>
      <path
        d="M12.9248 1.75V12.25H2.4248V1.75H12.9248ZM12.9248 0.25H2.4248C1.5998 0.25 0.924805 0.925 0.924805 1.75V12.25C0.924805 13.075 1.5998 13.75 2.4248 13.75H12.9248C13.7498 13.75 14.4248 13.075 14.4248 12.25V1.75C14.4248 0.925 13.7498 0.25 12.9248 0.25Z"
        fill={colour}
      />
    </g>
  </svg>
);

export default Checkbox;
