import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { autoScaleIconLabel } from "../../text";

const Autoscale = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={autoScaleIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16">
    <g strokeWidth="1" fill={colour} stroke={colour}>
      <rect
        data-color="color-2"
        x="4.5"
        y="4.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        width="7"
        height="7"
      />
      <path
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M0.5,5V1.5 c0-0.552,0.448-1,1-1H5"
      />
      <path
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M11,0.5h3.5 c0.552,0,1,0.448,1,1V5"
      />
      <path
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M15.5,11v3.5 c0,0.552-0.448,1-1,1H11"
      />
      <path
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M5,15.5H1.5 c-0.552,0-1-0.448-1-1V11"
      />
    </g>
  </svg>
);

export default Autoscale;
