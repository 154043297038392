import { ToastLife } from "@Types/global.types";
import { MutableRefObject } from "react";
import { getRequest } from "../../axios/requests";

interface IUseCompanyInfoProps {
  toast?: MutableRefObject<null>;
}

interface IUseCompanyInfo {
  handleFetchCompanyInfo: () => void;
}
export const useCompanyInfo = (
  props: IUseCompanyInfoProps
): IUseCompanyInfo => {
  const fetchCompanyInfo = () => getRequest("/company/by/my-company");

  const { toast } = props;

  const handleFetchCompanyInfo = async () => {
    fetchCompanyInfo()
      .then((response) => {
        sessionStorage.setItem(
          process.env.CACHED_COMPANY_INFO,
          JSON.stringify(response.data)
        );
      })
      .catch((error) => {
        if (toast) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: ToastLife,
          });
        }
      });
  };

  return {
    handleFetchCompanyInfo,
  };
};
