import styled from "styled-components";

export const LoadingOrErrorContainer = styled("div")`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${({ theme }) => theme.space(1)};
`;

export const LoadingContainer = styled("div")`
  opacity: 0;
  animation: fadeIn 0.1s forwards;
  animation-delay: 0.3s;
`;

export const ErrorContainer = styled("div")`
  display: grid;
  align-items: flex-start;
  grid-template-columns: ${({ theme }) => theme.space(2)} 1fr;
  grid-gap: ${({ theme }) => theme.space(1)};
`;
