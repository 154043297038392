import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { listIconLabel } from "../../text";

const List = ({ colour, size = IconSizes.SM }: IconSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 36 31"
    aria-hidden="true"
    aria-label={listIconLabel}
    fill={colour}>
    <path d="M32.6396 0H4.20215C2.38994 0 0.920898 1.46904 0.920898 3.28125V27.3438C0.920898 29.156 2.38994 30.625 4.20215 30.625H32.6396C34.4519 30.625 35.9209 29.156 35.9209 27.3438V3.28125C35.9209 1.46904 34.4519 0 32.6396 0ZM32.2295 27.3438H4.6123C4.50352 27.3438 4.3992 27.3005 4.32228 27.2236C4.24536 27.1467 4.20215 27.0424 4.20215 26.9336V3.69141C4.20215 3.58263 4.24536 3.4783 4.32228 3.40138C4.3992 3.32446 4.50352 3.28125 4.6123 3.28125H32.2295C32.3383 3.28125 32.4426 3.32446 32.5195 3.40138C32.5964 3.4783 32.6396 3.58263 32.6396 3.69141V26.9336C32.6396 27.0424 32.5964 27.1467 32.5195 27.2236C32.4426 27.3005 32.3383 27.3438 32.2295 27.3438ZM29.3584 21.0547V22.6953C29.3584 23.1483 28.9911 23.5156 28.5381 23.5156H14.8662C14.4132 23.5156 14.0459 23.1483 14.0459 22.6953V21.0547C14.0459 20.6017 14.4132 20.2344 14.8662 20.2344H28.5381C28.9911 20.2344 29.3584 20.6017 29.3584 21.0547ZM29.3584 14.4922V16.1328C29.3584 16.5858 28.9911 16.9531 28.5381 16.9531H14.8662C14.4132 16.9531 14.0459 16.5858 14.0459 16.1328V14.4922C14.0459 14.0392 14.4132 13.6719 14.8662 13.6719H28.5381C28.9911 13.6719 29.3584 14.0392 29.3584 14.4922ZM29.3584 7.92969V9.57031C29.3584 10.0233 28.9911 10.3906 28.5381 10.3906H14.8662C14.4132 10.3906 14.0459 10.0233 14.0459 9.57031V7.92969C14.0459 7.47667 14.4132 7.10938 14.8662 7.10938H28.5381C28.9911 7.10938 29.3584 7.47667 29.3584 7.92969ZM12.1318 8.75C12.1318 10.1091 11.03 11.2109 9.6709 11.2109C8.31178 11.2109 7.20996 10.1091 7.20996 8.75C7.20996 7.39088 8.31178 6.28906 9.6709 6.28906C11.03 6.28906 12.1318 7.39088 12.1318 8.75ZM12.1318 15.3125C12.1318 16.6716 11.03 17.7734 9.6709 17.7734C8.31178 17.7734 7.20996 16.6716 7.20996 15.3125C7.20996 13.9534 8.31178 12.8516 9.6709 12.8516C11.03 12.8516 12.1318 13.9534 12.1318 15.3125ZM12.1318 21.875C12.1318 23.2341 11.03 24.3359 9.6709 24.3359C8.31178 24.3359 7.20996 23.2341 7.20996 21.875C7.20996 20.5159 8.31178 19.4141 9.6709 19.4141C11.03 19.4141 12.1318 20.5159 12.1318 21.875Z" />
  </svg>
);

export default List;
