import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const DownTrendIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5C5 4.44772 4.55228 4 4 4C3.44772 4 3 4.44772 3 5V6.99983C3 
        6.99994 3 7.00006 3 7.00017L3 19C3 19.5523 3.44772 20 4 
        20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 
        18H5V9.41421L8.29289 12.7071C8.68342 13.0976 9.31658 
        13.0976 9.70711 12.7071L13 9.41421L17.5858 14H15C14.4477 14 14 
        14.4477 14 15C14 15.5523 14.4477 16 15 16H20C20.5523 16 21 15.5523 
        21 15V10C21 9.44772 20.5523 9 20 9C19.4477 9 19 9.44772 19 
        10V12.5858L13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 
        7.29289L9 10.5858L5 6.58579V5Z"
      fill={colour}
    />
  </svg>
);

export default DownTrendIcon;
