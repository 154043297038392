import { NumericFormat } from "react-number-format";
import { getCompanyInfo } from "@Utils/get-login-user";

const FormatCurrency = (props: {
  inputValue: number;
  decimalPlaces?: number;
}) => {
  const { currency } = getCompanyInfo();

  const { inputValue, decimalPlaces = 2 } = props;

  if (Number.isNaN(inputValue)) return inputValue;

  return (
    <NumericFormat
      value={Number(inputValue)?.toFixed(decimalPlaces)}
      displayType="text"
      thousandSeparator
      prefix={currency?.symbol || "₦"}
    />
  );
};

export default FormatCurrency;
