import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { calendarIconLabel } from "../../text";

const Calendar = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 15 17"
    aria-hidden="true"
    aria-label={calendarIconLabel}
    fill={colour}>
    <path d="M3.96191 9.65039C3.75391 9.65039 3.57691 9.57739 3.43091 9.43139C3.28491 9.28539 3.21191 9.10839 3.21191 8.90039C3.21191 8.69239 3.28491 8.51539 3.43091 8.36939C3.57691 8.22339 3.75391 8.15039 3.96191 8.15039C4.16991 8.15039 4.34691 8.22339 4.49291 8.36939C4.63891 8.51539 4.71191 8.69239 4.71191 8.90039C4.71191 9.10839 4.63891 9.28539 4.49291 9.43139C4.34691 9.57739 4.16991 9.65039 3.96191 9.65039ZM7.21191 9.65039C7.00391 9.65039 6.82691 9.57739 6.68091 9.43139C6.53491 9.28539 6.46191 9.10839 6.46191 8.90039C6.46191 8.69239 6.53491 8.51539 6.68091 8.36939C6.82691 8.22339 7.00391 8.15039 7.21191 8.15039C7.41991 8.15039 7.59691 8.22339 7.74291 8.36939C7.88891 8.51539 7.96191 8.69239 7.96191 8.90039C7.96191 9.10839 7.88891 9.28539 7.74291 9.43139C7.59691 9.57739 7.41991 9.65039 7.21191 9.65039ZM10.4619 9.65039C10.2539 9.65039 10.0769 9.57739 9.93091 9.43139C9.78491 9.28539 9.71191 9.10839 9.71191 8.90039C9.71191 8.69239 9.78491 8.51539 9.93091 8.36939C10.0769 8.22339 10.2539 8.15039 10.4619 8.15039C10.6699 8.15039 10.8469 8.22339 10.9929 8.36939C11.1389 8.51539 11.2119 8.69239 11.2119 8.90039C11.2119 9.10839 11.1389 9.28539 10.9929 9.43139C10.8469 9.57739 10.6699 9.65039 10.4619 9.65039ZM1.71191 16.1504C1.29525 16.1504 0.941247 16.0011 0.649914 15.7024C0.357914 15.4037 0.211914 15.0531 0.211914 14.6504V3.65039C0.211914 3.24772 0.357914 2.89706 0.649914 2.59839C0.941247 2.29972 1.29525 2.15039 1.71191 2.15039H3.21191V0.150391H4.71191V2.15039H9.71191V0.150391H11.2119V2.15039H12.7119C13.1286 2.15039 13.4826 2.29972 13.7739 2.59839C14.0659 2.89706 14.2119 3.24772 14.2119 3.65039V14.6504C14.2119 15.0531 14.0659 15.4037 13.7739 15.7024C13.4826 16.0011 13.1286 16.1504 12.7119 16.1504H1.71191ZM1.71191 14.6504H12.7119V7.15039H1.71191V14.6504Z" />
  </svg>
);

export default Calendar;
