import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { crosshairIconLabel } from "../../text";

const CrosshairGlyph = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={crosshairIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16">
    <g fill={colour}>
      <circle cx="8" cy="8" r="2" data-color="color-2" />
      <rect x="7" width="2" height="4" fill={colour} />
      <rect x="12" y="7" width="4" height="2" fill={colour} />
      <rect x="7" y="12" width="2" height="4" fill={colour} />
      <rect y="7" width="4" height="2" fill={colour} />
    </g>
  </svg>
);

export default CrosshairGlyph;
