import { Calendar } from "primereact/calendar";
import styled from "styled-components";

export const PurchaseInvoicesWrapper = styled.div``;

export const PageWrapper = styled.div`
  display: flex;
  overflow: auto;
  height: 100%;
  gap: 15px;
  width: 100%;
  padding-top: 10px;
`;

export const CreateSupplier = styled.div`
  padding-top: 10px;
`;

export const CalendarWrapper = styled(Calendar)`
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.fill1};
`;
