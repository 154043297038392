import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { successIconLabel } from "../../text";

const Success = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    aria-label={successIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill={colour}
      d="M10 0.771484C4.48 0.771484 0 5.25148 0 10.7715C0 16.2915 4.48 20.7715 10 20.7715C15.52 20.7715 20 16.2915 20 10.7715C20 5.25148 15.52 0.771484 10 0.771484ZM10 18.7715C5.59 18.7715 2 15.1815 2 10.7715C2 6.36148 5.59 2.77148 10 2.77148C14.41 2.77148 18 6.36148 18 10.7715C18 15.1815 14.41 18.7715 10 18.7715ZM14.59 6.35148L8 12.9415L5.41 10.3615L4 11.7715L8 15.7715L16 7.77148L14.59 6.35148Z"
    />
  </svg>
);

export default Success;
