import { ReactElement } from "react";
import { getLoginUser } from "@Utils/get-login-user";
import { Navigate } from "react-router-dom";
import { PageUrl } from "@Types/global.types";

const PreventVisitComponent = ({ children }: { children: ReactElement }) => {
  const { role } = getLoginUser();
  const token = sessionStorage.getItem(process.env.TOKEN_NAME);

  return token && role ? <Navigate to={PageUrl.Home} /> : <>{children}</>;
};

export default PreventVisitComponent;
