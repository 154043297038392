import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const DashboardIcon = ({
  colour = "white",
  size = IconSizes.XL,
}: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="dashboard-alt">
    <path d="M14,10V22H4a2,2,0,0,1-2-2V10Z" />
    <path d="M22,10V20a2,2,0,0,1-2,2H16V10Z" />
    <path d="M22,4V8H2V4A2,2,0,0,1,4,2H20A2,2,0,0,1,22,4Z" />
  </svg>
);

export default DashboardIcon;
