import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";

const Heatmap = ({ colour, size }: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.11364 2.25H15.8864C16.6395 2.25 17.25 2.92157 17.25 3.75V12.75C17.25 13.5784 16.6395 14.25 15.8864 14.25H2.11364C1.36052 14.25 0.75 13.5784 0.75 12.75V3.75C0.75 2.92157 1.36052 2.25 2.11364 2.25ZM2.25 3.75V12.75H15.75V3.75H2.25ZM3.75 9.75V11.25H5.25V9.75H3.75ZM6 11.25V9.75H8.25V11.25H6ZM12.75 9.75V11.25H14.25V9.75H12.75ZM10.5 11.25V9.75H12V11.25H10.5ZM6 6H3.75V8.25H6V6Z"
      fill={colour}
    />
  </svg>
);

export default Heatmap;
