import styled from "styled-components";

export const LoginCopyrightWrapper = styled.div`
  position: absolute;
  bottom: 0;
  padding: 20px;
  width: 100%;
  color: ${({ theme }) => theme.text1};
  text-align: center;
  z-index: 1;
`;
