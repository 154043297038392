import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const TaxLawIcon = ({
  colour = "white",
  size = IconSizes.XL,
}: IconSvgProps): ReactElement => (
  <svg
    stroke="#000"
    height="60px"
    width="60px"
    fill="#fff"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 122.88 120.44">
    <g>
      <path d="M93.64,27.96c2.97,2.97,3.03,7.77,0.14,10.66l-1.57,1.57l14.51,14.29L74.88,85.15L60.71,70.53l-47.78,47.78 c-2.89,2.89-7.69,2.83-10.66-0.14c-2.97-2.97-3.03-7.77-0.14-10.66l47.95-47.95l-12-12.38l30.08-30.67l13.17,12.97l1.65-1.65 C85.88,24.94,90.67,24.99,93.64,27.96L93.64,27.96z M73.1,38.26l-2.73,2.73l-2.21-2.21L57.25,49.7l-3.76-3.76l10.92-10.92 l-2.23-2.23l2.73-2.73L73.1,38.26L73.1,38.26z M79.7,44.86L68.2,60.65l-3.84-3.83l2.25-2.65l-1.35-1.35l-2.65,2.25l-3.88-3.88 l15.55-11.74L79.7,44.86L79.7,44.86z M68.93,51.65c1.37-1.73,3.09-3.83,5.18-6.32c-2.99,2.25-5.14,3.93-6.45,5.04L68.93,51.65 L68.93,51.65z M90.55,55.7l-7.35,4.72l-5.62,9.6l-3.47-3.47c0.95-1.78,2.23-3.82,3.83-6.13c-0.98,0.77-2.03,1.55-3.17,2.34 l-2.56,1.89l-3.65-3.65l8.98-6.24l4.67-7.4l3.63,3.63c-0.3,0.45-0.8,1.21-1.5,2.27c-0.52,0.82-1.01,1.56-1.47,2.23l4.54-2.93 L90.55,55.7L90.55,55.7z M66.87,1.8c2.36,2.36,2.41,6.18,0.11,8.48L31.88,45.38c-2.3,2.3-6.12,2.25-8.48-0.11 c-2.36-2.36-2.41-6.18-0.11-8.48L58.38,1.7C60.69-0.61,64.5-0.56,66.87,1.8L66.87,1.8L66.87,1.8z M121.08,56.01 c2.36,2.36,2.41,6.18,0.11,8.48L86.09,99.59c-2.3,2.3-6.12,2.25-8.48-0.11C75.24,97.12,75.2,93.3,77.5,91l35.09-35.09 C114.9,53.6,118.71,53.65,121.08,56.01L121.08,56.01z" />
    </g>
  </svg>
);

export default TaxLawIcon;
