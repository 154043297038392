import { Card } from "primereact/card";
import {
  CardWrapper,
  BootstrapContainer,
  BootstrapHeader,
  BootstrapWrapper,
  GridContent,
  GridContentButtonWrapper,
  HomeWrapper,
} from "@Pages/BootstrapPage/styles";
import Text from "@Core/Text";
import LoginCopyright from "@Atoms/LoginCopyright";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import Timer from "@Atoms/Timer";
import { getCompanyName } from "@Utils/general-utils";

import Icon from "@Core/Icon/Icon";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { PageUrl } from "@Types/global.types";
import Modal from "@Atoms/Modal";
import SetupCompany from "@Pages/BootstrapPage/SetupCompany";
import { Button } from "primereact/button";
import Logo from "@Atoms/Logo";
import { postRequest } from "../../../axios/requests";

const BootstrapPage = () => {
  const toast = useRef(null);
  const [showData, setShowData] = useState(false);
  const [setupCompany, setSetupCompany] = useState(false);
  const [companyExist, setCompanyExist] = useState(true);
  const [loginInfo, setLoginInfo] = useState<
    { code: string; email: string } | undefined
  >();
  const [loading, setLoading] = useState(false);
  const countCompany = () => postRequest("/company/check-existence", {});

  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleCountCompany = () => {
    setLoading(true);
    countCompany()
      .then((response) => {
        setLoading(false);
        if (response.data > 0) {
          setCompanyExist(true);
        } else {
          setCompanyExist(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCompleteSetup = (data) => {
    setLoginInfo(data);
    // show modal to display new company Secret and Email
    setShowData(true);
  };

  useEffect(() => {
    handleCountCompany();
  }, []);

  return (
    <BootstrapContainer>
      <Logo />
      <BootstrapWrapper>
        {loading ? (
          <div style={{ textAlign: "center", fontSize: "55px" }}>
            <i
              className="pi pi-spinner pi-spin pi-2x"
              style={{ fontSize: "55px" }}
            />

            <span>&nbsp;Loading...</span>
          </div>
        ) : (
          <CardWrapper>
            <Card
              header={<BootstrapHeader>Startup page</BootstrapHeader>}
              title={getCompanyName()}
              subTitle={
                <Text bold fontSize="30px" noStack>
                  <Timer />
                </Text>
              }
              style={{ minWidth: "40vw", zIndex: "999" }}>
              {companyExist ? (
                <HomeWrapper>
                  <GridContent
                    className="btn-1"
                    onClick={() => handleNavigate(PageUrl.Login)}>
                    <GridContentButtonWrapper>
                      <Icon icon="CompanyIcon" colour={theme.text1} />
                      <Text marginTop="10px">Cashier Login</Text>
                    </GridContentButtonWrapper>
                  </GridContent>
                  <GridContent
                    className="btn-1"
                    onClick={() => handleNavigate(PageUrl.AdminLogin)}>
                    <GridContentButtonWrapper>
                      <Icon icon="CompanyIcon" colour={theme.text1} />
                      <Text marginTop="10px">Store Admin Login</Text>
                    </GridContentButtonWrapper>
                  </GridContent>
                  <GridContent
                    className="btn-1"
                    onClick={() => handleNavigate(PageUrl.AdminLogin)}>
                    <GridContentButtonWrapper>
                      <Icon icon="CompanyIcon" colour={theme.text1} />
                      <Text marginTop="10px">Warehouse Admin Login</Text>
                    </GridContentButtonWrapper>
                  </GridContent>
                  <GridContent
                    className="btn-1"
                    onClick={() => handleNavigate(PageUrl.SuperLogin)}>
                    <GridContentButtonWrapper>
                      <Icon icon="CompanyIcon" colour={theme.text1} />
                      <Text marginTop="10px">Company Admin Login</Text>
                    </GridContentButtonWrapper>
                  </GridContent>
                </HomeWrapper>
              ) : (
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  <GridContent
                    className="btn-2"
                    onClick={() => setSetupCompany(true)}>
                    <GridContentButtonWrapper>
                      <Icon icon="CompanyIcon" colour={theme.text1} />
                      <Text marginTop="10px">Setup new Company</Text>
                    </GridContentButtonWrapper>
                  </GridContent>
                </div>
              )}
            </Card>
          </CardWrapper>
        )}
      </BootstrapWrapper>
      <LoginCopyright />
      <Toast ref={toast} />
      {setupCompany && (
        <div id="modal-setup-company">
          <Modal
            isOpen={setupCompany}
            position="top"
            title={() => (showData ? "Login Information" : "Setup new company")}
            width={showData ? "40vw" : "90vw"}
            isMaximizable={!showData}
            onCancel={
              showData
                ? () => navigate(PageUrl.SuperLogin)
                : () => setSetupCompany(false)
            }>
            {showData ? (
              <div>
                <Text h3 color="green">
                  Company setup successful!
                </Text>
                <Text p bold>
                  Make sure to keep your secret code safe. It will be required
                  in the process to login to the portal
                </Text>
                <div>
                  <Text>
                    EMAIL:{" "}
                    <b style={{ fontSize: "18px" }}>{loginInfo?.email}</b>
                  </Text>
                  <Text>
                    PASSWORD:{" "}
                    <b style={{ fontSize: "18px" }}>*********************</b>
                  </Text>
                  <Text>
                    SECRET CODE:{" "}
                    <b style={{ fontSize: "18px" }}>{loginInfo?.code}</b>
                  </Text>
                </div>
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                  <Button
                    label="GO TO LOGIN"
                    severity="success"
                    onClick={() => navigate(PageUrl.SuperLogin)}
                  />
                </div>
              </div>
            ) : (
              <SetupCompany onCloseModal={handleCompleteSetup} />
            )}
          </Modal>
        </div>
      )}
    </BootstrapContainer>
  );
};

export default BootstrapPage;
