import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const PeopleConnentIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="5.8" cy="16.32" r="2.39" />
    <path d="M1.5,23.48V23a4.3,4.3,0,0,1,4.3-4.3h0A4.3,4.3,0,0,1,10.09,23v.48" />
    <circle cx="18.2" cy="3.91" r="2.39" />
    <path d="M13.91,11.07v-.48A4.29,4.29,0,0,1,18.2,6.3h0a4.29,4.29,0,0,1,4.3,4.29v.48" />
    <polyline points="15.7 15.72 18.68 12.75 21.66 15.72" />
    <path d="M18.68,13v2.86A5.73,5.73,0,0,1,13,21.57h0" />
    <polyline points="8.3 8.32 5.32 11.3 2.34 8.32" />
    <path d="M5.32,11.07V8.2a5.72,5.72,0,0,1,5.73-5.72h0" />
  </svg>
);

export default PeopleConnentIcon;
