import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { seriesLabel } from "../../text";

const Series = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={seriesLabel}
    aria-hidden="true"
    role="img"
    width="16"
    height="16"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24">
    <path
      d="M2 15s0-6 6-6c4 0 4.5 3.5 7.5 3.5c4 0 4-3.5 4-3.5H22s0 6-6 6c-4 0-5.5-3.5-7.5-3.5c-4 0-4 3.5-4 3.5H2"
      fill={colour}
    />
  </svg>
);

export default Series;
