import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { cursorValuesIconLabel } from "../../text";

const CursorValuesGlyph = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={cursorValuesIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16">
    <g fill={colour}>
      <polygon fill={colour} points="0,0 3,9 5,5 9,3 " />
      <path
        data-color="color-2"
        d="M15,10H7c-0.552,0-1-0.448-1-1V7c0-0.552,0.448-1,1-1h8c0.552,0,1,0.448,1,1v2 C16,9.552,15.552,10,15,10z"
      />
      <path
        data-color="color-2"
        d="M15,16H7c-0.552,0-1-0.448-1-1v-2c0-0.552,0.448-1,1-1h8c0.552,0,1,0.448,1,1v2 C16,15.552,15.552,16,15,16z"
      />
    </g>
  </svg>
);

export default CursorValuesGlyph;
