import Swal from "sweetalert2";

export const Alert = (
  onConfirm,
  onReject?: () => any,
  option?: {
    message: string;
  }
) => {
  Swal.fire({
    title: "Are you sure?",
    text: option?.message ?? "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes!",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm?.();
    } else {
      onReject?.();
    }
  });
};

export default Alert;
