import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { dataExportIconLabel } from "../../text";

const DataExport = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    aria-label={dataExportIconLabel}>
    <circle cx="11" cy="11" r="10.2" stroke={colour} strokeWidth="1.6" />
    <mask
      id="mask0_552_1940"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="18"
      height="18">
      <rect x="2" y="2" width="18" height="18" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_552_1940)">
      <path
        d="M6.5 17C6.0875 17 5.7345 16.8533 5.441 16.5597C5.147 16.2657 5 15.9125 5 15.5V13.25H6.5V15.5H15.5V13.25H17V15.5C17 15.9125 16.8533 16.2657 16.5597 16.5597C16.2657 16.8533 15.9125 17 15.5 17H6.5ZM11 14L7.25 10.25L8.3 9.1625L10.25 11.1125V5H11.75V11.1125L13.7 9.1625L14.75 10.25L11 14Z"
        fill={colour}
      />
    </g>
  </svg>
);

export default DataExport;
