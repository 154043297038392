import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { arrowRightIconLabel } from "../../text";

const ArrowRight = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 10"
    aria-hidden="true"
    aria-label={arrowRightIconLabel}
    fill={colour}>
    <path d="M13.044 15.7966L11.7002 14.4841L16.9502 9.23407H0.512695V7.35907H16.9502L11.669 2.07782L13.0127 0.76532L20.544 8.29657L13.044 15.7966Z" />
  </svg>
);

export default ArrowRight;
