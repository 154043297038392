import { FC, memo, ReactNode, ReactElement } from "react";
import { useTheme } from "styled-components";
import Icon from "@Core/Icon";
import Text from "@Core/Text";
import { genericLoadingMessage, genericErrorMessage } from "./text";
import {
  LoadingOrErrorContainer,
  LoadingContainer,
  ErrorContainer,
} from "./styles";

export interface LoadingOrErrorProps {
  loading?: boolean;
  loadingFallback?: ReactElement;
  error?: Error;
  errorFallback?: ReactElement;
  children?: ReactNode;
}

const LoadingOrError: FC<LoadingOrErrorProps> = ({
  loading: isLoading,
  error: hasError,
  loadingFallback,
  errorFallback,
  children,
}) => {
  const theme = useTheme();

  return (
    <>
      {isLoading || hasError ? (
        <LoadingOrErrorContainer data-testid="LoadingOrErrorContainer">
          {isLoading &&
            (!loadingFallback ? (
              <LoadingContainer aria-label={genericLoadingMessage}>
                <Icon icon="loading-dots" colour={theme.text1} />
              </LoadingContainer>
            ) : (
              loadingFallback
            ))}
          {hasError &&
            !isLoading &&
            (!errorFallback ? (
              <ErrorContainer>
                <Icon icon="warning" colour={theme.error} />
                <Text
                  error>{`${genericErrorMessage}: ${hasError.message}`}</Text>
              </ErrorContainer>
            ) : (
              errorFallback
            ))}
        </LoadingOrErrorContainer>
      ) : (
        children
      )}
    </>
  );
};

export default memo(LoadingOrError);
