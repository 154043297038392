import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { drawRangeIconLabel } from "../../text";

const DrawRangeIcon = ({
  colour,
  size = IconSizes.MD,
}: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 17"
    aria-label={drawRangeIconLabel}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.468262"
      y="0.378906"
      width="15.999"
      height="15.999"
      fill={colour}
      fillOpacity="0.1"
    />
    <rect
      x="1.21826"
      y="1.12891"
      width="14.499"
      height="14.499"
      stroke="#D9D9D9"
      strokeOpacity="0.6"
      strokeWidth="1.5"
      strokeDasharray="2 2"
    />
  </svg>
);

export default DrawRangeIcon;
