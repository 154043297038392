import { ChangeEvent, ReactNode } from "react";
import { FilterMatchMode } from "primereact/api";

export enum PageUrl {
  Bootstrap = "/",
  Login = "/cashier-login",
  CashierTransactionPage = "/transaction/9876543456789-876543456789-87654345678/cashier/oiuyty-987to890pojrt9-2976tredsdfguytr08j-987to890pojrt9-2tredsdfguytr08j",
  AdminLogin = "/admin/login",
  SuperLogin = "/super-administrator/login",
  Home = "/dashboard",
  Product = "/products",
  Category = "/categories",
  Unit = "/units",
  Settings = "/settings",
  Report = "/reports",
  Supplier = "/suppliers",
  Users = "/manage-users",
  StockAdjustment = "/stock-adjustment",
  SalesReturn = "/sales-return",
  DailyProfitAndLost = "/daily-profit-and-lost",
  Invoices = "/view-transaction-invoices",
  Registration = "/registration",
  Tax = "/manage-tax",
  TaxSettings = "/tax-settings",
  Logs = "/manage-logs",
  StoreOrWarehouse = "/manage-stores-and-warehouses",
  Wallet = "/manage-wallet",
  Banking = "/manage-banking",
  Customers = "/manage-customers",
  SalesMaster = "/manage-Salesmaster",
  Quotations = "/manage-quotation",
  Payment = "/manage-payment",
  Voucher_Expenses = "/manage-vouchers",
  POS_Work_Period = "/manage-pos-work-period",
  POS_Record = "/manage-pos-record",
  POS_Report = "/manage-pos-report",
  PurchaseInvoices = "/purchase-invoices",
  ComplainReport = "/ComplainReport",
  ComplainRegistar = "/ComplainRegistar",
}

export enum Colour {
  posBlack = "#0C0C0C",
  posGrey1 = "#1B1B1B",
  posGrey2 = "#272727",
  posGrey25 = "#212121",
  posGrey3 = "#3A3A3A",
  posGrey4 = "#535353",
  posGrey5 = "#6D6D6D",
  posGrey6 = "#888888",
  posGrey7 = "#A5A5A5",
  posGrey8 = "#C2C2C2",
  posGrey9 = "#E0E0E0",
  posGrey10 = "#6E6E6E",
  posGrey11 = "#D1D1D1",
  posGrey15 = "#1D1D1D",
  posGrey12 = "#EAEAEA",
  posGrey16 = "#414141",
  posWhite = "#FFFFFF",
  posYellow = "#FFFF69",
  posRed = "#FF0000",
  posDarkGreyBlue = "#005561",
  posGreyBlue = "#5E7E8B",
  posLightBlue = "#67BBDF",
  posBlue1 = "#2D74FF",
  success = "#68E199",
  error = "#CF6679",
  warning = "orange",
  info = "#B7DCFC",
  posGrey13 = "#F5F5F5",
  posGrey14 = "#444444",
  transparent = "rgba(0, 0, 0, 0)",
  previewBlue = "#0070CB",
}

export interface Theme {
  space: (arg1: number) => string;
  fill1: Colour;
  fill2: Colour;
  fill3: Colour;
  fill4: Colour;
  fill5: Colour;
  fill6: Colour;
  fill8: Colour;
  fill12: Colour;
  fillContrast1: Colour;
  fillContrast2: Colour;
  fillContrast3: Colour;
  fillContrast4: Colour;
  text1: Colour;
  text2: Colour;
  modifierStroke: Colour;
  textContrast1: Colour;
  textContrast2: Colour;
  highlight: Colour;
  error: Colour;
  success: Colour;
  warning: Colour;
  info: Colour;
  fill7: Colour;
  fill9: Colour;
  fill10: Colour;
  fill17: Colour;
  wellSketch: any; // TODO: define wellSketchTheme
  labelFill: string;
  transparent: Colour;
}

export interface Themes {
  dark: Theme;
  light: Theme;
}

export interface TextProps {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  bold?: boolean;
  span?: boolean;
  error?: boolean;
  noStack?: boolean;
  margin?: boolean;
  marginTop?: string;
  align?: "left" | "right" | "center" | "justify";
  fontWeight?: "lighter" | "normal" | "bold" | "bolder";
  color?: string;
  ellipsis?: boolean;
  fontSize?: string;
  children?: ReactNode;
}

export type Icon =
  | "loading-dots"
  | "hand"
  | "magnifying-glass"
  | "warning"
  | "scale"
  | "checkbox"
  | "checkbox-ticked"
  | "crosshair"
  | "crosshair-glyph"
  | "cursor-values"
  | "cursor-values-glyph"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "plus"
  | "add-circle"
  | "chart"
  | "cross"
  | "eye-open"
  | "eye-closed"
  | "scissors"
  | "settings"
  | "series"
  | "success"
  | "error"
  | "info"
  | "padlock"
  | "minus"
  | "questionMark"
  | "chartIcon"
  | "dragHandle"
  | "arrow-right"
  | "list"
  | "heatmap"
  | "triangle"
  | "tick-mark"
  | "circle-with-arrow"
  | "calendar"
  | "data-export"
  | "drag"
  | "chevron-up"
  | "annotate"
  | "draw-range"
  | "pin"
  | "bank"
  | "pinned"
  | "PeopleConnentIcon"
  | "Cart"
  | "CompanyIcon"
  | "DbIcon"
  | "NoteWithDollarMark"
  | "OrderListIcon"
  | "Payment"
  | "ReceiptIcon"
  | "PrinterIcon"
  | "SalesmanIcon"
  | "WalletIcon"
  | "POPPrinting"
  | "EditIcon"
  | "POSWithCardIcon"
  | "ListbookWithPicIcon"
  | "DeleteBinIcon"
  | "CustomerCareIcon"
  | "CashierIcon"
  | "ReportStatisticsIcon"
  | "AnalysisSearchIcon"
  | "AnalyzeIcon"
  | "LogIcon"
  | "ExpireIcon"
  | "DownTrendIcon"
  | "EmptyBox"
  | "StockGraphIcon"
  | "DashboardIcon"
  | "TruckIcon"
  | "CartWithProductIcon"
  | "CategoriesIcon"
  | "TaxLawIcon"
  | "SalesReturnIcon"
  | "ShopIcon"
  | "PurchaseOrderIcon"
  | "BarCode";

export enum IconSizes {
  XS = "10",
  SM = "16",
  MD = "24",
  LG = "32",
  XL = "36",
  XXL = "40",
}

export interface IconSvgProps {
  colour: Colour | string;
  size?: IconSizes;
  className?: string;
}

export interface IconProps extends IconSvgProps {
  icon?: Icon;
  size?: IconSizes;
}

export type IconButtonShape = "circle" | "square" | "flat";

export type IconButtonSize = "xsmall" | "small" | "medium" | "large";

export interface IconToggleButtonProps {
  iconDisabled: Icon;
  iconEnabled: Icon;
  enabled: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

export interface IconRadioButtonProps {
  icon: Icon;
  selected: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  radioButtonGroup: string;
}

export interface SelectOption {
  id: string;
  name: string;
  type?: string;
  disabled?: boolean;
}

export type GlobalType = {
  value: string | null;
  matchMode: FilterMatchMode;
};

export interface IFilter {
  global: GlobalType;
}

export interface ISupplier {
  _id: string;
  name: string;
  companyName: string;
  address?: string;
  phoneNumber: string;
  email?: string;
  storeOrWarehouseId: string;
}

export interface ProductSalesType {
  productName: string;
  productCode: string;
  quantity: number;
  tempQuantity?: number;
  productId: string;
  unitPrice: number;
  minimumSalePrice: number;
  unitPrices?: { id: string; name: string; price: number }[];
  newSalesUnits?: { id: string; name: string; price: number }[];

  salesUnit?: string;
  tempSalesUnit?: string;
  tempSalesUnitName?: string;
  tempSalesUnitPrice?: number;
  amount?: number;
  isEditMode?: boolean;
}

export interface IProduct {
  reorderPoint: number;
  unitPrices?: {
    id: string;
    name: string;
    price: number;
    _name: string;
    qty: number;
  }[];
  color: string;
  salesPrice: number | null;
  description: string;
  purchasePrice: number | null;
  storeOrWarehouseId?: { name: string; _id: string } | null;
  expiryDate: Date | null;
  createdAt: Date | null;
  marginAmount: number;
  supplier?: { name: string; _id: string } | null;
  manufactureDate: Date | null;
  barcode: string;
  wholesalePrice: number | null;
  quantityInStock: number;
  brandName: string;
  marginPercent: number;
  weight: number;
  picture: string;
  purchaseUnit: string | undefined;
  productCode: string;
  taxCommodity?: { name: string; _id: string } | null;
  size: string;
  salesUnit: string | undefined;
  name: string;
  colorCode: string;
  _id: string;
  category?: { name: string; _id: string } | null;
  minimumSalePrice: number | null;
}

export interface INetworkResponse {
  code: number;
  message: string;
  data: any;
}

export const ToastLife = 3000;

export enum UserRoleEnum {
  STOREADMIN = "STORE_ADMIN",
  WAREHOUSEADMIN = "WAREHOUSE_ADMIN",
  SUPERADMIN = "SUPERADMIN",
  CASHIER = "CASHIER",
}

export interface ITransaction {
  amountTendered: number;
  balanceChange: number;
  createdAt: string;
  discount: number;
  invoiceId: string;
  paymentMethod: string;
  salesReturned: boolean;
  seller: { _id: string; firstName: string; lastName: string };
  subTotal: number;
  taxPercent: number;
  total: number;
}

export interface ICompanyInfo {
  country: string;
  creditOrDebitCardProcessingFee: undefined;
  showLogo: boolean;
  companyName: string;
  postalCode: string;
  ceoPhone: string;
  showQROnReceipt: boolean;
  createdAt: string;
  askAdminPinForRateAndDiscount: boolean;
  contactNumber: string;
  addressLine1: string;
  logo: string;
  baseUnit: string;
  addressLine2: string;
  currency: {
    createdAt: string;
    symbol: string;
    code: string;
    _id: string;
    updatedAt: string;
  };
  addressLine3: string;
  ceoName: string;
  receiptFooterNote: string;
  ceoEmail: string;
  fax: string;
  email: string;
  updatedAt: string;
  companyCode: string;
  applyTaxOnWholesale: boolean;
  printSize: string;
  posPrintCopies: number;
  showInvoiceNumberAsBarcodeOnReceipt: boolean;
  quotationTermsAndCondition: string;
  _id: string;
  ceoAddress: string;
  vatNumber: string;
}

export interface ILoginCredential {
  email: string;
  password: string;
  secretCode?: string;
  storeCode?: string;
}
