import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const AnalyzeIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 512 512"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Shape" fill={colour} transform="translate(42.666667, 42.666667)">
        <path
          d="M170.666667,-7.10542736e-15 
                    C264.770773,-7.10542736e-15 341.333333,76.56256 
                    341.333333,170.666667 C341.333333,210.04032 
                    327.799467,246.239573 305.3152,275.148373 
                    L426.630187,396.463573 L396.463573,426.630187 
                    L275.148373,305.314987 C246.239573,327.799467 
                    210.04032,341.333333 170.666667,341.333333 
                    C76.56256,341.333333 7.10542736e-15,264.770773 
                    7.10542736e-15,170.666667 C7.10542736e-15,76.56256 
                    76.56256,-7.10542736e-15 170.666667,-7.10542736e-15 Z 
                    M170.666667,148.928 L133.408288,240 
                    L63.1115909,240.001039 C85.9289807,275.276196 
                    125.615934,298.666667 170.666667,298.666667 
                    C215.7174,298.666667 255.404353,275.276196 
                    278.221742,240.001039 L207.925046,240 
                    L170.666667,148.928 Z M170.666667,42.6666667 
                    C100.083413,42.6666667 42.6666667,100.083413 
                    42.6666667,170.666667 C42.6666667,183.648708 
                    44.6089886,196.185346 48.2183113,208.00126 
                    L111.914667,208 L170.666667,64.4093713 
                    L229.397333,208 L293.115022,208.00126 
                    C296.724345,196.185346 298.666667,183.648708 
                    298.666667,170.666667 C298.666667,100.083413 
                    241.24992,42.6666667 170.666667,42.6666667 Z"
        />
      </g>
    </g>
  </svg>
);

export default AnalyzeIcon;
