import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const StockGraphIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    fill={colour}>
    <g>
      <polygon
        points="204.344,155.188 249.469,200.297 409.344,40.422 268.031,40.422 316.063,88.453 249.469,155.031 
                204.953,110.516 41.906,264.969 63.906,288.219 	"
      />
      <polygon points="512,102.313 276.031,330.281 212.656,266.906 0,471.578 512,471.578 	" />
    </g>
  </svg>
);

export default StockGraphIcon;
