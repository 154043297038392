/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { Button as PrimeButton } from "primereact/button";

// LISTS

export const List = styled.ul`
  list-style-type: none;
  margin: 0 0 ${({ theme }) => theme.space(2)};
  padding: ${({ theme }) => theme.space(3)};
  padding-bottom: ${({ theme }) => theme.space(2)};
  background-color: ${({ theme }) => theme.fill1};
  border-radius: ${({ theme }) => theme.space(1)};
  overflow: auto;
  flex-grow: 1;
`;

interface ILinkButtonProps {
  block?: boolean;
  noPad?: boolean;
  underline?: boolean;
  rounded?: boolean;
  active?: boolean;
  ellipsis?: boolean;
  right?: boolean;
}

export const LinkButton = styled.button<ILinkButtonProps>`
  display: ${({ block }) => (block ? "block" : "inline")};
  width: ${({ block }) => (block ? "100%" : "auto")};
  text-align: ${({ block }) => (block ? "left" : "auto")};
  background: ${({ active }) => (active ? "red" : "none")};
  padding: ${({ theme, block, noPad, rounded }) =>
    (!noPad && block) || rounded ? theme && theme.space && theme.space(1) : 0};
  cursor: pointer;
  color: inherit;
  outline: none;
  border: none;
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  border-radius: ${({ rounded }) => (rounded ? "50%" : "none")};
  max-width: ${({ ellipsis }) => (ellipsis ? "100%" : "none")};
  overflow: ${({ ellipsis }) => (ellipsis ? "hidden" : "")};
  text-overflow: ${({ ellipsis }) => (ellipsis ? "ellipsis" : "")};
  white-space: ${({ ellipsis }) => (ellipsis ? "nowrap" : "")};
  float: ${({ right }) => (right ? "right" : "")};
  opacity: 1;

  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 0.6;
  }
`;

interface DividerProps {
  noPad?: boolean;
}

export const Divider = styled("div")<DividerProps>`
  background-color: ${({ theme }) => theme.highlight};
  height: ${({ theme }) => theme.space(0.5)};
  max-width: 100%;
  margin: 0 ${({ theme, noPad }) => (noPad ? 0 : theme.space(3))};
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

export const TablePadding = styled.div`
  padding: ${({ theme }) => theme.space(1)};
  background-color: ${({ theme }) => theme.fill1};
`;

export const FormWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.fill1};

  @media only screen and (max-device-width: 320px) {
    margin-top: 20px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    margin-top: 20px;
  }
  @media only screen and (min-device-width: 480px) and (max-device-width: 768px) {
    margin-top: 20px;
  }
`;

export const CustomPrimeButton = styled(PrimeButton)`
  margin-right: 5px;
`;
