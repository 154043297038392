import { IconSizes, IconSvgProps } from "@Types/global.types";
import { ReactElement } from "react";

const PurchaseOrderIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 97.92 122.88"
    fill={colour}>
    <g>
      <path d="M21.95,18.35h34.79c2.47,0,2.34,1,2.12,3.55l-1.4,17.92c-0.24,2.85-0.04,2.16-3.06,2.6L29.6,44.59l1.47,4.27 c12.06,0,14.88,0,26.93,0c0.41,1.52,0.96,3.98,1.36,5.67h-4.97l-0.48-1.73c-10.04,0-10.87,0-20.92,0 c-5.49-0.09-4.94,1.39-6.25-3.35l-9.43-32.2H14.4v-4.01h6.32C21.09,14.63,21.61,16.95,21.95,18.35L21.95,18.35z M68.17,0.66 C67.73,0.26,67.11,0,66.5,0c-0.13,0-0.26,0-0.4,0.04H5.54c-1.49,0-2.9,0.62-3.91,1.63C0.62,2.68,0,4.04,0,5.58v111.76 c0,1.54,0.62,2.9,1.63,3.91c1.01,1.01,2.37,1.63,3.91,1.63h86.76c1.54,0,2.9-0.62,3.91-1.63c1.01-1.01,1.63-2.37,1.63-3.91V32.3 c0.04-0.22,0.09-0.4,0.09-0.62c0-0.75-0.35-1.41-0.84-1.89L68.47,0.83c-0.09-0.09-0.13-0.13-0.22-0.18L68.17,0.66L68.17,0.66 L68.17,0.66z M66.2,5.01v19.46c0,2.07,0.84,3.96,2.2,5.32c1.36,1.36,3.25,2.2,5.32,2.2h19.06v85.36c0,0.13-0.04,0.31-0.18,0.4 c-0.09,0.09-0.22,0.18-0.4,0.18H5.49c-0.13,0-0.31-0.04-0.4-0.18c-0.09-0.09-0.18-0.26-0.18-0.4V5.58c0-0.18,0.04-0.31,0.18-0.4 c0.09-0.09,0.22-0.18,0.4-0.18H66.2L66.2,5.01L66.2,5.01z M71.16,24.47V8.97l17.79,18.05H73.71c-0.7,0-1.32-0.31-1.8-0.75 C71.47,25.83,71.16,25.17,71.16,24.47L71.16,24.47L71.16,24.47z M20.52,69.69c-1.36,0-2.5,1.1-2.5,2.5c0,1.36,1.1,2.51,2.5,2.51 h56.13c1.36,0,2.51-1.1,2.51-2.51c0-1.36-1.1-2.5-2.51-2.5H20.52L20.52,69.69z M21.46,99.38c-1.36,0-2.51,1.1-2.51,2.51 c0,1.36,1.1,2.5,2.51,2.5h55.76c1.36,0,2.51-1.1,2.51-2.51c0-1.36-1.1-2.5-2.51-2.5H21.46L21.46,99.38L21.46,99.38z M20.52,85.06 c-1.36,0-2.5,1.1-2.5,2.5c0,1.36,1.1,2.5,2.5,2.5h56.7c1.36,0,2.5-1.1,2.5-2.5c0-1.36-1.1-2.5-2.5-2.5H20.52L20.52,85.06z M37,22.87h7.19v7.3h6.47L40.6,39.95l-10.07-9.79H37V22.87L37,22.87z M33.03,55.21c2.12,0,3.84,1.72,3.84,3.84 c0,2.12-1.72,3.84-3.84,3.84c-2.12,0-3.84-1.72-3.84-3.84C29.19,56.93,30.91,55.21,33.03,55.21L33.03,55.21z M48.86,55.21 c2.12,0,3.84,1.72,3.84,3.84c0,2.12-1.72,3.84-3.84,3.84c-2.12,0-3.84-1.72-3.84-3.84C45.02,56.93,46.74,55.21,48.86,55.21 L48.86,55.21z" />
    </g>
  </svg>
);

export default PurchaseOrderIcon;
