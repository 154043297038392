import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const ReportStatisticsIcon = ({
  colour = "white",
  size = IconSizes.XL,
}: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    mirror-in-rtl="true">
    <path
      fill={colour}
      d="M15 0H3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM6 14.983a1 1 0 1 1-2 0v-2a1 1 0 0 1 2 0v2zM4 5.5A2.5 2.5 0 0 1 6.5 3v2.5H9a2.5 2.5 0 0 1-5 0zm6 9.468a1 1 0 1 1-2 0v-5a1 1 0 0 1 2 0v5zM7.5 4.5V2A2.5 2.5 0 0 1 10 4.5H7.5zM14 15a1 1 0 0 1-2 0V4a1 1 0 0 1 2 0v11z"
    />
  </svg>
);
export default ReportStatisticsIcon;
