import { lazy, ReactElement, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoadingOrError from "@Components/molecules/LoadingOrError";
import { PageUrl } from "@Types/global.types";
import BootstrapPage from "@Pages/BootstrapPage";
import Login from "@Pages/Login";
import ProtectedComponent from "@Core/ProtectedComponent";
import PreventVisitComponent from "@Core/PreventVisit";
import PrivateComponent from "@Core/PrivateComponent";
import PurchaseInvoices from "@Components/pages/PurchaseInvoices/PurchaseInvoices";
import ComplainRegistar from "@Components/pages/ComplainRegister/ComplainRegister";
// import ComplainReport from "@Components/pages/ComplainReport/ComplainReport";

const Report = lazy(() => import("@Pages/Report"));
const TaxTypes = lazy(() => import("@Pages/TaxTypes"));
const Quotation = lazy(() => import("@Pages/Quotation"));
const AdminLogin = lazy(() => import("@Pages/AdminLogin"));
const SuperAdmin = lazy(() => import("@Pages/SuperAdmin"));
const CashierWrapper = lazy(() => import("@Organisms/CashierWrapper"));
const Cashier = lazy(() => import("@Pages/Cashier"));
const SalesReturn = lazy(() => import("@Pages/SalesReturn"));
const VouchersOrExpenses = lazy(() => import("@Pages/VouchersExpenses"));
const PosReport = lazy(() => import("@Pages/PosReport"));
const PosRecord = lazy(() => import("@Pages/PosRecord"));
const ComplainReport = lazy(() => import("@Pages/ComplainReport"));
const Project = lazy(() => import("@Pages/Project"));
const Categories = lazy(() => import("@Pages/Categories"));
const Suppliers = lazy(() => import("@Pages/Suppliers"));
const Users = lazy(() => import("@Pages/Users"));
const Units = lazy(() => import("@Pages/Units"));
const HomePage = lazy(() => import("@Pages/Home"));
const ProductOverview = lazy(() => import("@Pages/Products/ProductOverview"));
const SystemLogs = lazy(() => import("@Pages/SystemLogs"));
const CreditCustomer = lazy(() => import("@Components/pages/Customer"));
const SalesMaster = lazy(() => import("@Components/pages/SalesMaster"));
const ManageTax = lazy(() => import("@Pages/ManageTax"));
const Settings = lazy(() => import("@Pages/Settings"));
const DailyProfitAndLoss = lazy(
  () => import("@Pages/ProfitAndLoss/ProfitAndLoss")
);
const Invoices = lazy(() => import("@Pages/Invoices/Invoices"));
const StockAdjustment = lazy(() => import("@Pages/StockAdjustment"));
const StoreAndWarehouses = lazy(() => import("@Pages/StoresAndWarehouses"));

const Router = (): ReactElement => (
  <>
    <BrowserRouter>
      <Suspense fallback={<LoadingOrError loading />}>
        <Routes>
          <Route
            path={PageUrl.Bootstrap}
            element={
              <PreventVisitComponent>
                <BootstrapPage />
              </PreventVisitComponent>
            }
          />
          <Route
            path={PageUrl.Login}
            element={
              <PreventVisitComponent>
                <Login />
              </PreventVisitComponent>
            }
          />
          <Route
            path={PageUrl.AdminLogin}
            element={
              <PreventVisitComponent>
                <AdminLogin />
              </PreventVisitComponent>
            }
          />
          <Route
            path={PageUrl.SuperLogin}
            element={
              <PreventVisitComponent>
                <SuperAdmin />
              </PreventVisitComponent>
            }
          />
          <Route
            path={PageUrl.Home}
            element={
              <ProtectedComponent>
                <Project isHideShortcut>
                  <HomePage />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Product}
            element={
              <ProtectedComponent>
                <Project>
                  <ProductOverview />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.PurchaseInvoices}
            element={
              <ProtectedComponent>
                <Project>
                  <PurchaseInvoices />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.StockAdjustment}
            element={
              <ProtectedComponent>
                <Project>
                  <StockAdjustment />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Category}
            element={
              <ProtectedComponent>
                <Project>
                  <Categories />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Settings}
            element={
              <ProtectedComponent>
                <Project>
                  <Settings />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Users}
            element={
              <ProtectedComponent>
                <Project>
                  <Users />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Report}
            element={
              <ProtectedComponent>
                <Project>
                  <Report />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Unit}
            element={
              <ProtectedComponent>
                <Project>
                  <Units />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.SalesMaster}
            element={
              <ProtectedComponent>
                <Project>
                  <SalesMaster />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Customers}
            element={
              <ProtectedComponent>
                <Project>
                  <CreditCustomer />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Supplier}
            element={
              <ProtectedComponent>
                <Project>
                  <Suppliers />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Voucher_Expenses}
            element={
              <ProtectedComponent>
                <Project>
                  <VouchersOrExpenses />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.POS_Record}
            element={
              <ProtectedComponent>
                <Project>
                  <PosRecord />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.ComplainReport}
            element={
              <ProtectedComponent>
                <Project>
                  <ComplainReport />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.POS_Report}
            element={
              <ProtectedComponent>
                <Project>
                  <PosReport />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Registration}
            element={
              <ProtectedComponent>
                <Project>
                  <Users />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Tax}
            element={
              <ProtectedComponent>
                <Project>
                  <ManageTax />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.TaxSettings}
            element={
              <ProtectedComponent>
                <Project>
                  <TaxTypes />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.DailyProfitAndLost}
            element={
              <ProtectedComponent>
                <Project>
                  <DailyProfitAndLoss />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Invoices}
            element={
              <ProtectedComponent>
                <Project>
                  <Invoices />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.StoreOrWarehouse}
            element={
              <ProtectedComponent>
                <Project>
                  <StoreAndWarehouses />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.DailyProfitAndLost}
            element={
              <ProtectedComponent>
                <Project>Daily Profit and Lost</Project>
              </ProtectedComponent>
            }
          />
          <Route
            path={PageUrl.Quotations}
            element={
              <PrivateComponent>
                <CashierWrapper>
                  <Quotation />
                </CashierWrapper>
              </PrivateComponent>
            }
          />
          <Route
            path={PageUrl.ComplainRegistar}
            element={
              <PrivateComponent>
                <CashierWrapper>
                  <ComplainRegistar />
                </CashierWrapper>
              </PrivateComponent>
            }
          />
          <Route
            path={PageUrl.SalesReturn}
            element={
              <PrivateComponent>
                <CashierWrapper>
                  <SalesReturn />
                </CashierWrapper>
              </PrivateComponent>
            }
          />
          <Route
            path={PageUrl.CashierTransactionPage}
            element={
              <PrivateComponent>
                <CashierWrapper>
                  <Cashier />
                </CashierWrapper>
              </PrivateComponent>
            }
          />
          <Route
            path={PageUrl.Logs}
            element={
              <ProtectedComponent>
                <Project>
                  <SystemLogs />
                </Project>
              </ProtectedComponent>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </>
);
export default Router;
