import { Card } from "primereact/card";
import {
  CardWrapper,
  FieldWrapper,
  LoginContainer,
  LoginHeader,
  LoginWrapper,
  ButtonWrapper,
  InputPinWrapper,
} from "@Pages/Login/styles";
import Text from "@Core/Text";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import {
  ILoginCredential,
  INetworkResponse,
  PageUrl,
  ToastLife,
} from "@Types/global.types";
import LoginCopyright from "@Atoms/LoginCopyright";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Toast } from "primereact/toast";
import Timer from "@Atoms/Timer";
import { useCompanyInfo } from "@Hooks/useCompanyInfo/useCompanyInfo";
import { getCompanyName } from "@Utils/general-utils";
import Logo from "@Atoms/Logo";
import { useCashierCache } from "@Hooks/useCashierCache/useCashierCache";
import { postRequest } from "../../../axios/requests";

const Login = () => {
  const toast = useRef(null);
  const { handleFetchCompanyInfo } = useCompanyInfo({ toast });
  const { handleClearWorkspace } = useCashierCache();

  const [isLoading, setIsLoading] = useState(false);
  const [pincode, setPincode] = useState<number | null>(null);

  const navigate = useNavigate();

  const formOptions: {
    defaultValues: ILoginCredential;
  } = {
    defaultValues: {
      email: "",
      password: "",
    },
  };

  const { reset } = useForm<ILoginCredential>({
    ...formOptions,
    mode: "onBlur",
  });

  const handleLogin = () => {
    handleClearWorkspace();
    navigate(PageUrl.CashierTransactionPage, { replace: true });
  };

  const handlePinLoginReq = (pinLoginInfo: { pinCode: string }) =>
    postRequest("/auth/login/pincode", pinLoginInfo);

  const handleLoginWithPin = () => {
    setIsLoading(true);
    reset();
    handlePinLoginReq({ pinCode: pincode?.toString() })
      .then((response: axios.AxiosResponse & INetworkResponse) => {
        setIsLoading(false);
        handleFetchCompanyInfo();
        if (response.code === 200) {
          handleLogin();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        reset();
        setPincode(null);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: ToastLife,
        });
      });
  };

  const handleValidation = (value: number): boolean => {
    if (!value) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please enter pincode",
        life: ToastLife,
      });
      return false;
    }
    if (value.toString().length < 4 || value.toString().length > 6) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Invalid pincode, consider using email and password",
        life: ToastLife,
      });
      return false;
    }
    return true;
  };

  const handlePinLoginEntered = (e) => {
    if (e.key === "Enter" && e?.target?.value) {
      if (handleValidation(e?.target?.value)) {
        handleLoginWithPin();
      }
    }
  };

  const handlePinLoginClicked = () => {
    if (pincode) {
      if (handleValidation(pincode)) {
        handleLoginWithPin();
      }
    }
  };

  const handlePincodeChange = (value: number | null) => {
    setPincode(value);
  };

  useEffect(() => {
    const details = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;

    const isMobileDevice = regexp.test(details);

    if (isMobileDevice && process.env.NODE_ENV !== "development") {
      navigate("/");
    }
  }, []);

  return (
    <LoginContainer>
      <Logo />
      <LoginWrapper>
        <CardWrapper>
          <Card
            header={<LoginHeader>Staff Login Page</LoginHeader>}
            title={getCompanyName()}
            subTitle={
              <Text bold fontSize="30px" noStack>
                <Timer />
              </Text>
            }>
            <div>
              <div>
                <FieldWrapper>
                  <Text bold>Enter your secret pin</Text>
                  <InputPinWrapper
                    id="pincode"
                    type="password"
                    useGrouping={false}
                    maxLength="6"
                    value={pincode}
                    onKeyPress={handlePinLoginEntered}
                    name="pincode"
                    onChange={(e) => handlePincodeChange(e.value)}
                    placeholder="Secret pin code"
                  />
                </FieldWrapper>
                <ButtonWrapper>
                  <Button
                    onClick={handlePinLoginClicked}
                    label={isLoading ? "Loading..." : "Sign in"}
                    disabled={!pincode || isLoading}
                    size="large"
                    severity="info"
                  />
                </ButtonWrapper>
              </div>
            </div>
          </Card>
        </CardWrapper>
      </LoginWrapper>
      <LoginCopyright />
      <Toast ref={toast} />
    </LoginContainer>
  );
};

export default Login;
