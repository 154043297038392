import styled from "styled-components";
import { InputText } from "primereact/inputtext";
import Button from "@Atoms/Button";
import BackgroundImage2 from "@Assets/images/bg.jpg";

export const BootstrapContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: ${() => `url(${BackgroundImage2})`};
`;

export const BootstrapWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding-top: 10px;
`;

export const CardWrapper = styled.div`
  margin-top: 0;
  min-height: 300px;

  @media only screen and (max-device-width: 320px) {
    width: 90%;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    width: 90%;
  }
  @media only screen and (min-device-width: 480px) and (max-device-width: 768px) {
    width: 90%;
    margin-top: 20px;
  }
`;

export const BootstrapHeader = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.fill2};
  height: 70px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  color: white;
  font-size: 30px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  padding: 3px 0;
  justify-content: space-between;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  margin: 10px 0;
`;

export const InputWrapper = styled(InputText)`
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.fill1};
`;

export const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: center;
`;

export const HomeWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 0;
  margin: -10px;
`;

export const GridContent = styled(Button)`
  height: 125px;
  width: 95%;
  font-weight: bold;
  border-radius: 5px;
  margin: 5px;
  font-size: 20px;
  background-color: ${({ theme }) => theme.fill2};
  color: ${({ theme }) => theme.text1};
  box-shadow: 3px 2px 5px 0px rgba(255, 255, 255, 0.21);
  -webkit-box-shadow: 3px 2px 5px 0px rgba(255, 255, 255, 0.21);
  -moz-box-shadow: 3px 2px 5px 0px rgba(255, 255, 255, 0.21);

  &:hover {
    box-shadow: 1px 2px 10px 2px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 1px 2px 10px 2px rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 1px 2px 10px 2px rgba(255, 255, 255, 0.5);
  }
`;

export const GridContentButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
