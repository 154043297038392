import { getItems, setItem } from "@Utils/cache";
import { KEYS } from "@Hooks/useCashierCache/useCashierCache";
import { INetworkResponse, ToastLife, UserRoleEnum } from "@Types/global.types";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { ICustomer } from "@Pages/Customer/Customer";
import { getLoginUser } from "@Utils/get-login-user";
import { deleteRequest, getRequest, postRequest } from "../../axios/requests";

interface IUseCustomer {
  customers: ICustomer[];
  toast: MutableRefObject<null>;
  onDeleteCustomer: (rowData: ICustomer) => void;
  loading: boolean;
  handleSearch: () => void;
  searchValue: string | undefined;
  cachedCustomers: ICustomer[];
  handleFetchCustomers: (cb?: (data) => void) => void;
  setSearchValue: (value: string | undefined) => void;
}
export const useCustomer = (options: {
  disableAPICall: boolean;
}): IUseCustomer => {
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const { role } = getLoginUser();

  const toast = useRef(null);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);

  const getCustomers = () => getRequest("/customers");

  const postCustomer = (path: string, body: object) => postRequest(path, body);
  const deleteExpenses = (id: string) => deleteRequest(`/customers/${id}`);

  const handleGetCustomers = (cb?: (data) => void) => {
    setLoading(true);
    getCustomers()
      .then((response) => {
        setCustomers(response.data);
        cb?.(response.data);
        setLoading(false);
        setItem(KEYS.CUSTOMER, JSON.stringify(response.data));
      })
      .catch((error) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: ToastLife,
        });
      });
  };

  const handleSearch = () => {
    const payload = searchValue?.includes("CUS")
      ? { customerId: searchValue }
      : { name: searchValue };
    setLoading(true);
    postCustomer("/customers/filter/customer", payload)
      .then((response) => {
        setCustomers(response.data);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: ((response as unknown) as INetworkResponse).message,
          life: ToastLife,
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: ToastLife,
        });
      });
  };

  const onDeleteCustomer = (rowData: ICustomer) => {
    if (role !== UserRoleEnum.CASHIER) {
      setLoading(true);
      deleteExpenses(rowData._id).then((res) => {
        handleGetCustomers();
        setLoading(false);
        toast.current
          .show({
            severity: "success",
            summary: "Success",
            detail: ((res as unknown) as INetworkResponse).message,
            life: ToastLife,
          })
          .catch((error) => {
            setLoading(false);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: error.message,
              life: ToastLife,
            });
          });
      });
    } else {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "You are not authorized to delete this customer",
        life: ToastLife,
      });
    }
  };

  const cachedCustomers = useMemo(() => getItems(KEYS.CUSTOMER), [customers]);

  useEffect(() => {
    if (options.disableAPICall) return;
    handleGetCustomers();
  }, []);

  return {
    customers,
    toast,
    onDeleteCustomer,
    setSearchValue,
    loading,
    handleSearch,
    searchValue,
    cachedCustomers,
    handleFetchCustomers: handleGetCustomers,
  };
};
