import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { eyeClosedLabel } from "../../text";

const EyeClosed = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    aria-label={eyeClosedLabel}
    width={size}
    height={size}
    aria-hidden="true"
    role="img"
    viewBox="6 6 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9205 12.0324C17.4367 11.0132 16.861 10.1819 16.1934 9.53867L15.4931 10.2389C16.0641 10.7846 16.5615 11.4984 16.9916 12.3875C15.8465 14.7573 14.2212 15.8831 12 15.8831C11.3333 15.8831 10.7191 15.7804 10.1576 15.5751L9.39893 16.3338C10.1788 16.6939 11.0458 16.874 12 16.874C14.6451 16.874 16.6186 15.4964 17.9205 12.7411C17.9729 12.6303 18 12.5093 18 12.3868C18 12.2642 17.9729 12.1432 17.9205 12.0324ZM17.0456 7.61966L16.459 7.03229C16.4487 7.02205 16.4366 7.01393 16.4232 7.00839C16.4099 7.00285 16.3955 7 16.3811 7C16.3666 7 16.3523 7.00285 16.3389 7.00839C16.3255 7.01393 16.3134 7.02205 16.3032 7.03229L14.798 8.53678C13.9681 8.1129 13.0355 7.90096 12 7.90096C9.35489 7.90096 7.38138 9.27857 6.07947 12.0338C6.02714 12.1446 6 12.2656 6 12.3881C6 12.5107 6.02714 12.6317 6.07947 12.7425C6.59959 13.838 7.22577 14.7161 7.95802 15.3768L6.5017 16.8327C6.48106 16.8533 6.46948 16.8813 6.46948 16.9105C6.46948 16.9397 6.48106 16.9677 6.5017 16.9883L7.08921 17.5758C7.10985 17.5965 7.13785 17.6081 7.16703 17.6081C7.19622 17.6081 7.22421 17.5965 7.24486 17.5758L17.0456 7.77545C17.0559 7.76523 17.064 7.75308 17.0695 7.73972C17.0751 7.72635 17.0779 7.71203 17.0779 7.69756C17.0779 7.68309 17.0751 7.66876 17.0695 7.6554C17.064 7.64203 17.0559 7.62989 17.0456 7.61966ZM7.00842 12.3875C8.15482 10.0176 9.78014 8.89185 12 8.89185C12.7506 8.89185 13.4331 9.02066 14.0522 9.28256L13.0847 10.25C12.6265 10.0056 12.1019 9.91485 11.5882 9.99124C11.0746 10.0676 10.599 10.3071 10.2318 10.6743C9.86459 11.0415 9.62512 11.5171 9.54872 12.0307C9.47233 12.5444 9.56306 13.0691 9.80753 13.5272L8.65948 14.6753C8.02408 14.1145 7.47634 13.3547 7.00842 12.3875ZM10.4036 12.3875C10.4038 12.1448 10.4613 11.9056 10.5713 11.6893C10.6814 11.473 10.8409 11.2857 11.037 11.1427C11.233 10.9996 11.46 10.9048 11.6995 10.866C11.9391 10.8272 12.1844 10.8454 12.4156 10.9192L10.4766 12.8581C10.428 12.706 10.4034 12.5472 10.4036 12.3875Z"
      fill={colour}
    />
    <path
      d="M11.9451 13.9291C11.8975 13.9291 11.8506 13.9269 11.8041 13.9227L11.0771 14.6496C11.5144 14.817 11.9907 14.8542 12.4486 14.7567C12.9065 14.6592 13.3264 14.4311 13.6574 14.1001C13.9885 13.769 14.2166 13.3492 14.3141 12.8913C14.4116 12.4333 14.3744 11.957 14.207 11.5198L13.48 12.2467C13.4843 12.2932 13.4865 12.3401 13.4865 12.3878C13.4866 12.5902 13.4468 12.7907 13.3694 12.9777C13.292 13.1648 13.1784 13.3348 13.0353 13.4779C12.8921 13.6211 12.7222 13.7346 12.5351 13.812C12.3481 13.8894 12.1476 13.9292 11.9451 13.9291Z"
      fill={colour}
    />
  </svg>
);

export default EyeClosed;
