import {
  CustomPrimeButton,
  TablePadding,
  TableWrapper,
} from "@Core/StyledComponents";
import {
  ActionContainer,
  ScannerWrapper,
  PageWrapper,
  ProductContainer,
} from "@Pages/Products/styles";
import { SplitButton } from "primereact/splitbutton";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import ViewAllProducts from "@Molecules/ViewAllProducts";
import Text from "@Core/Text";
import { Toast } from "primereact/toast";
import { IProduct } from "@Types/global.types";
import { handleScanBarcode } from "@Utils/general-utils";

export enum PageView {
  viewAll = "VIEW_ALL",
  viewByCategory = "VIEW_BY_CATEGORY",
  expiredProduct = "EXPIRED",
  outOfStock = "OUT_OF_STOCK",
  lowStock = "LOWSTOCK",
}
export interface ISearcher {
  barcode: string | null;
  trigger: boolean;
}

export const Products = ({
  onShowProductForm,
  productUpdated,
  onEditProduct,
}: {
  onShowProductForm: (val: boolean) => void;
  onEditProduct: (data: IProduct) => void;
  productUpdated: boolean;
}) => {
  const barcodeScanInput = useRef(null);
  const [viewModel, setViewModel] = useState<string>("More actions");
  const [searcher, setSearcher] = useState<ISearcher>({
    barcode: null,
    trigger: false,
  });
  const toast = useRef(null);

  const [view, setView] = useState<PageView>(PageView.viewAll);
  const moreActions = [
    {
      label: "View all products",
      icon: "pi pi-external-link",
      command: () => {
        setView(PageView.viewAll);
        setViewModel("View all");
      },
    },
    {
      label: "View low in stock",
      icon: "pi pi-table",
      command: () => {
        setView(PageView.lowStock);
        setViewModel("View low in stock");
      },
    },
    {
      label: "View out of stock",
      icon: "pi pi-external-link",
      command: () => {
        setView(PageView.outOfStock);
        setViewModel("View out of stock");
      },
    },
  ];

  const handleSplitButtonClick = () => {
    const activeAction = moreActions.find(
      (action) => action.label === viewModel
    );
    if (activeAction) {
      activeAction?.command?.();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    handleScanBarcode(barcodeScanInput.current as Node, (sCode) => {
      setSearcher((prev) => ({ ...prev, trigger: true, barcode: sCode }));
    });
  }, []);

  return (
    <>
      <ProductContainer>
        <Text h2 margin={false}>
          Manage products
        </Text>
        <ActionContainer>
          <ScannerWrapper>
            <div className="p-inputgroup">
              <Button label="Scan barcode" disabled />
              <InputText
                style={{ width: "300px" }}
                placeholder="barcode"
                id="barcode"
                ref={barcodeScanInput}
              />
            </div>
          </ScannerWrapper>
          <div style={{ display: "flex", gap: "5px" }}>
            <CustomPrimeButton
              label="Add new product"
              severity="success"
              onClick={() => onShowProductForm(true)}
              raised
            />
            <div
              className="card flex justify-content-center"
              style={{ paddingRight: "10px", minWidth: "min-content" }}>
              <SplitButton
                label={viewModel}
                icon="pi pi-eye"
                onClick={handleSplitButtonClick}
                severity="help"
                model={moreActions}
                raised
              />
            </div>
            <CustomPrimeButton
              label="Refresh"
              style={{ paddingLeft: "10px" }}
              raised
              onClick={refreshPage}
              severity="danger"
            />
          </div>
        </ActionContainer>
      </ProductContainer>
      <PageWrapper>
        <TableWrapper>
          <TablePadding>
            {view === PageView.viewAll && (
              <ViewAllProducts
                type={PageView.viewAll}
                search={searcher}
                toast={toast}
                refetchProducts={productUpdated}
                onProductEditClick={onEditProduct}
              />
            )}
            {view === PageView.outOfStock && (
              <ViewAllProducts
                type={PageView.outOfStock}
                toast={toast}
                onProductEditClick={onEditProduct}
              />
            )}
            {view === PageView.lowStock && (
              <ViewAllProducts
                type={PageView.lowStock}
                toast={toast}
                onProductEditClick={onEditProduct}
              />
            )}
            {view === PageView.expiredProduct && (
              <ViewAllProducts
                type={PageView.expiredProduct}
                toast={toast}
                onProductEditClick={onEditProduct}
              />
            )}
          </TablePadding>
        </TableWrapper>
        <Toast ref={toast} />
      </PageWrapper>
    </>
  );
};
export default Products;
