import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { addCircleconLabel } from "../../text";

const AddCircle = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    aria-label={addCircleconLabel}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24">
    <path
      fill={colour}
      d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    />
  </svg>
);

export default AddCircle;
