import React, { useEffect, useState } from "react";

const Clock = () => {
  const [, setTime] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setInterval(() => {
        setTime(new Date().toLocaleTimeString());
      }, 1000);
    });
  }, []);

  return (
    <>
      {new Date().toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })}
    </>
  );
};
export default Clock;
