import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { IFilter } from "@Types/global.types";

interface ITableSearch {
  filters: IFilter;
  setFilters: (value: IFilter) => void;
}
const TableSearch = (props: ITableSearch) => {
  const { filters, setFilters } = props;
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  return (
    <div className="flex justify-content-end">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
        />
      </span>
    </div>
  );
};

export default TableSearch;
