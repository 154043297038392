import {
  DropdownWrapper,
  FieldsWrapper,
  FieldWrapper,
  InputWrapper,
} from "@Organisms/companyInfo/styles";
import Text from "@Core/Text";
import { FieldPath, SubmitHandler, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { DisplayErrorMessage } from "@Molecules/CreateProduct/styles";
import { useCurrency } from "@Hooks/useCurrency/useCurrency";
import { useRef, useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { postRequest } from "../../../axios/requests";

export interface ICompanySetup {
  companyName: string;
  companyEmail: string;
  companyCode: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  currency: string;
  baseUnit: string;
  verifyCompanyEmail: string;
  password: string;
  password2: string;
}

interface IFormOptions {
  defaultValues: ICompanySetup;
}

const initialValues: ICompanySetup = {
  companyName: "",
  companyEmail: "",
  verifyCompanyEmail: "",
  companyCode: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  currency: "",
  baseUnit: "",
  password: "",
  password2: "",
};

const SetupCompany = (props: { onCloseModal: (data) => void }) => {
  const { onCloseModal } = props;
  const toast = useRef(null);
  const { currencies } = useCurrency({});
  const [selectedCurrency, setSelectedCurrency] = useState<
    string | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [selectedBaseUnit, setSelectedBaseUnit] = useState<string | undefined>(
    "pcs"
  );

  const setupCompanyRequest = (companyData: ICompanySetup) =>
    postRequest(`/company/setup/new-company`, companyData);

  const formOptions: IFormOptions = {
    defaultValues: initialValues,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<ICompanySetup>({ ...formOptions, mode: "onBlur" });

  const handleFormChange = (name: string, value: string) => {
    if (name === "currency") {
      setSelectedCurrency(value);
    } else {
      setSelectedBaseUnit(value);
    }
  };

  const handleSetupCompany = (data: ICompanySetup) => {
    setLoading(true);
    setupCompanyRequest(data)
      .then((response) => {
        setLoading(false);
        onCloseModal?.(response.data);
        reset();
        toast.current.show({
          severity: "success",
          summary: "Congratulations!!!",
          detail: `Your company(${data.companyName}) has been successfully set up on our server, 
          you can now login to manage your company using your email and password`,
          life: 90000,
        });
      })
      .catch(() => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Setup failed",
          detail:
            "We have not been able to setup your company, refresh the app, confirm that you fill in all the " +
            "details and try again, if the problem persist, kindly contact us.",
          life: 3000,
        });
      });
  };

  const validateForm = (input): boolean => {
    const validated = false;
    if (!input.companyName) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Company name is required",
        life: 3000,
      });
      return validated;
    }
    if (!input.currency) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Base currency is required",
        life: 3000,
      });
      return validated;
    }
    if (!input.addressLine1) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Address line 1 is required",
        life: 3000,
      });
      return validated;
    }

    if (!input.baseUnit) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Base unit is required",
        life: 3000,
      });
      return validated;
    }
    if (!input.companyEmail) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Company email is required for login to the POS portal",
        life: 3000,
      });
      return validated;
    }
    if (!input.verifyCompanyEmail) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Verify company email",
        life: 3000,
      });
      return validated;
    }
    if (input.verifyCompanyEmail !== input.companyEmail) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Company email is not same as verify email",
        life: 3000,
      });
      return validated;
    }

    if (!input.password) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Password is required for login to the POS portal",
        life: 3000,
      });
      return validated;
    }
    if (!input.password2) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Verify password",
        life: 3000,
      });
      return validated;
    }

    if (input.password !== input.password2) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Password is not same as verify password",
        life: 3000,
      });
      return validated;
    }

    return true;
  };

  const onSubmitHandler: SubmitHandler<ICompanySetup> = (data) => {
    const setupData = {
      ...data,
      baseUnit: selectedBaseUnit,
      currency: selectedCurrency,
    };
    if (validateForm(setupData)) {
      handleSetupCompany(setupData as ICompanySetup);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div>
        <div style={{ display: "flex", gap: "10px", width: "100%" }}>
          <div style={{ width: "100%" }}>
            <FieldsWrapper>
              <FieldWrapper>
                <Text bold noStack>
                  Company name - (the name of your company e.g Choice place
                  mini-mart):
                </Text>
                <InputWrapper
                  id="companyName"
                  type="text"
                  placeholder="Company name"
                  {...register("companyName" as FieldPath<ICompanySetup>, {
                    required: "Company name is required!",
                  })}
                  aria-invalid={errors.companyName ? "true" : "false"}
                  className={classNames(
                    {
                      "p-invalid": errors.companyName?.type === "required",
                    },
                    "p-inputtext-lg"
                  )}
                />
                {errors.companyName && (
                  <DisplayErrorMessage>
                    <small className="p-error">
                      {errors.companyName.message}
                    </small>
                  </DisplayErrorMessage>
                )}
              </FieldWrapper>
            </FieldsWrapper>
            <FieldsWrapper>
              <FieldWrapper>
                <Text bold noStack>
                  Address line 1 - (the location of your company)
                </Text>
                <InputWrapper
                  id="addressLine1"
                  type="text"
                  placeholder="Enter company address line 1"
                  {...register("addressLine1" as FieldPath<ICompanySetup>, {
                    required: "Address line 1 is required!",
                  })}
                  aria-invalid={errors.addressLine1 ? "true" : "false"}
                  className={classNames(
                    {
                      "p-invalid": errors.addressLine1?.type === "required",
                    },
                    "p-inputtext-lg"
                  )}
                />

                {errors.addressLine1 && (
                  <DisplayErrorMessage>
                    <small className="p-error">
                      {errors.addressLine1.message}
                    </small>
                  </DisplayErrorMessage>
                )}
              </FieldWrapper>
            </FieldsWrapper>
            <FieldsWrapper>
              <FieldWrapper>
                <Text bold noStack>
                  Address line 2:
                </Text>
                <InputWrapper
                  id="addressLine2"
                  placeholder="Enter company address line 2"
                  type="text"
                  {...register("addressLine2" as FieldPath<ICompanySetup>)}
                />
              </FieldWrapper>
            </FieldsWrapper>
            <FieldsWrapper>
              <FieldWrapper>
                <Text bold noStack>
                  Address line 3:
                </Text>
                <InputWrapper
                  id="addressLine3"
                  placeholder="Enter company address line 3"
                  type="text"
                  {...register("addressLine3" as FieldPath<ICompanySetup>)}
                />
              </FieldWrapper>
            </FieldsWrapper>
            <FieldsWrapper>
              <FieldWrapper>
                <Text bold noStack>
                  Phone Number:
                </Text>
                <InputWrapper
                  id="contact"
                  placeholder="Enter company phone/contact number"
                  type="text"
                  {...register("contact" as FieldPath<ICompanySetup>)}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Text bold noStack>
                  Base Currency:
                </Text>
                <DropdownWrapper
                  id="currencies"
                  name="currencies"
                  options={currencies}
                  optionLabel="code"
                  optionValue="_id"
                  placeholder="Select currency"
                  value={selectedCurrency}
                  onChange={(e) =>
                    handleFormChange("currency", e.value.toString())
                  }
                />
              </FieldWrapper>
            </FieldsWrapper>
            <FieldsWrapper style={{ marginTop: "10px", marginBottom: "20px" }}>
              <FieldWrapper>
                <Text bold noStack>
                  Base Unit:
                </Text>
                <DropdownWrapper
                  id="units"
                  name="units"
                  options={["pcs", "pck"]}
                  placeholder="Select base unit"
                  value={selectedBaseUnit}
                  onChange={(e) =>
                    handleFormChange("baseUnit", e.value.toString())
                  }
                />
              </FieldWrapper>
            </FieldsWrapper>
            <Text marginTop="-10px" color="red">
              This is the base unit of all transaction for your company you can
              create more once you log into the application.
              <br />
              <br />
              PCS, PCK is the possible base unit at the moment.
              <br />
              <br />
              Other units like
              <b> Carton</b> is a parent of <b>PCS</b>
            </Text>
          </div>

          <div style={{ width: "100%" }}>
            <FieldsWrapper>
              <FieldWrapper>
                <Text bold noStack>
                  Company email -{" "}
                  <b>
                    (the email you&apos;ll use to login to this company portal)
                  </b>
                </Text>
                <InputWrapper
                  id="companyEmail"
                  placeholder="Enter company email address"
                  type="email"
                  {...register("companyEmail" as FieldPath<ICompanySetup>, {
                    required: "Company email is required!",
                  })}
                  aria-invalid={errors.companyEmail ? "true" : "false"}
                  className={classNames(
                    {
                      "p-invalid": errors.companyEmail?.type === "required",
                    },
                    "p-inputtext-lg"
                  )}
                />

                {errors.companyEmail && (
                  <DisplayErrorMessage>
                    <small className="p-error">
                      {errors.companyEmail.message}
                    </small>
                  </DisplayErrorMessage>
                )}
              </FieldWrapper>
            </FieldsWrapper>

            <FieldsWrapper>
              <FieldWrapper>
                <Text bold noStack>
                  Verify company email:
                </Text>
                <InputWrapper
                  id="verifyCompanyEmail"
                  placeholder="Verify company email address"
                  type="email"
                  {...register(
                    "verifyCompanyEmail" as FieldPath<ICompanySetup>,
                    {
                      required: "Company email is required!",
                    }
                  )}
                  aria-invalid={errors.verifyCompanyEmail ? "true" : "false"}
                  className={classNames(
                    {
                      "p-invalid":
                        errors.verifyCompanyEmail?.type === "required",
                    },
                    "p-inputtext-lg"
                  )}
                />

                {errors.verifyCompanyEmail && (
                  <DisplayErrorMessage>
                    <small className="p-error">
                      {errors.verifyCompanyEmail.message}
                    </small>
                  </DisplayErrorMessage>
                )}
              </FieldWrapper>
            </FieldsWrapper>

            <div style={{ marginTop: "50px" }}>
              <Fieldset
                legend="Enter login password"
                pt={{
                  legend: {
                    style: {
                      padding: "10px",
                    },
                  },
                }}>
                <FieldWrapper>
                  <Text bold noStack>
                    Enter Login password:
                  </Text>
                  <InputWrapper
                    id="password"
                    type="password"
                    placeholder="Enter login password"
                    {...register("password" as FieldPath<ICompanySetup>, {
                      required: "Password is required!",
                    })}
                    aria-invalid={errors.password ? "true" : "false"}
                    className={classNames(
                      {
                        "p-invalid": errors.password?.type === "required",
                      },
                      "p-inputtext-lg"
                    )}
                  />
                  {errors.password && (
                    <DisplayErrorMessage>
                      <small className="p-error">
                        {errors.password.message}
                      </small>
                    </DisplayErrorMessage>
                  )}
                </FieldWrapper>

                <FieldWrapper>
                  <Text bold noStack>
                    Confirm Login password:
                  </Text>
                  <InputWrapper
                    id="password2"
                    type="password"
                    placeholder="Confirm password"
                    {...register("password2" as FieldPath<ICompanySetup>, {
                      required: "Password is required!",
                    })}
                    aria-invalid={errors.password2 ? "true" : "false"}
                    className={classNames(
                      {
                        "p-invalid": errors.password2?.type === "required",
                      },
                      "p-inputtext-lg"
                    )}
                  />
                  {errors.password2 && (
                    <DisplayErrorMessage>
                      <small className="p-error">
                        {errors.password2.message}
                      </small>
                    </DisplayErrorMessage>
                  )}
                </FieldWrapper>
              </Fieldset>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            label={loading ? "SAVING, PLEASE WAIT..." : "SETUP NEW COMPANY"}
            size="large"
            style={{ width: "330px", height: "60px" }}
            severity="success"
            disabled={loading || !isDirty || !isValid}
          />
        </div>
      </div>
      <Toast ref={toast} />
    </form>
  );
};

export default SetupCompany;
