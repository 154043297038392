import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { chartconLabel } from "../../text";

const AddCircle = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    aria-label={chartconLabel}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 18 18">
    <path
      fill={colour}
      d="M16 1H2c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1zM7 13H5V8h2v5zm3 0H8V5h2v8zm3 0h-2V9h2v4z"
    />
  </svg>
);

export default AddCircle;
