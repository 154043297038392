import { MutableRefObject, useEffect, useState } from "react";
import { ToastLife } from "@Types/global.types";
import { getRequest, postRequest } from "../../axios/requests";

export interface ICurrency {
  name: string;
  code: string;
  _id: string;
}

interface IUseCurrencyProps {
  toast?: MutableRefObject<null>;
}
export interface IUseCurrency {
  currencies: ICurrency[];
}

export const useCurrency = (props: IUseCurrencyProps): IUseCurrency => {
  const [currencies, setCurrencies] = useState<ICurrency[]>([]);
  const [currencyCreated, setCurrencyCreated] = useState(false);

  const fetchCurrencies = () => getRequest("/currency");
  const createCurrencies = (data) => postRequest("/currency", data);

  const { toast } = props;

  const handleCreateCurrency = () => {
    createCurrencies({ symbol: "₦", code: "NGN" })
      .then(() => {
        setCurrencyCreated(true);
      })
      .catch(() => {});
  };

  const handleFetchCurrencies = () => {
    fetchCurrencies()
      .then((response) => {
        if (response.data.length) {
          setCurrencies(response.data);
        } else {
          handleCreateCurrency();
        }
      })
      .catch((error) => {
        if (toast) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: ToastLife,
          });
        }
      });
  };

  useEffect(() => {
    handleFetchCurrencies();
  }, [currencyCreated]);

  return {
    currencies,
  };
};
