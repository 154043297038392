import { ReactElement, useMemo } from "react";
import { getLoginUser } from "@Utils/get-login-user";
import { PageUrl, UserRoleEnum } from "@Types/global.types";
import { Navigate } from "react-router-dom";

const PrivateComponent = ({ children }: { children: ReactElement }) => {
  const { role } = getLoginUser();
  const token = sessionStorage.getItem(process.env.TOKEN_NAME);
  const shouldRedirect = !token && !role;

  const getRouteLink = useMemo(() => {
    if (UserRoleEnum.CASHIER === role) {
      return PageUrl.Login;
    }
    if (UserRoleEnum.SUPERADMIN === role) {
      return PageUrl.SuperLogin;
    }
    return PageUrl.AdminLogin;
  }, [role]);

  // eslint-disable-next-line no-nested-ternary
  return shouldRedirect ? (
    <Navigate to={getRouteLink} />
  ) : role &&
    [
      UserRoleEnum.WAREHOUSEADMIN,
      UserRoleEnum.SUPERADMIN,
      UserRoleEnum.CASHIER,
      UserRoleEnum.STOREADMIN,
    ].includes(role as UserRoleEnum.SUPERADMIN) ? (
    <>{children}</>
  ) : (
    <Navigate to={getRouteLink} />
  );
};

export default PrivateComponent;
