import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { dragIconLabel } from "../../text";

const DragIcon = ({ colour, size }: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={dragIconLabel}>
    <circle cx="9" cy="7" r="1.5" fill={colour} />
    <circle cx="9" cy="12" r="1.5" fill={colour} />
    <circle cx="9" cy="17" r="1.5" fill={colour} />
    <circle cx="15" cy="7" r="1.5" fill={colour} />
    <path
      d="M16.5 12C16.5 12.8284 15.8284 13.5 15 13.5C14.1716 13.5 13.5 12.8284 13.5 12C13.5 11.1716 14.1716 10.5 15 10.5C15.8284 10.5 16.5 11.1716 16.5 12Z"
      fill="#9E9E9E"
    />
    <circle cx="15" cy="17" r="1.5" fill={colour} />
  </svg>
);

export default DragIcon;
