import Text from "@Core/Text";
import {
  CustomFieldSet,
  SearchFieldWrapper,
  SearchCustomer,
} from "@Molecules/CashierCustomers/styles";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import {
  DataTable,
  DataTableFilterMeta,
  DataTableRowData,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { IFilter, ToastLife } from "@Types/global.types";
import { Toast } from "primereact/toast";
import { useCashierCache } from "@Hooks/useCashierCache/useCashierCache";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { ICustomer } from "@Pages/Customer/Customer";
import { useCustomer } from "@Hooks/useCustomer/useCustomer";
import CreateCounterCustomer from "@Molecules/CreateCounterCustomer";
import { postRequest } from "../../../axios/requests";

interface ICustomerSearch {
  onCustomerSelected: (data: ICustomer) => void;
}

const CashierCustomers = (props: ICustomerSearch) => {
  const { onCustomerSelected } = props;

  const { handleFetchCustomers } = useCustomer({ disableAPICall: false });
  const { customers } = useCashierCache();

  const toast = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showNewForm, setShowNewForm] = useState<boolean>(false);
  const [displayedCustomers, setDisplayedCustomers] = useState<ICustomer[]>([]);

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const [filters, setFilters] = useState<IFilter>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const fetchCustomerByPhone = (phone: string) =>
    postRequest("/customers/search/by/phone-number", { phoneNumber: phone });

  const handleFetchCustomerByPhoneNumber = (name: string) => {
    setLoading(true);
    fetchCustomerByPhone(name)
      .then((response) => {
        setDisplayedCustomers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setDisplayedCustomers([]);
        setLoading(false);
        if (toast) {
          toast?.current.show({
            severity: "error",
            summary:
              "Customer with this phone number does not exist on the system",
            detail: error.message,
            life: ToastLife,
          });
        }
      });
  };

  const handleUpdateCustomerPhoneNumber = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
  };

  const handleSearchCustomer = () => {
    handleFetchCustomerByPhoneNumber(phoneNumber);
  };

  const handleFetchAllCustomers = async () => {
    setLoading(true);
    await handleFetchCustomers((pData) => {
      setDisplayedCustomers(pData);
      setLoading(false);
    });
  };

  const handleSelectCustomer = (data: ICustomer) => {
    onCustomerSelected?.(data);
  };

  const handleNewCustomerCreated = (data: ICustomer) => {
    onCustomerSelected?.(data);
    setShowNewForm(false);
    handleFetchCustomers();
  };

  const handleCancelCreateCustomer = () => {
    setShowNewForm(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchCustomer();
    }
  };

  useEffect(() => {
    if (customers.length) {
      setDisplayedCustomers(customers);
    }
  }, [customers]);

  const actionButtons = (rowData: ICustomer) => (
    <Button
      size="small"
      type="button"
      label="Select"
      onClick={() => handleSelectCustomer(rowData)}
    />
  );

  const renderHeader = () => (
    <div className="flex justify-content-end">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
        />
      </span>
    </div>
  );

  const header = renderHeader();

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <CustomFieldSet
        legend={
          <Text style={{ padding: "0 5px" }}>
            Search customer by phone number
          </Text>
        }>
        <SearchFieldWrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "stretch",
            }}>
            <div className="p-inputgroup flex-1" style={{ width: "60%" }}>
              <div>
                <Text p bold fontSize="15px" margin={false} fontWeight="bolder">
                  Can&apos;t find the customer? Search the database
                </Text>
                <div style={{ display: "flex", gap: "10px" }}>
                  <SearchCustomer
                    id="phoneNumber"
                    type="text"
                    name="phoneNumber"
                    value={phoneNumber}
                    onKeyPress={handleKeyPress}
                    onChange={handleUpdateCustomerPhoneNumber}
                    autoFocus
                    placeholder="Find customer in the database"
                  />
                  <Button
                    label="Search database"
                    style={{ width: "400px" }}
                    icon="pi pi-magnify"
                    loading={loading}
                    onClick={handleSearchCustomer}
                  />
                </div>
              </div>
            </div>
            <div>
              <Button
                label="Get All Customers"
                style={{ width: "200px", height: "50px", marginTop: "20px" }}
                severity="info"
                outlined
                onClick={handleFetchAllCustomers}
                size="small"
              />
            </div>
          </div>
        </SearchFieldWrapper>
        <div
          style={{
            display: "flex",
            gap: "10px",
            height: "500px",
            marginTop: "20px",
          }}>
          <div
            style={{
              paddingTop: "20px",
              width: "70%",
              overflow: "scroll",
            }}>
            <DataTable
              value={displayedCustomers as DataTableRowData<any>}
              paginator
              scrollable
              showGridlines
              rows={50}
              size="small"
              totalRecords={displayedCustomers.length}
              rowsPerPageOptions={[50, 100, 150, 200, 300]}
              dataKey="_id"
              loading={loading}
              filters={filters as DataTableFilterMeta}
              header={header}
              emptyMessage="No customer found.">
              <Column
                header="Action"
                style={{ width: "10%" }}
                body={actionButtons}
                frozen
                alignFrozen="right"
                bodyStyle={{ textAlign: "center" }}
              />
              <Column
                field="customerId"
                header="Id"
                style={{ width: "100px" }}
              />
              <Column
                field="name"
                header="Customer name"
                style={{ width: "300px" }}
              />
              <Column
                header="Phone number"
                field="phoneNumber"
                style={{ width: "200px" }}
              />
              <Column
                header="Address"
                field="address"
                style={{ width: "200px" }}
              />
              <Column
                field="remark"
                header="Remark"
                style={{ width: "300px" }}
              />
            </DataTable>
          </div>
          <div
            style={{
              width: "30%",
              margin: showNewForm ? "unset" : "auto",
            }}>
            {showNewForm ? (
              <div style={{ padding: "0 10px" }}>
                <CreateCounterCustomer
                  onCreated={handleNewCustomerCreated}
                  onCancel={handleCancelCreateCustomer}
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Button
                  label="Add new customer"
                  onClick={() => setShowNewForm(true)}
                />
              </div>
            )}
          </div>
        </div>
      </CustomFieldSet>
      <Toast ref={toast} />
    </div>
  );
};

export default CashierCustomers;
