import { HelmetProvider } from "react-helmet-async";
import { createRoot } from "react-dom/client";
// import * as Sentry from "@sentry/react";
// import { BrowserOptions } from "@sentry/browser";
import App from "./App";
//
// Sentry.init({
//   dsn:
//     "https://6353207daa1ac865c66cafc593cf4f84@o4506280180514816.ingest.sentry.io/4506280183988224",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         "localhost",
//         /http:\/\/localhost:8040\/api\/v1/,
//         /^https:\/\/push-it-pos-back-dev-bsjq\.3\.us-1\.fl0\.io\/api\/v1/,
//         /^https:\/\/pos-staging-back-dev-hjab\.3\.us-1\.fl0\.io\/api\/v1/,
//         /^https:\/\/push-it-pos-back-hmgh\.4\.us-1\.fl0\.io\/api\/v1/,
//       ], // /^https:\/\/yourserver\.io\/api/
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// } as BrowserOptions);

const app = (
  <HelmetProvider>
    <App />
  </HelmetProvider>
);

const root = createRoot(document.querySelector("#root") as Element);
root.render(app);
