import { ReactElement } from "react";
import { chartIconLabel } from "../../text";

const ChartIcon = (): ReactElement => (
  <svg
    aria-label={chartIconLabel}
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.196777 2.25342C0.196777 1.42499 0.86835 0.753418 1.69678 0.753418H15.5718C16.4002 0.753418 17.0718 1.42499 17.0718 2.25342V13.3784C17.0718 14.2068 16.4002 14.8784 15.5718 14.8784H1.69678C0.86835 14.8784 0.196777 14.2068 0.196777 13.3784V2.25342Z"
      fill="#C4C4C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5718 1.75342H1.69678C1.42063 1.75342 1.19678 1.97728 1.19678 2.25342V13.3784C1.19678 13.6546 1.42063 13.8784 1.69678 13.8784H15.5718C15.8479 13.8784 16.0718 13.6546 16.0718 13.3784V2.25342C16.0718 1.97728 15.8479 1.75342 15.5718 1.75342ZM1.69678 0.753418C0.86835 0.753418 0.196777 1.42499 0.196777 2.25342V13.3784C0.196777 14.2068 0.86835 14.8784 1.69678 14.8784H15.5718C16.4002 14.8784 17.0718 14.2068 17.0718 13.3784V2.25342C17.0718 1.42499 16.4002 0.753418 15.5718 0.753418H1.69678Z"
      fill="black"
    />
    <path
      d="M5.29248 10.7484L8.29248 7.74342L10.2925 9.74342L14.5425 4.96342L13.8375 4.25842L10.2925 8.24342L8.29248 6.24342L4.54248 9.99842L5.29248 10.7484Z"
      fill="black"
    />
    <rect
      x="4.80994"
      y="3.10614"
      width="2.01305"
      height="8.54005"
      fill="black"
      fillOpacity="0.3"
    />
    <rect
      x="8.72095"
      y="3.10614"
      width="2.01305"
      height="8.54005"
      fill="black"
      fillOpacity="0.3"
    />
    <rect
      x="12.6323"
      y="3.10614"
      width="2.01305"
      height="8.54005"
      fill="black"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.15112 3.10693H3.15112V11.6069H14.6511V12.6069H2.15112V3.10693Z"
      fill="black"
    />
  </svg>
);

export default ChartIcon;
