import { MutableRefObject, useEffect, useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import {
  IFilter,
  INetworkResponse,
  IProduct,
  ToastLife,
} from "@Types/global.types";
import Alert from "@Atoms/Alert/Alert";
import { getCompanyInfo } from "@Utils/get-login-user";
import { deleteRequest, getRequest } from "../../axios/requests";

export interface IUnit {
  _id: string;
  name: string;
  unit: number;
  storeOrWarehouseId: {
    _id: string;
    name: string;
    storeCode: string;
  };
  price: number;
  storageName: string;
  productQuantityCount?: number;
}

interface IUseUnit {
  handleCancelEdit: () => void;
  filters: IFilter;
  onGlobalFilterChange: (e: { target: { value: string } }) => void;
  globalFilterValue: string;
  loading: boolean;
  toast: MutableRefObject<null>;
  setUnit: (
    value: ((prevState: IUnit | null) => IUnit | null) | IUnit | null
  ) => void;
  setFilters: (value: ((prevState: IFilter) => IFilter) | IFilter) => void;
  units: IUnit[];
  unit: IUnit | null;
  isNewForm: boolean;
  baseUnit: string | undefined;
  setIsNewForm: (value: ((prevState: boolean) => boolean) | boolean) => void;
  handleFetchUnits: (showErrorMessage?: boolean) => void;
  handleDeleteCategory: (product: IProduct) => void;
}

export const useUnit = (options: { disableAPICall: boolean }): IUseUnit => {
  const { baseUnit } = getCompanyInfo();
  const toast = useRef(null);

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [units, setUnits] = useState<IUnit[]>([]);
  const [unit, setUnit] = useState<IUnit | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isNewForm, setIsNewForm] = useState<boolean>(true);
  const [filters, setFilters] = useState<IFilter>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const deleteCategoryById = (id: string) => deleteRequest(`/units/${id}`);

  const fetchUnits = () => getRequest("/units");

  const handleFetchUnits = (showErrorMessage: boolean = true) => {
    setLoading(true);
    fetchUnits()
      .then((response) => {
        setUnits([
          {
            _id: baseUnit || "pcs",
            name: baseUnit,
            unit: 1,
          },
          ...response.data,
        ]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (showErrorMessage) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: ToastLife,
          });
        }
      });
  };

  const handleDeleteCategory = (rowData: IProduct) => {
    Alert(() => {
      deleteCategoryById(rowData._id)
        .then((response) => {
          handleFetchUnits(true);
          if (toast) {
            toast?.current.show({
              severity: "success",
              summary: "Success",
              detail: (response as INetworkResponse)?.message,
              life: ToastLife,
            });
          }
        })
        .catch((error) => {
          if (toast) {
            toast?.current.show({
              severity: "error",
              summary: "Error",
              detail: error.message,
              life: ToastLife,
            });
          }
        });
    });
  };

  useEffect(() => {
    if (options.disableAPICall) return;
    handleFetchUnits();
  }, []);

  const handleCancelEdit = () => {
    setUnit(null);
  };

  return {
    setFilters,
    baseUnit,
    handleCancelEdit,
    toast,
    unit,
    onGlobalFilterChange,
    globalFilterValue,
    units,
    loading,
    isNewForm,
    setIsNewForm,
    filters,
    handleFetchUnits,
    setUnit,
    handleDeleteCategory,
  };
};
