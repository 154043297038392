import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { warningIconLabel } from "../../text";

const Warning = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    aria-label={warningIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16">
    <g>
      <path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,12c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1 S8.6,12,8,12z M9,9H7V4h2V9z" />
    </g>
  </svg>
);

export default Warning;
