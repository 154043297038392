import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { pinIconLabel } from "../../text";

const Pin = ({
  colour = "white",
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 17 18"
    fill="none"
    aria-hidden="true"
    aria-label={pinIconLabel}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.7143 2.80077C10.3001 2.21498 11.2498 2.21498 11.8356 2.80077L12.9522 3.9174L13.4733 4.43845L14.5899 5.55508C15.1757 6.14087 15.1757 7.09062 14.5899 7.6764L13.9077 8.35868C13.6001 8.66621 13.1193 8.69488 12.7795 8.4447L11.461 9.76324C11.3247 9.89947 11.2596 10.1299 11.3155 10.3746C11.3891 10.6971 11.4619 11.1178 11.4856 11.5902C11.5055 11.989 11.4276 12.3462 11.3174 12.6425C11.1478 13.098 10.761 13.3433 10.3499 13.3767C9.95815 13.4086 9.55855 13.2516 9.2681 12.9612L7.3283 11.0214L4.26421 14.0855C4.06894 14.2807 3.75236 14.2807 3.5571 14.0855C3.36184 13.8902 3.36184 13.5736 3.5571 13.3784L6.62119 10.3143L6.58829 10.2814L4.42953 8.1226C4.13908 7.83215 3.98213 7.43255 4.01397 7.04078C4.04739 6.62968 4.29272 6.24291 4.74823 6.07334C5.04446 5.96307 5.40166 5.88516 5.80054 5.90512C6.27289 5.92875 6.69362 6.00156 7.01612 6.07521C7.2608 6.13109 7.49123 6.06597 7.62746 5.92974L8.946 4.6112C8.69582 4.27136 8.7245 3.79057 9.03202 3.48305L9.7143 2.80077ZM11.1285 3.50788L12.2451 4.6245L12.7662 5.14556L13.8828 6.26219C14.0781 6.45745 14.0781 6.77403 13.8828 6.96929L13.2866 7.56555C12.9467 7.31537 12.4659 7.34404 12.1584 7.65157L10.7539 9.05613C10.3346 9.47541 10.2216 10.076 10.3406 10.5972C10.4045 10.8769 10.4667 11.2387 10.4868 11.6401C10.4987 11.8778 10.4528 12.0985 10.3802 12.2936C10.3648 12.3351 10.3472 12.3511 10.3371 12.3584C10.3252 12.367 10.3042 12.3771 10.2689 12.38C10.1929 12.3862 10.0753 12.3541 9.97521 12.2541L7.81645 10.0953L7.29539 9.57425L5.13664 7.41549C5.03656 7.31542 5.00451 7.1978 5.01069 7.1218C5.01355 7.08654 5.02369 7.0655 5.03234 7.05358C5.03964 7.04351 5.05563 7.02595 5.09709 7.01051C5.29221 6.93788 5.51288 6.89198 5.75057 6.90387C6.15204 6.92396 6.51377 6.98623 6.79347 7.05011C7.31467 7.16914 7.91529 7.05612 8.33457 6.63684L9.73913 5.23228C10.0467 4.92475 10.0753 4.44397 9.82515 4.10413L10.4214 3.50788C10.6167 3.31261 10.9333 3.31261 11.1285 3.50788Z"
      fill={colour}
    />
  </svg>
);

export default Pin;
