import Text from "@Core/Text";
import { classNames } from "primereact/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import CurrencyWrapper from "@Atoms/CurrencyWrapper";
import { FieldPath, SubmitHandler, useForm } from "react-hook-form";
import { INetworkResponse, ToastLife } from "@Types/global.types";
import { Fieldset } from "primereact/fieldset";
import { v4 } from "uuid";
import {
  IPurchaseInvoices,
  IPurchaseItem,
} from "@Components/pages/PurchaseInvoices/PurchaseInvoices";
import { Toast } from "primereact/toast";
import { postRequest, putRequest } from "../../../axios/requests";
import {
  ButtonWrapper,
  CalendarWrapper,
  CreatePurchaseInvoiceWrapper,
  CustomButton,
  CustomTD,
  CustomTH,
  CustomTR,
  CustomTable,
  DisplayErrorMessage,
  FieldWrapper,
  FieldsWrapper,
  FormCard,
  FormContainer,
  FormSection,
  InputNumberWrapper,
  InputTextareaContentWrapper,
  InputTextareaWrapper,
  InputWrapper,
  PurchaseInvoiceForm,
} from "./styles";

interface IFormValues {
  date: string;
}
const initialValues: IPurchaseInvoices = {
  _id: "",
  name: "",
  invoiceNumber: "",
  phoneNumber: "",
  phoneNumber2: "",
  phoneNumber3: "",
  total: 0,
  address: "",
  email: "",
  date: "",
  remark: "",
  items: [
    {
      description: "",
      _description: "",
      rate: 0,
      quantity: 1,
      amount: 0,
      id: "",
    },
  ],
};

const initialFormValues: IFormValues = {
  date: "",
};

interface IFormOptions {
  defaultValues: IPurchaseInvoices;
}

const CreatePurchaseInvoices = ({
  selectedPurchaseInvoice,
  onCancel,
  refreshTableData,
}: {
  selectedPurchaseInvoice: IPurchaseInvoices | null | undefined;
  onCancel: () => void;
  refreshTableData: () => void;
}) => {
  const defaultItems = {
    id: v4(),
    description: "",
    _description: "",
    rate: 0,
    quantity: 1,
    amount: 0,
  };
  const toast = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [formValues, setFormValues] = useState<IFormValues>(initialFormValues);
  const [purchaseItems, setPurchaseItems] = useState<IPurchaseItem[]>([
    {
      description: "",
      _description: "",
      rate: 0,
      quantity: 1,
      amount: 0,
      id: v4(),
    },
  ]);
  const formOptions: IFormOptions = {
    defaultValues: initialValues,
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<IPurchaseInvoices>({ ...formOptions, mode: "onBlur" });

  const CreatePurchaseInvoice = (productData: IPurchaseInvoices) =>
    postRequest("/purchase-invoice", productData);
  const editPurchaseInvoice = (id: string, productData: IPurchaseInvoices) =>
    putRequest(`/purchase-invoice/${id}`, productData);

  const handleCreatePurchaseInvoice = (data: IPurchaseInvoices) => {
    setIsSaving(true);
    CreatePurchaseInvoice(data)
      .then((response) => {
        setIsSaving(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: ((response as unknown) as INetworkResponse).message,
          life: ToastLife,
        });
        setPurchaseItems([defaultItems]);
        refreshTableData();
        reset();
      })
      .catch((error) => {
        setIsSaving(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: ToastLife,
        });
      });
  };

  const handleUpdatePurchaseInvoice = (id: string, data: IPurchaseInvoices) => {
    setIsSaving(true);
    editPurchaseInvoice(id, data)
      .then((response) => {
        setIsSaving(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: (response as INetworkResponse).message,
          life: ToastLife,
        });
        reset();
        refreshTableData();
        onCancel?.();
      })
      .catch((error) => {
        setIsSaving(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: ToastLife,
        });
      });
  };

  useEffect(() => {
    if (selectedPurchaseInvoice) {
      Object.entries(selectedPurchaseInvoice).forEach(([key, value]) =>
        setValue(key as FieldPath<IPurchaseInvoices>, value)
      );
      setPurchaseItems(selectedPurchaseInvoice?.items ?? []);
      setFormValues(
        (prev) =>
          ({
            ...prev,
            date: selectedPurchaseInvoice.date,
          } as IFormValues)
      );
    } else {
      setFormValues(initialFormValues);
      reset();
    }
  }, [selectedPurchaseInvoice]);

  const handleDateChange = (name: string, value: Date | null) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getTotalPurchaseItems = useMemo(
    () =>
      purchaseItems.reduce(
        (accumulator, items) => accumulator + items.amount,
        0
      ),
    [purchaseItems]
  );

  const onSubmitHandler: SubmitHandler<IPurchaseInvoices> = (data) => {
    const purchaseInvoiceInput = {
      ...data,
      ...formValues,
      total: purchaseItems
        .filter((product) => product._description)
        .reduce((accumulator, items) => accumulator + items.amount, 0),
      items: purchaseItems
        .filter((product) => product._description)
        .map((product) => ({ ...product, description: product._description })),
    };

    if (purchaseInvoiceInput && !selectedPurchaseInvoice?._id) {
      handleCreatePurchaseInvoice(purchaseInvoiceInput as IPurchaseInvoices);
    } else if (selectedPurchaseInvoice?._id && purchaseInvoiceInput) {
      handleUpdatePurchaseInvoice(
        selectedPurchaseInvoice._id,
        purchaseInvoiceInput as IPurchaseInvoices
      );
    }
  };

  const handleClearForm = () => {
    onCancel?.();
    setPurchaseItems([
      {
        description: "",
        _description: "",
        rate: 1,
        quantity: 1,
        amount: 0,
        id: v4(),
      },
    ]);
    reset();
  };

  const getIsDisabled = useMemo(() => {
    if (selectedPurchaseInvoice?._id) return isSaving;

    return !isValid || isSaving;
  }, [isValid, isSaving, selectedPurchaseInvoice]);

  const handlePurchaseItemChange = (
    productId: string,
    name: string,
    value: string | number | null
  ) => {
    setPurchaseItems((prev) =>
      prev.map((data) => {
        if (data.id === productId) {
          if (name === "quantity" && value < 1) {
            value = Math.abs(Number(value));
          }
          const newData = { ...data, [name]: value };
          const qty = newData.quantity ?? 1;
          const rate = newData.rate ?? 1;
          return { ...newData, amount: Number(qty * rate) };
        }
        return data;
      })
    );
  };

  const handleInsertNewProduct = () => {
    const initialValue = purchaseItems[purchaseItems.length - 1];
    if (initialValue) {
      setPurchaseItems((prev) => [
        ...prev,
        {
          description: "",
          _description: "",
          rate: 0,
          quantity: 1,
          amount: 0,
          id: v4(),
        },
      ]);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <CreatePurchaseInvoiceWrapper>
        <Text span noStack>
          Fill in all (*) fields
        </Text>
        <PurchaseInvoiceForm onSubmit={handleSubmit(onSubmitHandler)}>
          <FormContainer id="form-container">
            <FormSection>
              <FormCard>
                <FieldsWrapper>
                  <FieldWrapper>
                    <Text bold noStack>
                      Name(*):
                    </Text>
                    <InputWrapper
                      id="name"
                      type="text"
                      placeholder="Seller's name"
                      {...register("name" as FieldPath<IPurchaseInvoices>, {
                        required: "Seller name is required",
                      })}
                      aria-invalid={errors.name ? "true" : "false"}
                      className={classNames(
                        {
                          "p-invalid": errors.name?.type === "required",
                        },
                        "p-inputtext-lg"
                      )}
                    />
                    {errors.name && (
                      <DisplayErrorMessage>
                        <small className="p-error">{errors.name.message}</small>
                      </DisplayErrorMessage>
                    )}
                  </FieldWrapper>
                  <FieldWrapper>
                    <Text bold noStack>
                      Address (*):
                    </Text>
                    <InputWrapper
                      id="address"
                      type="text"
                      {...register("address" as FieldPath<IPurchaseInvoices>, {
                        required: "Address is required!",
                      })}
                      aria-invalid={errors.address ? "true" : "false"}
                      className={classNames(
                        {
                          "p-invalid": errors.address?.type === "required",
                        },
                        "p-inputtext-lg"
                      )}
                    />
                    {errors.address && (
                      <DisplayErrorMessage>
                        <small className="p-error">
                          {errors.address.message}
                        </small>
                      </DisplayErrorMessage>
                    )}
                  </FieldWrapper>
                  <FieldWrapper>
                    <Text bold noStack>
                      Email:
                    </Text>
                    <InputWrapper
                      id="email"
                      type="email"
                      {...register("email" as FieldPath<IPurchaseInvoices>)}
                    />
                  </FieldWrapper>
                </FieldsWrapper>
                <FieldsWrapper>
                  <FieldWrapper>
                    <Text bold noStack>
                      Phone No 1.:
                    </Text>
                    <InputWrapper
                      id="phoneNumber"
                      type="tel"
                      {...register(
                        "phoneNumber" as FieldPath<IPurchaseInvoices>
                      )}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Text bold noStack>
                      Phone No 2.:
                    </Text>
                    <InputWrapper
                      id="phoneNumber2"
                      type="tel"
                      {...register(
                        "phoneNumber2" as FieldPath<IPurchaseInvoices>
                      )}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Text bold noStack>
                      Phone No 3.:
                    </Text>
                    <InputWrapper
                      id="phoneNumber3"
                      type="tel"
                      {...register(
                        "phoneNumber3" as FieldPath<IPurchaseInvoices>
                      )}
                    />
                  </FieldWrapper>
                </FieldsWrapper>
                <FieldsWrapper>
                  <FieldWrapper>
                    <Text bold noStack>
                      Date (*):
                    </Text>
                    <CalendarWrapper
                      showIcon
                      id="date"
                      placeholder="Date"
                      required
                      value={
                        formValues?.date
                          ? new Date(formValues?.date as string)
                          : new Date()
                      }
                      name="date"
                      onChange={(e) =>
                        handleDateChange("date", e.value as Date)
                      }
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Text bold noStack>
                      Invoice number (*):
                    </Text>
                    <div className="p-inputgroup flex-1">
                      <InputWrapper
                        id="invoiceNumber"
                        type="text"
                        placeholder="Invoice number"
                        {...register(
                          "invoiceNumber" as FieldPath<IPurchaseInvoices>,
                          {
                            required: "Invoice number is required",
                          }
                        )}
                        aria-invalid={errors.invoiceNumber ? "true" : "false"}
                        className={classNames(
                          {
                            "p-invalid":
                              errors.invoiceNumber?.type === "required",
                          },
                          "p-inputtext-lg"
                        )}
                      />
                    </div>
                    {errors.invoiceNumber && (
                      <DisplayErrorMessage>
                        <small className="p-error">
                          {errors.invoiceNumber.message}
                        </small>
                      </DisplayErrorMessage>
                    )}
                  </FieldWrapper>
                  <FieldWrapper>
                    <Text bold noStack>
                      Total:
                    </Text>
                    <CurrencyWrapper>
                      <InputNumberWrapper
                        maxFractionDigits={2}
                        readOnly
                        disabled
                        placeholder="Read only: total"
                        value={getTotalPurchaseItems ?? 0}
                      />
                    </CurrencyWrapper>
                  </FieldWrapper>
                </FieldsWrapper>
                {!!purchaseItems.length && (
                  <Fieldset
                    pt={{
                      root: {
                        style: { marginTop: "20px" },
                      },
                    }}>
                    <Text bold h3>
                      Invoice items
                    </Text>
                    <div style={{ marginBottom: "10px" }}>
                      <Text span color="blue" noStack bold>
                        Please Note: Input all items in the invoice
                      </Text>
                    </div>
                    <CustomTable>
                      <thead>
                        <CustomTR>
                          <CustomTH style={{ width: "50%" }}>
                            Description
                          </CustomTH>
                          <CustomTH style={{ width: "20%" }}>Rate</CustomTH>
                          <CustomTH style={{ width: "12%" }}>Quantity</CustomTH>
                          <CustomTH style={{ width: "40%" }}>Amount</CustomTH>
                        </CustomTR>
                      </thead>
                      <tbody>
                        {purchaseItems.map((product, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <CustomTR key={`${product.description}-${i}`}>
                            <CustomTD>
                              <InputWrapper
                                id="description"
                                type="text"
                                name="description"
                                defaultValue={product.description}
                                placeholder="description"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  handlePurchaseItemChange(
                                    product.id,
                                    "_description",
                                    value
                                  );
                                }}
                              />
                            </CustomTD>
                            <CustomTD>
                              <CurrencyWrapper>
                                <InputNumberWrapper
                                  maxFractionDigits={2}
                                  placeholder="Rate"
                                  onChange={(e) => {
                                    handlePurchaseItemChange(
                                      product.id,
                                      "rate",
                                      e.value
                                    );
                                  }}
                                  value={product.rate ?? 0}
                                />
                              </CurrencyWrapper>
                            </CustomTD>
                            <CustomTD>
                              <div className="p-inputgroup flex-1">
                                <InputWrapper
                                  id="quantity"
                                  type="number"
                                  min={1}
                                  placeholder="Quantity"
                                  defaultValue={product.quantity ?? 0}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    handlePurchaseItemChange(
                                      product.id,
                                      "quantity",
                                      value
                                    );
                                  }}
                                />
                              </div>
                            </CustomTD>
                            <CustomTD>
                              <CurrencyWrapper>
                                <InputNumberWrapper
                                  maxFractionDigits={2}
                                  readOnly
                                  disabled
                                  placeholder="Sub total"
                                  value={product.amount ?? 0}
                                />
                              </CurrencyWrapper>
                            </CustomTD>
                            <CustomTD>
                              <Button
                                icon="pi pi-plus"
                                severity="help"
                                type="button"
                                onClick={handleInsertNewProduct}
                              />
                            </CustomTD>
                          </CustomTR>
                        ))}
                      </tbody>
                    </CustomTable>
                  </Fieldset>
                )}
                <FieldsWrapper>
                  <InputTextareaContentWrapper>
                    <Text bold noStack>
                      Remark:
                    </Text>
                    <InputTextareaWrapper
                      id="remark"
                      rows={4}
                      cols={30}
                      {...register("remark" as FieldPath<IPurchaseInvoices>)}
                    />
                  </InputTextareaContentWrapper>
                </FieldsWrapper>
                <ButtonWrapper>
                  <CustomButton
                    label="Cancel"
                    size="medium"
                    severity="secondary"
                    width="140px"
                    type="button"
                    disabled={isSaving}
                    onClick={handleClearForm}
                  />
                  <Button
                    type="submit"
                    label={
                      isSaving
                        ? "Saving..."
                        : `${
                            selectedPurchaseInvoice?._id ? "Update" : "Save"
                          } invoice`
                    }
                    size="medium"
                    severity="success"
                    disabled={getIsDisabled}
                  />
                </ButtonWrapper>
              </FormCard>
            </FormSection>
          </FormContainer>
        </PurchaseInvoiceForm>
      </CreatePurchaseInvoiceWrapper>
    </>
  );
};

export default CreatePurchaseInvoices;
