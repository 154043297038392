import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { scissorsLabel } from "../../text";

const Scissors = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    aria-label={scissorsLabel}
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 512.009 512.009"
    xmlSpace="preserve">
    <path
      fill={colour}
      d="M446.102,408.415c-6.521-31.26-30.876-56.531-62.065-64.385c-21.334-5.354-42.773-2.656-61.565,7.604L298.4,297.208 l92.117-221.064c2.771-6.656,4.167-13.667,4.167-20.844c0-18.156-9.021-35.01-24.126-45.083L357.932,1.8 c-2.625-1.76-5.896-2.26-8.896-1.365c-3.021,0.875-5.5,3.042-6.771,5.927l-86.252,195.013L169.758,6.363 c-1.271-2.885-3.75-5.052-6.771-5.927c-3.021-0.896-6.292-0.396-8.896,1.365l-12.625,8.417 C126.361,20.29,117.34,37.144,117.34,55.3c0,7.177,1.396,14.187,4.167,20.844l92.114,221.083l-24.091,54.417 c-12.396-6.771-26.084-10.302-40.189-10.302c-47.064,0-85.337,38.281-85.337,85.333c0,47.052,38.272,85.333,85.337,85.333 c47.064,0,85.337-38.281,85.337-85.333c0-31.553,10.326-54.621,21.32-70.391c10.688,15.298,20.671,37.202,21.307,67.349 c1,47.188,36.522,85.948,80.836,88.26c1.542,0.073,3.063,0.115,4.563,0.115c25.022,0,48.439-10.729,64.815-29.844 C444.894,461.884,451.665,434.998,446.102,408.415z M149.338,469.342c-23.521,0-42.667-19.135-42.667-42.667 s19.146-42.667,42.667-42.667c23.521,0,42.667,19.135,42.667,42.667S172.859,469.342,149.338,469.342z M256.004,320.009 c-11.771,0-21.333-9.573-21.333-21.333s9.563-21.333,21.333-21.333c11.771,0,21.333,9.573,21.333,21.333 S267.775,320.009,256.004,320.009z M362.671,469.342c-23.521,0-42.667-19.135-42.667-42.667s19.146-42.667,42.667-42.667 c23.521,0,42.667,19.135,42.667,42.667S386.192,469.342,362.671,469.342z"
    />
  </svg>
);

export default Scissors;
