import { LoginCopyrightWrapper } from "@Atoms/LoginCopyright/styles";
import { getCompanyName } from "@Utils/general-utils";

const year = new Date().getFullYear();

const LoginCopyright = () => (
  <LoginCopyrightWrapper>
    <span>
      &copy;{year} <b>{getCompanyName()}</b>. All right reserved. Powered by{" "}
      <b>Push IT</b>
    </span>
  </LoginCopyrightWrapper>
);

export default LoginCopyright;
