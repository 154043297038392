import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { infoIconLabel } from "../../text";

const Info = ({ colour, size = IconSizes.SM }: IconSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 17"
    aria-hidden="true"
    aria-label={infoIconLabel}
    fill={colour}>
    <path d="M7.25 12.8613H8.75V7.86133H7.25V12.8613ZM8 6.36133C8.208 6.36133 8.385 6.28833 8.531 6.14233C8.677 5.99633 8.75 5.81933 8.75 5.61133C8.75 5.40333 8.677 5.22633 8.531 5.08033C8.385 4.93433 8.208 4.86133 8 4.86133C7.792 4.86133 7.615 4.93433 7.469 5.08033C7.323 5.22633 7.25 5.40333 7.25 5.61133C7.25 5.81933 7.323 5.99633 7.469 6.14233C7.615 6.28833 7.792 6.36133 8 6.36133ZM8 16.8613C6.90267 16.8613 5.868 16.653 4.896 16.2363C3.924 15.8197 3.07333 15.2467 2.344 14.5173C1.61467 13.788 1.04167 12.9373 0.625 11.9653C0.208333 10.9933 0 9.95866 0 8.86133C0 7.74999 0.208333 6.71166 0.625 5.74633C1.04167 4.78166 1.61467 3.93466 2.344 3.20533C3.07333 2.47599 3.924 1.90299 4.896 1.48633C5.868 1.06966 6.90267 0.861328 8 0.861328C9.11133 0.861328 10.1497 1.06966 11.115 1.48633C12.0797 1.90299 12.9267 2.47599 13.656 3.20533C14.3853 3.93466 14.9583 4.78166 15.375 5.74633C15.7917 6.71166 16 7.74999 16 8.86133C16 9.95866 15.7917 10.9933 15.375 11.9653C14.9583 12.9373 14.3853 13.788 13.656 14.5173C12.9267 15.2467 12.0797 15.8197 11.115 16.2363C10.1497 16.653 9.11133 16.8613 8 16.8613ZM8 15.3613C9.80533 15.3613 11.34 14.7293 12.604 13.4653C13.868 12.2013 14.5 10.6667 14.5 8.86133C14.5 7.05599 13.868 5.52133 12.604 4.25733C11.34 2.99333 9.80533 2.36133 8 2.36133C6.19467 2.36133 4.66 2.99333 3.396 4.25733C2.132 5.52133 1.5 7.05599 1.5 8.86133C1.5 10.6667 2.132 12.2013 3.396 13.4653C4.66 14.7293 6.19467 15.3613 8 15.3613Z" />
  </svg>
);

export default Info;
