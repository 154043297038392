import { useMemo, useState } from "react";
import { getItems } from "@Utils/cache";
import { useCategory } from "@Hooks/useCategory/useCategory";
import { ICategory } from "@Pages/Categories/Categories";
import { useProducts } from "@Hooks/useProducts/useProducts";
import { IProduct } from "@Types/global.types";
import { ICustomer } from "@Pages/Customer/Customer";
import { useCustomer } from "@Hooks/useCustomer/useCustomer";
import { IUnit, useUnit } from "@Hooks/useUnit/useUnit";

export const KEYS = {
  CATEGORY: "GET_CATEGORIES",
  PRODUCT: "GET_PRODUCTS",
  CUSTOMER: "GET_CUSTOMERS",
  UNITS: "GET_UNITS",
};

interface IUseCashierCache {
  loading: boolean;
  categories: ICategory[];
  products: IProduct[];
  customers: ICustomer[];
  units: IUnit[];
  handleClearWorkspace: () => void;
  handleGetProductForCategory: (
    id: string,
    cb: (data: IProduct[]) => void
  ) => void;
  handleFindProductByBarcode: (
    barcode: string,
    cb: (data: IProduct | null) => void
  ) => void;
}

export const useCashierCache = (): IUseCashierCache => {
  const [loading, setLoading] = useState(false);
  const { categories: liveCategories, handleFetchCategories } = useCategory({
    disableAPICall: true,
  });
  const { products: liveProducts, handleFetchProducts } = useProducts({
    disableAPICall: true,
  });
  const { customers: liveCustomers, handleFetchCustomers } = useCustomer({
    disableAPICall: true,
  });
  const { units: liveUnits, handleFetchUnits } = useUnit({
    disableAPICall: true,
  });

  const categories = useMemo(() => {
    const cachedCategory = getItems(KEYS.CATEGORY);

    if (cachedCategory && cachedCategory.length) return cachedCategory;

    return liveCategories;
  }, []);

  const products = useMemo(() => {
    const cachedProducts = getItems(KEYS.PRODUCT);

    if (cachedProducts && cachedProducts.length) return cachedProducts;

    return liveProducts;
  }, []);

  const customers = useMemo(() => {
    const cachedCustomers = getItems(KEYS.CUSTOMER);

    if (cachedCustomers && cachedCustomers.length) return cachedCustomers;

    return liveCustomers;
  }, []);

  const units = useMemo(() => {
    const cachedUnits = getItems(KEYS.UNITS);

    if (cachedUnits && cachedUnits.length) return cachedUnits;

    return liveUnits;
  }, []);

  const handleGetProductForCategory = (
    id: string,
    cb: (products: IProduct[]) => void
  ) => {
    const results =
      products.filter((product) => product?.category?._id === id) ?? [];
    cb(results);
  };

  const handleFindProductByBarcode = (
    barcode: string,
    cb: (product: IProduct | null) => void
  ) => {
    const result =
      products.find(
        (product) => product?.barcode.toString() === barcode.toString()
      ) ?? null;

    cb(result ?? null);
  };

  const handleClearWorkspace = () => {
    setLoading(true);
    // cleared
    handleFetchProducts(() => {
      setLoading(false);
    });
    handleFetchUnits();
    handleFetchCategories();
    handleFetchCustomers();
  };

  return {
    categories,
    units,
    handleGetProductForCategory,
    handleFindProductByBarcode,
    handleClearWorkspace,
    products,
    customers,
    loading,
  };
};
