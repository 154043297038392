import {
  DataTable,
  DataTableFilterMeta,
  DataTableRowData,
} from "primereact/datatable";
import TableSearch from "@Atoms/TableSearch";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ButtonWrapper } from "@Atoms/TableHeader/styles";
import { useProducts } from "@Hooks/useProducts/useProducts";
import { ISearcher } from "@Pages/Products/Products";
import { MutableRefObject } from "react";
import { IProduct } from "@Types/global.types";
import { format } from "date-fns";
import FormatCurrency from "@Atoms/FormatCurrency";
import { Tag } from "primereact/tag";
import { getCompanyInfo } from "@Utils/get-login-user";

interface IViewAllProducts {
  type: string;
  toast: MutableRefObject<null>;
  barcode?: string;
  search?: ISearcher;
  refetchProducts?: boolean;
  onProductEditClick: ((data: IProduct) => void) | null;
}
const ViewAllProducts = (props: IViewAllProducts) => {
  const { baseUnit } = getCompanyInfo();
  const { type, search, toast, refetchProducts, onProductEditClick } = props;

  const {
    productRef,
    products,
    loading,
    isDeleteLoading,
    filters,
    activeId,
    setFilters,
    handleDeleteProduct,
    exportCSV,
    exportExcel,
  } = useProducts(
    { disableAPICall: false },
    toast,
    search,
    refetchProducts,
    type
  );

  const actionButtons = (rowData: IProduct) =>
    onProductEditClick && (
      <div style={{ display: "flex" }}>
        <Button
          size="small"
          type="button"
          label="Edit"
          onClick={() => onProductEditClick?.(rowData)}
          disabled={isDeleteLoading}
          style={{ marginRight: "5px" }}
        />
        <Button
          type="button"
          size="small"
          label={
            isDeleteLoading && activeId === rowData._id
              ? "Deleting..."
              : "Delete"
          }
          disabled={isDeleteLoading}
          severity="danger"
          onClick={() => handleDeleteProduct(rowData)}
        />
      </div>
    );

  return (
    <>
      <DataTable
        // scrollHeight="70vh"

        value={products as DataTableRowData<IProduct>}
        paginator
        scrollable
        ref={productRef}
        resizableColumns
        showGridlines
        size="normal"
        rows={50}
        rowsPerPageOptions={[5, 20, 50, 70, 100, 150, 200, 250, 500, 1000]}
        totalRecords={products.length}
        dataKey="_id"
        filters={filters as DataTableFilterMeta}
        header={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TableSearch filters={filters} setFilters={setFilters} />
            <ButtonWrapper>
              <Button
                type="button"
                icon="pi pi-file"
                onClick={() => exportCSV(false)}
                rounded
                data-balloon-pos="up"
                aria-label="Export products as CSV"
              />
              &nbsp;
              <Button
                type="button"
                icon="pi pi-file-excel"
                severity="success"
                data-balloon-pos="up"
                aria-label="Export products as Excel"
                onClick={exportExcel}
                rounded
                data-pr-tooltip="XLS"
              />
            </ButtonWrapper>
          </div>
        }
        loading={loading}
        emptyMessage="No product found.">
        <Column
          body={(_, { rowIndex }) => rowIndex + 1}
          field="id"
          header="S/N"
          footer="S/N"
          frozen
          style={{ minWidth: "10%" }}
        />
        <Column
          field="name"
          header="Name"
          footer="Name"
          frozen
          sortable
          bodyStyle={{ minWidth: "200px" }}
        />
        <Column
          field="purchasePrice"
          header="Purchase Price"
          footer="Purchase Price"
          body={(row) => (
            <b>
              <FormatCurrency inputValue={row.purchasePrice} />
            </b>
          )}
          bodyStyle={{ minWidth: "250px" }}
        />
        <Column
          field="salesPrice"
          header="Sales Price"
          footer="Sales Price"
          body={(row) => (
            <div>
              <div>
                <span style={{ fontSize: "12px" }}>per ({baseUnit}):</span>
                <br />
                <b>
                  <FormatCurrency inputValue={row.salesPrice} />
                </b>
              </div>
              {!!row.unitPrices.length && (
                <div style={{ fontSize: "12px" }}>
                  <hr />
                  <div>
                    {row.unitPrices.map((price, i) => (
                      <div key={price.id}>
                        Price {i + 1}:
                        <div>
                          <b style={{ color: "green" }}>
                            <FormatCurrency inputValue={price.price} />
                          </b>{" "}
                          per <b>{price?.name}</b>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          bodyStyle={{ minWidth: "150px" }}
        />

        <Column
          field="quantityInStock"
          header={`Avail. quantity (${baseUnit})`}
          footer={`Avail. quantity (${baseUnit})`}
          body={(row) => (
            <Tag
              severity={
                row.quantityInStock <= row.reorderPoint ? "danger" : "success"
              }>
              {row.quantityInStock} {baseUnit}
            </Tag>
          )}
          bodyStyle={{ minWidth: "100px" }}
        />
        <Column
          field="barcode"
          header="Barcode"
          footer="Barcode"
          bodyStyle={{ minWidth: "160px" }}
        />
        <Column
          field="category.name"
          header="Category"
          footer="Category"
          bodyStyle={{ minWidth: "150px" }}
        />
        <Column
          field="supplier.name"
          header="Supplier"
          footer="Supplier"
          bodyStyle={{ minWidth: "150px" }}
        />
        <Column
          body={(row) => (
            <div>
              <b>
                <FormatCurrency inputValue={row.minimumSalePrice} />
              </b>
            </div>
          )}
          header="Min. Sales price"
          footer="Min. Sales price"
          bodyStyle={{ minWidth: "150px" }}
        />
        <Column
          field="reorderPoint"
          header="Re-order point"
          footer="Re-order"
          bodyStyle={{ minWidth: "120px" }}
        />
        <Column
          field="manufactureDate"
          header="Manufacture date"
          footer="Manufacture date"
          body={(row) => (
            <>
              {row.manufactureDate &&
                format(new Date(row.manufactureDate), "dd/MM/yyyy")}
            </>
          )}
          bodyStyle={{ minWidth: "150px" }}
        />
        <Column
          field="expiryDate"
          header="Expiry date"
          Footer="Expiry date"
          body={(row) => (
            <>
              {row.expiryDate && format(new Date(row.expiryDate), "dd/MM/yyyy")}
            </>
          )}
          bodyStyle={{ minWidth: "150px" }}
        />
        <Column
          field="brandName"
          header="Brand name"
          footer="Brand name"
          bodyStyle={{ minWidth: "160px" }}
        />
        <Column
          field="taxCommodity.name"
          header="Tax comm."
          footer="Tax comm."
          bodyStyle={{ minWidth: "150px" }}
        />
        <Column
          field="storeOrWarehouseId.name"
          header="Store/Warehouse"
          footer="Store/Warehouse"
          bodyStyle={{ minWidth: "160px" }}
        />
        <Column
          field="marginAmount"
          header="Margin amount"
          footer="Margin amount"
          body={(row) => (
            <b>
              <FormatCurrency inputValue={row.marginAmount} />
            </b>
          )}
          bodyStyle={{ minWidth: "140px" }}
        />
        <Column
          field="marginPercent"
          header="Margin percent"
          footer="Margin percent"
          body={(rowData) => <>{rowData.marginPercent?.toFixed(2)}%</>}
          bodyStyle={{ minWidth: "140px" }}
        />
        <Column
          field="description"
          header="Description"
          footer="Description"
          bodyStyle={{ minWidth: "300px" }}
        />
        <Column
          field="color"
          header="Color"
          footer="Color"
          bodyStyle={{ minWidth: "140px" }}
        />
        <Column field="size" header="Size" bodyStyle={{ minWidth: "140px" }} />
        <Column
          field="weight"
          header="Weight"
          footer="Weight"
          bodyStyle={{ minWidth: "140px" }}
        />
        {onProductEditClick && (
          <Column
            header="Action"
            footer="Action"
            body={actionButtons}
            bodyStyle={{ textAlign: "center", minWidth: "200px" }}
          />
        )}
      </DataTable>
    </>
  );
};
export default ViewAllProducts;
