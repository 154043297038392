import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { eyeOpenLabel } from "../../text";

const EyeOpen = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    aria-label={eyeOpenLabel}
    width={size}
    height={size}
    aria-hidden="true"
    role="img"
    viewBox="6 6 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9205 12.1314C16.6159 9.38311 14.6437 8 12 8C9.35489 8 7.38413 9.38311 6.07947 12.1328C6.02714 12.2436 6 12.3646 6 12.4872C6 12.6097 6.02714 12.7308 6.07947 12.8416C7.38413 15.5899 9.35627 16.973 12 16.973C14.6451 16.973 16.6159 15.5899 17.9205 12.8402C18.0265 12.6172 18.0265 12.3585 17.9205 12.1314V12.1314ZM12 15.9821C9.78014 15.9821 8.15482 14.8564 7.00842 12.4865C8.15482 10.1166 9.78014 8.99088 12 8.99088C14.2198 8.99088 15.8452 10.1166 16.9916 12.4865C15.8466 14.8564 14.2212 15.9821 12 15.9821ZM11.9449 10.0643C10.6073 10.0643 9.52279 11.1488 9.52279 12.4865C9.52279 13.8242 10.6073 14.9087 11.9449 14.9087C13.2826 14.9087 14.3671 13.8242 14.3671 12.4865C14.3671 11.1488 13.2826 10.0643 11.9449 10.0643ZM11.9449 14.0279C11.0931 14.0279 10.4036 13.3384 10.4036 12.4865C10.4036 11.6346 11.0931 10.9451 11.9449 10.9451C12.7968 10.9451 13.4863 11.6346 13.4863 12.4865C13.4863 13.3384 12.7968 14.0279 11.9449 14.0279Z"
      fill={colour}
    />
  </svg>
);

export default EyeOpen;
