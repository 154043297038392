import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const DbIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    width={size}
    height={size}
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>db-copy</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="64px-Glyph"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g id="db-copy" fill="#fff">
        <path
          d="M42.8809,12.6572 C40.9209,7.3982 36.6489,4.0002 31.9999,4.0002 C25.3829,4.0002 19.9999,10.7282 19.9999,19.0002 L19.9999,20.5862 L16.7069,17.2932 C16.3169,16.9022 15.6839,16.9022 15.2929,17.2932 C14.9019,17.6832 14.9019,18.3162 15.2929,18.7072 L20.2919,23.7052 C20.3839,23.7982 20.4949,23.8722 20.6179,23.9232 C20.7399,23.9732 20.8699,24.0002 20.9999,24.0002 C21.1299,24.0002 21.2599,23.9732 21.3819,23.9232 C21.5049,23.8722 21.6159,23.7982 21.7079,23.7052 L26.7069,18.7072 C27.0979,18.3162 27.0979,17.6832 26.7069,17.2932 C26.3169,16.9022 25.6839,16.9022 25.2929,17.2932 L21.9999,20.5862 L21.9999,19.0002 C21.9999,11.8322 26.4859,6.0002 31.9999,6.0002 C35.8059,6.0002 39.3419,8.8872 41.0079,13.3562 C41.1999,13.8742 41.7769,14.1362 42.2939,13.9432 C42.8119,13.7512 43.0739,13.1752 42.8809,12.6572"
          id="Fill-433"
        />
        <path
          d="M20,36 C12.28,36 8.125,34.888 6,33.418 L6,38 C6,41.888 18.567,42 20,42 C21.433,42 34,41.888 34,38 L34,33.418 C31.875,34.888 27.72,36 20,36"
          id="Fill-434"
        />
        <path
          d="M20,50 C21.433,50 34,49.888 34,46 L34,41.418 C31.875,42.888 27.72,44 20,44 C12.28,44 8.125,42.888 6,41.418 L6,46 C6,49.888 18.567,50 20,50"
          id="Fill-435"
        />
        <path
          d="M20,52 C12.28,52 8.125,50.888 6,49.418 L6,54 C6,57.888 18.567,58 20,58 C21.433,58 34,57.888 34,54 L34,49.418 C31.875,50.888 27.72,52 20,52"
          id="Fill-436"
        />
        <path
          d="M20,34 C21.433,34 34,33.888 34,30 C34,26.112 21.433,26 20,26 C18.567,26 6,26.112 6,30 C6,33.888 18.567,34 20,34"
          id="Fill-437"
        />
        <path
          d="M44,24 C45.433,24 58,23.888 58,20 C58,16.112 45.433,16 44,16 C42.567,16 30,16.112 30,20 C30,23.888 42.567,24 44,24"
          id="Fill-438"
        />
        <path
          d="M44,42 C40.731,42 38.107,41.799 36,41.457 L36,47.382 C39.452,47.968 43.25,48 44,48 C45.433,48 58,47.888 58,44 L58,39.418 C55.875,40.888 51.72,42 44,42"
          id="Fill-439"
        />
        <path
          d="M44,34 C40.731,34 38.107,33.799 36,33.457 L36,39.382 C39.452,39.968 43.25,40 44,40 C45.433,40 58,39.888 58,36 L58,31.418 C55.875,32.888 51.72,34 44,34"
          id="Fill-440"
        />
        <path
          d="M44,26 C36.28,26 32.125,24.888 30,23.418 L30,24.943 C34.758,26.112 36,28.172 36,30 L36,31.382 C39.452,31.968 43.25,32 44,32 C45.433,32 58,31.888 58,28 L58,23.418 C55.875,24.888 51.72,26 44,26"
          id="Fill-441"
        />
      </g>
    </g>
  </svg>
);

export default DbIcon;
