import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { cursorValuesIconLabel } from "../../text";

const CursorValues = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={cursorValuesIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16">
    <g strokeWidth="1" stroke={colour}>
      <polygon
        points="0.5 0.5 9.5 3.5 4.5 4.5 3.5 9.5 0.5 0.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
        data-color="color-2"
      />
      <rect
        x="6.5"
        y="6.5"
        width="9"
        height="9"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
      />
      <line
        x1="6.5"
        y1="9.5"
        x2="15.5"
        y2="9.5"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
      />
      <line
        x1="6.5"
        y1="12.5"
        x2="15.5"
        y2="12.5"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
      />
    </g>
  </svg>
);

export default CursorValues;
