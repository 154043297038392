import Text from "@Core/Text";
import {
  ButtonWrapper,
  FieldsWrapper,
  FieldWrapper,
  CreateForm,
} from "@Molecules/CreateCounterCustomer/styles";

import { ICustomer } from "@Pages/Customer/Customer";
import { useRef, useState } from "react";
import { FieldPath, SubmitHandler, useForm } from "react-hook-form";
import { ToastLife } from "@Types/global.types";
import { Toast } from "primereact/toast";
import {
  DisplayErrorMessage,
  InputTextareaContentWrapper,
  InputTextareaWrapper,
  InputWrapper,
} from "@Organisms/CustomerFormModal/styles";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { postRequest } from "../../../axios/requests";

export interface ICreateCounterCustomer {
  onCreated: (data: ICustomer) => void;
  onCancel: () => void;
}

const initialValues = {
  customerId: "",
  name: "",
  photo: "",
  address: "",
  city: "",
  state: "",
  phoneNumber: "",
  email: "",
  remark: "",
  vat: "",
};

interface ICreateCustomer {
  customerId: string;
  name: string;
  photo: string;
  address: string;
  city: string;
  state: string;
  phoneNumber: string;
  email: string;
  remark: string;
  vat: string;
}
interface IFormOptions {
  defaultValues: ICreateCustomer;
}

const CreateCounterCustomer = (props: ICreateCounterCustomer) => {
  const { onCreated, onCancel } = props;

  const toast = useRef(null);
  const [isSaving, setIsSaving] = useState(false);

  const formOptions: IFormOptions = {
    defaultValues: initialValues,
  };

  const handleCreateCustomerReq = (body: ICreateCustomer) =>
    postRequest("/customers", body);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICreateCustomer>({ ...formOptions, mode: "onBlur" });

  const handleCreateCustomer = (data: ICreateCustomer) => {
    setIsSaving(true);
    handleCreateCustomerReq(data)
      .then((response) => {
        setIsSaving(false);
        onCreated?.(response.data);
        reset();
      })
      .catch((error) => {
        setIsSaving(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: ToastLife,
        });
      });
  };

  const handleCancel = () => {
    onCancel?.();
  };

  // eslint-disable-next-line consistent-return
  const onSubmitHandler: SubmitHandler<ICreateCustomer> = (data) => {
    if (!data.name || !data.phoneNumber) {
      return toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Customer information is required",
        life: ToastLife,
      });
    }
    handleCreateCustomer(data);
  };

  return (
    <div>
      <Text h2>Add new customer</Text>
      <CreateForm onSubmit={handleSubmit(onSubmitHandler)}>
        <FieldWrapper fullWidth>
          <Text noStack>Customer&apos;s name(*):</Text>
          <InputWrapper
            id="name"
            type="text"
            placeholder="Enter customer name"
            {...register("name" as FieldPath<ICreateCustomer>, {
              required: "Customer name is required!",
            })}
            aria-invalid={errors.name ? "true" : "false"}
            className={classNames(
              {
                "p-invalid": errors.name?.type === "required",
              },
              "p-inputtext-lg"
            )}
          />
          {errors.name && (
            <DisplayErrorMessage>
              <small className="p-error">{errors.name?.message}</small>
            </DisplayErrorMessage>
          )}
        </FieldWrapper>
        <FieldWrapper fullWidth>
          <Text noStack>Phone number(*):</Text>
          <InputWrapper
            id="phoneNumber"
            type="text"
            placeholder="Enter phone number"
            {...register("phoneNumber" as FieldPath<ICreateCustomer>, {
              required: "Phone number is required!",
            })}
            aria-invalid={errors.name ? "true" : "false"}
            className={classNames(
              {
                "p-invalid": errors.phoneNumber?.type === "required",
              },
              "p-inputtext-lg"
            )}
          />
          {errors.phoneNumber && (
            <DisplayErrorMessage>
              <small className="p-error">{errors.phoneNumber?.message}</small>
            </DisplayErrorMessage>
          )}
        </FieldWrapper>
        <FieldsWrapper>
          <InputTextareaContentWrapper>
            <Text bold noStack>
              Remarks:
            </Text>
            <InputTextareaWrapper
              id="remark"
              placeholder="Optional: Enter a remark about this customer"
              rows={4}
              cols={30}
              {...register("remark" as FieldPath<ICreateCustomer>)}
            />
          </InputTextareaContentWrapper>
        </FieldsWrapper>

        <ButtonWrapper>
          <Button
            onClick={handleCancel}
            severity="danger"
            label="Cancel"
            disabled={isSaving}
          />
          <Button
            type="submit"
            severity="success"
            label={isSaving ? "Saving...." : "Save Customer"}
            disabled={isSaving}
          />
        </ButtonWrapper>
      </CreateForm>
      <Toast ref={toast} />
    </div>
  );
};

export default CreateCounterCustomer;
