import { createGlobalStyle } from "styled-components";
import normalizeCSS from "./normalize-css";

const GlobalStyles = createGlobalStyle`
  ${normalizeCSS};
  
  html, body {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.fill1};
  }

  a {
    color: ${({ theme }) => theme.text1};
  }
  
  hr {
    border-top: 1px solid #474747;
  }

  #root {
    height: 100vh;
  }

  * {
    box-sizing: border-box;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track { 
      width: 8px;
      height: 8px;
      border: none;
      background: transparent;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #FFFF69;
  }
  
  :root {
    --balloon-color: ${({ theme }) => theme.fill1};
  }
  
  #userRoleDropdown .p-inputtext-lg .p-inputtext {
    padding: 10px 5px;
    font-size: unset;
  }
  
  #form-container .p-card .p-card-content {
    padding: 0;
    min-height: 400px;
  }
  
  .btn-1 {
    background: #0C0C0C;
    background: linear-gradient(90deg,#2D74FF 0%, #00ff00 80%);
    background: -webkit-linear-gradient(90deg,#2D74FF 0%, #00ff00 80%);
    background: -moz-linear-gradient(90deg,#2D74FF 0%, #00ff00 80%);
  }
  .btn-2 {
    background: #0C0C0C;
    background: radial-gradient(circle farthest-corner at center center, #ff0000 0%, #00ff00 80%);
    background: -webkit-radial-gradient(circle farthest-corner at center center, #ff0000 0%, #00ff00 80%);
    background: -moz-radial-gradient(circle farthest-corner at center center, #ff0000 0%, #00ff00 80%);
  }
  .btn-3 {
    background: #0C0C0C;
    background: radial-gradient(ellipse farthest-corner at center center, #2D74FF 0%, #00ff00 80%);
    background: -webkit-radial-gradient(ellipse farthest-corner at center center, #2D74FF 0%, #00ff00 80%);
    background: -moz-radial-gradient(ellipse farthest-corner at center center, #2D74FF 0%, #00ff00 80%);
  }
  .btn-4 {
    background: #0C0C0C;
    background: radial-gradient(ellipse farthest-corner at bottom left, #ff0000 0%, #00ff00 80%);
    background: -webkit-radial-gradient(ellipse farthest-corner at bottom left, #ff0000 0%, #00ff00 80%);
    background: -moz-radial-gradient(ellipse farthest-corner at bottom left, #ff0000 0%, #00ff00 80%);
  }
  .btn-5 {
    background: #0C0C0C;
    background: radial-gradient(circle farthest-corner at right center, #2D74FF 0%, #00ff00 20%);
    background: -webkit-radial-gradient(circle farthest-corner at right center, #2D74FF 0%, #00ff00 20%);
    background: -moz-radial-gradient(circle farthest-corner at right center, #2D74FF 0%, #00ff00 20%);
  }
  .btn-6 {
    background: #0C0C0C;
    background: linear-gradient(197deg,#ff0000 0%, #2D74FF 80%);
    background: -webkit-linear-gradient(197deg,#ff0000 0%, #2D74FF 80%);
    background: -moz-linear-gradient(197deg,#ff0000 0%, #2D74FF 80%);
  }
  .btn-7 {
    background: #0C0C0C;
    background: linear-gradient(315deg,#ff0000 0%, #00ff00 80%);
    background: -webkit-linear-gradient(315deg,#ff0000 0%, #00ff00 80%);
    background: -moz-linear-gradient(315deg,#ff0000 0%, #00ff00 80%);
  }
  
  .p-fieldset .p-fieldset-content {
    padding: 0;
  }
  
  #admin-avatar .p-splitbutton-defaultbutton {
    display: none;
  }
`;

export default GlobalStyles;
