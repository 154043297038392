import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const POPPrinting = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    width={size}
    height={size}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 462.731 462.731">
    <g>
      <path
        d="M376.365,130h-34.649V15c0-8.284-6.716-15-15-15h-190.7c-8.284,0-15,6.716-15,15v115h-34.65c-8.284,0-15,6.716-15,15
                    v116.199c0,8.284,6.716,15,15,15h1.69v171.533c0,8.284,6.716,15,15,15h256.62c8.284,0,15-6.716,15-15V276.198h1.689
                    c8.284,0,15-6.716,15-15V145C391.365,136.715,384.65,130,376.365,130z M311.716,130h-83.35V30h83.35V130z M208.366,30v100h-22V30
                    H208.366z M151.016,30h15.35v100h-15.35V30z M101.365,160h260v86.199h-260V160z M344.676,432.731h-226.62V276.198h226.62V432.731z"
      />
      <path
        d="M130.365,236.945h202c5.523,0,10-4.477,10-10v-47.693c0-5.523-4.477-10-10-10h-202c-5.523,0-10,4.477-10,10v47.693
                    C120.365,232.468,124.843,236.945,130.365,236.945z M224.815,216.945l27.693-27.693h24.716l-27.693,27.693H224.815z
                    M322.365,216.945h-44.55l27.693-27.693h16.857V216.945z M140.365,189.252h83.859l-27.693,27.693h-56.166V189.252z"
      />
      <path
        d="M146.366,314.464h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S140.843,314.464,146.366,314.464
                z"
      />
      <path
        d="M146.366,347.798h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S140.843,347.798,146.366,347.798
                z"
      />
      <path
        d="M146.366,381.131h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S140.843,381.131,146.366,381.131
                z"
      />
      <path
        d="M176.366,394.464h-30c-5.523,0-10,4.477-10,10s4.477,10,10,10h30c5.523,0,10-4.477,10-10S181.889,394.464,176.366,394.464z
                "
      />
      <path
        d="M216.366,314.464h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S210.843,314.464,216.366,314.464
                z"
      />
      <path
        d="M216.366,347.798h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S210.843,347.798,216.366,347.798
                    z"
      />
      <path
        d="M216.366,381.131h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S210.843,381.131,216.366,381.131
                    z"
      />
      <path
        d="M216.366,414.464h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S210.843,414.464,216.366,414.464
                    z"
      />
      <path
        d="M286.366,314.464h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S280.843,314.464,286.366,314.464
                    z"
      />
      <path
        d="M286.366,347.798h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S280.843,347.798,286.366,347.798
                    z"
      />
      <path
        d="M286.366,381.131h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S280.843,381.131,286.366,381.131
                    z"
      />
      <path
        d="M286.366,414.464h30c5.523,0,10-4.477,10-10s-4.477-10-10-10h-30c-5.523,0-10,4.477-10,10S280.843,414.464,286.366,414.464
                    z"
      />
    </g>
  </svg>
);

export default POPPrinting;
