import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { dragHandleLabel } from "../../text";

const DragHandle = ({ colour, size }: IconSvgProps): ReactElement => (
  <svg
    aria-label={dragHandleLabel}
    width={size}
    height={size}
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8928 25.5C13.7212 25.5 14.3928 24.8284 14.3928 24C14.3928 23.1716 13.7212 22.5 12.8928 22.5C12.0644 22.5 11.3928 23.1716 11.3928 24C11.3928 24.8284 12.0644 25.5 12.8928 25.5Z"
      fill={colour}
    />
    <path
      d="M18.8928 25.5C19.7212 25.5 20.3928 24.8284 20.3928 24C20.3928 23.1716 19.7212 22.5 18.8928 22.5C18.0644 22.5 17.3928 23.1716 17.3928 24C17.3928 24.8284 18.0644 25.5 18.8928 25.5Z"
      fill={colour}
    />
    <path
      d="M18.8928 19.5C19.7212 19.5 20.3928 18.8284 20.3928 18C20.3928 17.1716 19.7212 16.5 18.8928 16.5C18.0644 16.5 17.3928 17.1716 17.3928 18C17.3928 18.8284 18.0644 19.5 18.8928 19.5Z"
      fill={colour}
    />
    <path
      d="M24.8928 13.5C25.7212 13.5 26.3928 12.8284 26.3928 12C26.3928 11.1716 25.7212 10.5 24.8928 10.5C24.0644 10.5 23.3928 11.1716 23.3928 12C23.3928 12.8284 24.0644 13.5 24.8928 13.5Z"
      fill={colour}
    />
    <path
      d="M24.8928 25.5C25.7212 25.5 26.3928 24.8284 26.3928 24C26.3928 23.1716 25.7212 22.5 24.8928 22.5C24.0644 22.5 23.3928 23.1716 23.3928 24C23.3928 24.8284 24.0644 25.5 24.8928 25.5Z"
      fill={colour}
    />
    <path
      d="M24.8928 19.5C25.7212 19.5 26.3928 18.8284 26.3928 18C26.3928 17.1716 25.7212 16.5 24.8928 16.5C24.0644 16.5 23.3928 17.1716 23.3928 18C23.3928 18.8284 24.0644 19.5 24.8928 19.5Z"
      fill={colour}
    />
  </svg>
);

export default DragHandle;
