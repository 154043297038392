import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const Payment = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    width={size}
    height={size}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 490.3 490.3">
    <g>
      <path
        d="M199.821,96.9c-10.2,0-18.4-8.3-18.4-18.4s8.3-18.4,18.4-18.4c10.2,0,18.4,8.3,18.4,18.4S210.021,96.9,199.821,96.9z
		 M199.821,108.7c36.7,0,66.5,29.8,66.5,66.5c0,20.3-9.2,38.5-23.5,50.7c-6.4-3.1-13.6-4.9-21.6-4.9c-1.7,0-3.5,0.1-4.9,0.2
		c-3.9,0.3-17.3,2.5-27.2,16.9c-0.5,0.8-1,1.6-1.5,2.4c-30.9-5.7-54.3-32.8-54.3-65.3C133.321,138.5,163.121,108.7,199.821,108.7z
		 M243.121,199.2h-13.1c-2.7,8.7-6.6,15.9-10.6,21.5C229.521,216.4,237.821,208.8,243.121,199.2z M232.421,161.1
		c0.7,4.4,1.2,9,1.2,14.1s-0.4,9.7-1.2,14.1h14.9c1.3-4.5,2-9.2,2-14.1s-0.7-9.6-2-14.1H232.421z M219.421,129.7
		c4.1,5.6,8,12.8,10.6,21.5h13.1C237.821,141.7,229.521,134.1,219.421,129.7z M204.821,151.2h14.7c-3.9-10.8-9.9-18.5-14.7-23.4
		V151.2z M204.821,189.3h17.5c0.8-4.3,1.3-9,1.3-14.1s-0.5-9.7-1.3-14.1h-17.5V189.3z M204.821,222.7c4.8-4.8,10.9-12.5,14.8-23.5
		h-14.8V222.7z M167.221,189.3c-0.7-4.4-1.2-9-1.2-14.1s0.4-9.7,1.2-14.1h-14.9c-1.3,4.5-2,9.2-2,14.1s0.7,9.6,2,14.1H167.221z
		 M180.221,220.7c-4-5.6-8-12.8-10.6-21.5h-13.1C161.721,208.8,170.121,216.4,180.221,220.7z M194.821,199.2h-14.7
		c3.9,10.8,9.9,18.5,14.7,23.4V199.2z M194.821,161.1h-17.5c-0.8,4.3-1.3,9-1.3,14.1s0.5,9.7,1.3,14.1h17.5V161.1z M194.821,127.7
		c-4.8,4.8-10.9,12.5-14.8,23.5h14.8V127.7z M156.421,151.2h13.1c2.7-8.7,6.6-15.9,10.6-21.5
		C170.121,134.1,161.721,141.7,156.421,151.2z M386.721,273c-5.5-4.3-63-68.1-78.4-77.1V30.4c0-16.8-13.6-30.4-30.4-30.4h-156.2
		c-16.8,0-30.4,13.6-30.4,30.4v289.7c0,16.8,13.6,30.4,30.4,30.4h50.1h17.9l-3-25.6c-0.1-0.1-0.2-0.2-0.4-0.3h-11.8h-20
		c0.9-3.7,1.2-7.6,0.7-11.6c-1.6-12.6-11.2-23.1-23.5-25.9c-5.1-1.2-9.9-1-14.4,0.1V61.5c5.1,1,10.7,0.7,16.4-1.5
		c6.1-2.3,11.2-6.7,14.8-12.1c5.1-7.8,5.9-15.3,4.7-22.1h93.3c-1.2,6.7-0.4,14,4.5,21.8c3.5,5.6,8.7,10,14.8,12.3
		c5.8,2.2,11.4,2.5,16.6,1.5v220.1l0,0v63.2c-10.6-7.8-22.5-32.2-22.5-32.2s-5.8-65-38.7-65c-0.9,0-1.7,0-2.6,0.1
		c0,0-24.9,2.1-4.3,73.4l9.2,79.1c0,0,10.3,43,38.7,72.7v8.9c0,4.7,3.8,8.6,8.6,8.6h92.6c4.4,0,8-3.3,8.5-7.6
		c3.3-30.5,15.9-142.9,23.4-162.2c1.4-3.7,2.7-7.4,3.2-11.3C399.721,299.2,399.421,282.8,386.721,273z"
      />
    </g>
  </svg>
);

export default Payment;
