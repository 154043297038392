import { FC, useEffect } from "react";

import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet-async";
import Favicon from "@Assets/images/favicon.png";
import GlobalStyles from "./GlobalStyles";
import Router from "./Router";
import ErrorBoundary from "./ErrorBoundary";
import themes from "./themes";

const cssRule =
  "color: rgb(249, 162, 34);" +
  "font-size: 17px;" +
  "text-align: center" +
  "font-weight: bold;" +
  "text-shadow: 1px 1px 5px rgb(249, 162, 34);" +
  "filter: dropshadow(color=rgb(249, 162, 34), offx=1, offy=1);";
const App: FC = () => {
  useEffect(() => {
    setTimeout(
      // eslint-disable-next-line no-console
      console.info.bind(
        console,
        `%cPUSH-IT: POS-CHOICE-${process.env.NODE_ENV}-${process.env.APP_NAME}`,
        cssRule
      ),
      0
    );
  }, []);

  return (
    <>
      <Helmet>
        <link
          id="favicon"
          rel="shortcut icon"
          href={Favicon}
          sizes="any"
          type="image/svg+xml"
        />
      </Helmet>
      <ErrorBoundary>
        <ThemeProvider theme={themes.dark}>
          <GlobalStyles />
          <Router />
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
