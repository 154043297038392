export const getItems = (key: string) => JSON.parse(localStorage.getItem(key));

export const deleteItem = (key: string) => localStorage.removeItem(key);

export const setItem = (key: string, value: string) =>
  localStorage.setItem(key, value);

export const getSessions = (key: string) =>
  JSON.parse(sessionStorage.getItem(key));

export const setSession = (key: string, value: string) =>
  sessionStorage.setItem(key, value);

export const deleteSession = (key: string) => sessionStorage.removeItem(key);
