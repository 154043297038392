import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const POSWithCardIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    width={size}
    height={size}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 481.1 481.1">
    <g>
      <g>
        <path
          d="M445.2,77.4H326V44.9c0-19.8-16.1-35.8-35.9-35.8H35.8C16,9.1,0,25.2,0,44.9v391.3C0,456,16.1,472,35.8,472h254.3
                    c19.8,0,35.9-16.1,35.9-35.8v-48.7h119.2c19.8,0,35.9-16.1,35.9-35.8V113.3C481,93.5,464.9,77.4,445.2,77.4z M409.9,101.4v262.1
                    h-23.1V101.4H409.9z M302,436.1c0,6.5-5.3,11.8-11.9,11.8H35.8c-6.5,0-11.8-5.3-11.8-11.8V44.9c0-6.5,5.3-11.8,11.8-11.8h254.3
                    c6.5,0,11.9,5.3,11.9,11.8V436.1z M326,101.4h36.9v262.1H326V101.4z M457,351.6c0,6.5-5.3,11.8-11.9,11.8h-11.2v-262h11.2
                    c6.5,0,11.9,5.3,11.9,11.8V351.6z"
        />
        <path
          d="M94.2,223.7h137.5c16.9,0,30.6-13.7,30.6-30.6v-89.9c0-16.9-13.7-30.6-30.6-30.6H94.2c-16.9,0-30.6,13.7-30.6,30.6v89.9
                    C63.6,210,77.3,223.7,94.2,223.7z M87.6,103.2c0-3.7,3-6.6,6.6-6.6h137.5c3.7,0,6.6,3,6.6,6.6v89.9c0,3.7-3,6.6-6.6,6.6H94.2
                    c-3.7,0-6.6-3-6.6-6.6V103.2z"
        />
        <path d="M91.5,273.5H75.6c-6.6,0-12,5.4-12,12s5.4,12,12,12h15.9c6.6,0,12-5.4,12-12S98.1,273.5,91.5,273.5z" />
        <path d="M170.9,273.5H155c-6.6,0-12,5.4-12,12s5.4,12,12,12h15.9c6.6,0,12-5.4,12-12S177.5,273.5,170.9,273.5z" />
        <path d="M250.4,273.5h-15.9c-6.6,0-12,5.4-12,12s5.4,12,12,12h15.9c6.6,0,12-5.4,12-12S257,273.5,250.4,273.5z" />
        <path d="M91.5,327.7H75.6c-6.6,0-12,5.4-12,12s5.4,12,12,12h15.9c6.6,0,12-5.4,12-12S98.1,327.7,91.5,327.7z" />
        <path d="M170.9,327.7H155c-6.6,0-12,5.4-12,12s5.4,12,12,12h15.9c6.6,0,12-5.4,12-12S177.5,327.7,170.9,327.7z" />
        <path d="M250.4,327.7h-15.9c-6.6,0-12,5.4-12,12s5.4,12,12,12h15.9c6.6,0,12-5.4,12-12S257,327.7,250.4,327.7z" />
        <path d="M91.5,381.9H75.6c-6.6,0-12,5.4-12,12s5.4,12,12,12h15.9c6.6,0,12-5.4,12-12S98.1,381.9,91.5,381.9z" />
        <path d="M170.9,381.9H155c-6.6,0-12,5.4-12,12s5.4,12,12,12h15.9c6.6,0,12-5.4,12-12S177.5,381.9,170.9,381.9z" />
        <path d="M250.4,381.9h-15.9c-6.6,0-12,5.4-12,12s5.4,12,12,12h15.9c6.6,0,12-5.4,12-12S257,381.9,250.4,381.9z" />
      </g>
    </g>
  </svg>
);

export default POSWithCardIcon;
