import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { questionMarkLabel } from "../../text";

const QuestionMark = ({
  colour,
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    aria-label={questionMarkLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 0.269531 8 C 0.269531 12.269531 3.730469 15.730469 8 15.730469 C 12.269531 15.730469 15.730469 12.269531 15.730469 8 C 15.730469 3.730469 12.269531 0.269531 8 0.269531 C 3.730469 0.269531 0.269531 3.730469 0.269531 8 Z M 14.324219 8 C 14.324219 11.492188 11.492188 14.324219 8 14.324219 C 4.507812 14.324219 1.675781 11.492188 1.675781 8 C 1.675781 4.507812 4.507812 1.675781 8 1.675781 C 11.492188 1.675781 14.324219 4.507812 14.324219 8 Z M 8 11.511719 C 8.386719 11.511719 8.703125 11.199219 8.703125 10.808594 C 8.703125 10.421875 8.386719 10.105469 8 10.105469 C 7.613281 10.105469 7.296875 10.421875 7.296875 10.808594 C 7.296875 11.199219 7.613281 11.511719 8 11.511719 Z M 7.296875 9.40625 L 8.703125 9.40625 C 8.703125 8.84375 8.789062 8.742188 9.367188 8.453125 C 10.371094 7.949219 10.8125 7.4375 10.8125 6.242188 C 10.8125 4.710938 9.605469 3.785156 8 3.785156 C 6.449219 3.785156 5.1875 5.042969 5.1875 6.59375 L 6.59375 6.59375 C 6.59375 5.820312 7.222656 5.1875 8 5.1875 C 8.898438 5.1875 9.40625 5.582031 9.40625 6.242188 C 9.40625 6.804688 9.316406 6.90625 8.738281 7.195312 C 7.734375 7.699219 7.296875 8.210938 7.296875 9.40625 Z M 7.296875 9.40625 "
        fill={colour}
      />
    </g>
  </svg>
);

export default QuestionMark;
