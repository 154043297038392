import { Dialog } from "primereact/dialog";
import { ReactElement, ReactNode, useEffect, useState } from "react";

interface IModalProps {
  isOpen: boolean;
  onCancel?: () => void;
  children: ReactNode;
  isMaximizable?: boolean;
  isMaximized?: boolean;
  title?: () => JSX.Element | string | ReactElement;
  width?: string;
  showHeader?: boolean;
  closeOnEsc?: boolean;
  position:
    | "center"
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | undefined;
}
const Modal = (props: IModalProps) => {
  const {
    isOpen,
    onCancel,
    children,
    position,
    isMaximizable = false,
    isMaximized = false,
    showHeader = true,
    closeOnEsc = true,
    title,
    width = "50vw",
  } = props;

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);

  const handleCloseModal = () => {
    setVisible(false);
    onCancel?.();
  };

  return (
    <Dialog
      header={title}
      visible={visible}
      position={position}
      maximizable={isMaximizable}
      style={{ width }}
      blockScroll
      keepInViewport
      maximized={isMaximized}
      showHeader={showHeader}
      closeOnEscape={closeOnEsc}
      onHide={handleCloseModal}>
      {children}
    </Dialog>
  );
};

export default Modal;
