import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { annotateIconLabel } from "../../text";

const AnnotateIcon = ({
  colour,
  size = IconSizes.MD,
}: IconSvgProps): ReactElement => (
  <svg
    aria-label={annotateIconLabel}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 16 16">
    <path
      d="M2.76285 15.2395C2.49787 15.2395 2.26746 15.1416 2.07161 14.9457C1.87576 14.7499 1.77783 14.5195 1.77783 14.2545V3.95504C1.77783 3.69007 1.87576 3.45678 2.07161 3.25516C2.26746 3.05355 2.49787 2.95275 2.76285 2.95275H9.74435L8.74205 3.95504H2.76285V14.2545H13.0623V8.20616L14.0646 7.22114V14.2545C14.0646 14.5195 13.9638 14.7499 13.7622 14.9457C13.5606 15.1416 13.3273 15.2395 13.0623 15.2395H2.76285ZM11.0923 3.00459L11.8181 3.71311L6.85843 8.63818V10.1589H8.36187L13.3215 5.18199L14.03 5.89051L8.77662 11.1439H5.87342V8.24072L11.0923 3.00459ZM14.03 5.89051L11.0923 3.00459L12.8204 1.2765C13.0162 1.09217 13.2524 1 13.5289 1C13.8054 1 14.0415 1.09793 14.2374 1.29378L15.7063 2.79722C15.9021 3.00459 16.0001 3.24364 16.0001 3.51438C16.0001 3.78511 15.8964 4.01265 15.689 4.19698L14.03 5.89051Z"
      fill={colour}
    />
  </svg>
);

export default AnnotateIcon;
