import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { crosshairIconLabel } from "../../text";

const Crosshair = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={crosshairIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16">
    <g strokeWidth="1" stroke={colour}>
      <circle
        cx="8.5"
        cy="8.5"
        r="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
        data-color="color-2"
      />
      <line
        x1="8.5"
        y1="1.5"
        x2="8.5"
        y2="3.5"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
      />
      <line
        x1="1.5"
        y1="8.5"
        x2="3.5"
        y2="8.5"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
      />
      <line
        x1="13.5"
        y1="8.5"
        x2="15.5"
        y2="8.5"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
      />
      <line
        x1="8.5"
        y1="13.5"
        x2="8.5"
        y2="15.5"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
      />
    </g>
  </svg>
);

export default Crosshair;
