import { MouseEvent, FC, ReactNode } from "react";

import { Button as StyledButton, CancelButton } from "./styles";

export interface IButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  name?: string;
  label?: string;
  type?: "submit" | "reset" | "button";
  variant?: "text" | "outlined" | "contained" | "spaced";
  cancel?: boolean;
  disabled?: boolean;
  flex?: boolean;
  checked?: boolean;
  children?: ReactNode;
}

const Button: FC<IButtonProps> = ({
  children,
  onClick = () => {},
  label,
  name = "",
  type = "button",
  cancel = false,
  disabled = false,
  variant = "contained",
  flex = false,
  checked = false,
  ...otherProps
}) => {
  const ButtonType = cancel || disabled ? CancelButton : StyledButton;

  return (
    <ButtonType
      {...(name ? { name } : {})}
      aria-label={label}
      onClick={onClick}
      type={type}
      flex={flex}
      disabled={disabled}
      checked={checked}
      variant={variant}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}>
      {children}
    </ButtonType>
  );
};

export default Button;
