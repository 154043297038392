import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { chevronIconLabel } from "../../text";

const ChevronUp = ({
  colour,
  size = IconSizes.SM,
  className,
}: IconSvgProps): ReactElement => (
  <svg
    aria-label={chevronIconLabel}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}>
    <path
      fill={colour}
      d="M19 15.0054L12.0053 8L5 14.9947L6.24156 16.2381L12.0035 10.485L17.7565 16.2469L19 15.0054Z"
    />
  </svg>
);

export default ChevronUp;
