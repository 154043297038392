import { FC } from "react";
import { IconSvgProps } from "@Types/global.types";

export const Padlock: FC<IconSvgProps> = ({ colour = "black", size = 16 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}>
    <g>
      <g>
        ok
        <path
          fill={colour}
          d="m409.6,197.3h-18v-50.6c0-74.8-60.8-135.7-135.6-135.7s-135.6,60.9-135.6,135.6v50.6h-17.9c-17.7,0-32.1,14.4-32.1,32.1v239.6c0,17.7 14.4,32.1 32.1,32.1h307.2c17.7,0 32.1-14.4 32.1-32.1v-239.6c-0.1-17.6-14.5-32-32.2-32zm-248.4-50.7c0-52.3 42.5-94.8 94.8-94.8 52.3,0 94.8,42.5 94.8,94.8v50.5h-189.6v-50.5zm239.6,313.6h-289.6v-222.1h289.7v222.1z"
        />
        <path
          fill={colour}
          d="m256,429.7c11.3,0 20.4-9.1 20.4-20.4v-52.6c0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4v52.6c2.84217e-14,11.2 9.1,20.4 20.4,20.4z"
        />
      </g>
    </g>
  </svg>
);
