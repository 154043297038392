import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const WalletIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    fill={colour}
    width={size}
    height={size}
    viewBox="0 0 512 512">
    <g>
      <path
        d="M314.344,321.563c0.031-36.594,29.656-66.234,66.234-66.25H490v-78.719c0-32.781-25.25-59.656-57.375-62.297
                    v-0.25H78.031c-9.375,0-17-7.609-17-16.984c0-9.406,7.625-17,17-17h354.563c-0.484-34.141-28.281-61.688-62.531-61.688H100.094
                    c-53.781,0-97.531,42.453-99.859,95.672H0v4.422v72.813v202.25c0,55.281,44.813,100.094,100.094,100.094h327.359
                    C462,493.625,490,465.609,490,431.063v-43.281H380.578C344,387.781,314.375,358.125,314.344,321.563z"
      />
      <path
        d="M502.469,280.563H380.578c-11.359,0-21.531,4.563-28.984,12c-7.438,7.453-12.016,17.625-12.016,29
                    c0,11.344,4.578,21.531,12.016,28.984c7.453,7.453,17.625,12,28.984,12.016h121.891c5.266,0,9.531-4.281,9.531-9.547v-62.922
                    C512,284.813,507.734,280.563,502.469,280.563z M381.281,335.219c-7.547,0-13.656-6.125-13.656-13.656
                    c0-7.563,6.109-13.688,13.656-13.688s13.672,6.125,13.672,13.688C394.953,329.094,388.828,335.219,381.281,335.219z"
      />
    </g>
  </svg>
);

export default WalletIcon;
