import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const SalesmanIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    width={size}
    height={size}
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-351 153 256 256">
    <path
      id="group"
      d="M-288.1,242.8h22.5v17.7h-22.5V242.8z M-213.4,229.1h94.3v11.4h-94.3V229.1z M-213.4,279.1h94.3v11.4h-94.3
                V279.1z M-154.8,226.4c7.3,0,13.2-6,13.2-13.5c0-7.4-5.9-13.5-13.2-13.5s-13.2,6-13.2,13.5C-168,220.3-162.1,226.4-154.8,226.4z
                M-178.3,225.6c4.5,0,8.1-3.7,8.1-8.2c0-4.6-3.6-8.2-8.1-8.2c-4.4,0-8.1,3.7-8.1,8.2C-186.4,221.9-182.7,225.6-178.3,225.6z
                M-194.8,226.3c2.9,0,5.3-2.4,5.3-5.4s-2.4-5.4-5.3-5.4c-2.9,0-5.3,2.4-5.3,5.4C-200,223.9-197.7,226.3-194.8,226.3z M-174.8,257.4
                h33.2v17.4h-33.2V257.4z M-194.6,257.4h10.3v17.4h-10.3V257.4z M-276.4,198.1c11.3,0,20.4,9.1,20.4,20.4s-9.1,20.4-20.4,20.4
                c-11.3,0-20.4-9.1-20.4-20.4S-287.7,198.1-276.4,198.1z M-307.2,273.4c0-1.5,1.2-2.5,2.5-2.5c1.5,0,2.5,1,2.5,2.5v29.4h10.1v-42.2
                v-17.8h-7.9c-14.2,0-23.1,11.7-23.1,26.3v33.6h15.9V273.4z M-261.4,260.5v42.2h10.4v-29.4c0-1.5,1.2-2.5,2.5-2.5
                c1.5,0,2.5,1.2,2.5,2.5v29.4h15.9v-33.3c0.2-14.9-8.7-26.6-23.1-26.6h-8.1v17.7H-261.4z M-114,161.3v145h-216.8v-145h-16.8v244.5
                h250.4v-99.5v-145H-114z"
    />
  </svg>
);

export default SalesmanIcon;
