import styled from "styled-components";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ListBox } from "primereact/listbox";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
// import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

export const InputTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 600px;
`;

export const Input = styled.input`
  height: 35px;
  outline: none;
  border: 1px solid blue;
  padding: 5px 10px;
`;

export const TextArea = styled.textarea`
  outline: none;
  border: 1px solid blue;
  padding: 5px 10px;
`;

export const Button = styled.button`
  border: none;
  background-color: blue;
  font-size: 16px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  padding: 15px 0px;
`;

export const CreateProductContentWrapper = styled.div<{
  showProductForm: boolean;
}>``;

export const SalesMasterInfoForm = styled.form`
  width: 100%;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  padding: 3px 0;
  justify-content: space-between;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: block;
  }
`;

export const CheckedboxFieldWrapper = styled.div`
  display: flex;
  padding: 3px 0;
  gap: 5px;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  padding-right: 5px;
  margin-top: 10px;
`;

export const InputWrapper = styled(InputText)`
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.fill1};
`;

export const InputTextareaWrapper = styled(InputTextarea)`
  width: 100%;
  height: 150px;
  display: block;
  resize: none;
  color: ${({ theme }) => theme.fill1};
`;

export const InputTextareaContentWrapper = styled.div`
  width: 100%;
  padding-right: 5px;
  display: block;
`;
export const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.fill1};
`;

export const CalendarWrapper = styled(Calendar)`
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.fill1};
`;

export const DisplayErrorMessage = styled.div``;

export const HeaderWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  display: flex;
  gap: 5px;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: block;
  }
`;

export const DropdownWrapper = styled(Dropdown)`
  width: 100%;
  height: 40px;
  padding: 0;
  color: ${({ theme }) => theme.fill1};
`;

export const ListBoxDropdownWrapper = styled(ListBox)`
  width: 100%;
  height: 40px;
  padding: 0;
  color: ${({ theme }) => theme.fill1};
`;

export const FloatingButtons = styled.div`
  position: absolute;
  top: 2%;
  right: 4%;
`;
export const FButtonWrapper = styled.div`
  gap: 10px;
  display: flex;
`;

export const RelativeCard = styled.div`
  position: relative;
`;

export const FormCard = styled(Card)`
  width: 100%;
`;

export const CustomButton = styled(Button)<{ width: string }>`
  width: ${({ width }) => width};
  font-weight: bold;
`;

export const IdText = styled.h1`
  font-size: 18px;
`;
