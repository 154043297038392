import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { pinnedIconLabel } from "../../text";

const Pinned = ({
  colour = "white",
  size = IconSizes.SM,
}: IconSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 17 18"
    fill="none"
    aria-hidden="true"
    aria-label={pinnedIconLabel}>
    <path
      d="M9.50006 4.00006L11.0001 2.50006L14.5 6.50006L13.5 8H12.5L11.0001 10V12L10 13.0001L4.5 7.00006L6.5 6.50006L8.5 6.00006L9.50006 4.00006Z"
      fill="#EA4237"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.71424 2.80077C10.3 2.21498 11.2498 2.21498 11.8356 2.80077L12.9522 3.9174L13.4732 4.43845L14.5899 5.55508C15.1757 6.14087 15.1757 7.09062 14.5899 7.6764L13.9076 8.35868C13.6001 8.66621 13.1193 8.69488 12.7794 8.4447L11.4609 9.76324C11.3247 9.89947 11.2595 10.1299 11.3154 10.3746C11.3891 10.6971 11.4619 11.1178 11.4855 11.5902C11.5055 11.989 11.4276 12.3462 11.3173 12.6425C11.1477 13.098 10.761 13.3433 10.3499 13.3767C9.95809 13.4086 9.55849 13.2516 9.26804 12.9612L7.32824 11.0214L4.26415 14.0855C4.06888 14.2807 3.7523 14.2807 3.55704 14.0855C3.36178 13.8902 3.36178 13.5736 3.55704 13.3784L6.62113 10.3143L6.58823 10.2814L4.42947 8.1226C4.13902 7.83215 3.98207 7.43255 4.01391 7.04078C4.04733 6.62968 4.29266 6.24291 4.74817 6.07334C5.0444 5.96307 5.4016 5.88516 5.80048 5.90512C6.27283 5.92875 6.69356 6.00156 7.01606 6.07521C7.26074 6.13109 7.49117 6.06597 7.6274 5.92974L8.94594 4.6112C8.69576 4.27136 8.72443 3.79057 9.03196 3.48305L9.71424 2.80077ZM11.1285 3.50788L12.2451 4.6245L12.7661 5.14556L13.8828 6.26219C14.078 6.45745 14.078 6.77403 13.8828 6.96929L13.2865 7.56555C12.9467 7.31537 12.4659 7.34404 12.1584 7.65157L10.7538 9.05613C10.3345 9.47541 10.2215 10.076 10.3405 10.5972C10.4044 10.8769 10.4667 11.2387 10.4868 11.6401C10.4987 11.8778 10.4528 12.0985 10.3801 12.2936C10.3647 12.3351 10.3471 12.3511 10.3371 12.3584C10.3251 12.367 10.3041 12.3771 10.2688 12.38C10.1928 12.3862 10.0752 12.3541 9.97515 12.2541L7.81639 10.0953L7.29533 9.57425L5.13658 7.41549C5.0365 7.31542 5.00445 7.1978 5.01063 7.1218C5.01349 7.08654 5.02363 7.0655 5.03227 7.05358C5.03958 7.04351 5.05557 7.02595 5.09703 7.01051C5.29215 6.93788 5.51282 6.89198 5.7505 6.90387C6.15198 6.92396 6.51371 6.98623 6.79341 7.05011C7.31461 7.16914 7.91523 7.05612 8.33451 6.63684L9.73907 5.23228C10.0466 4.92475 10.0753 4.44397 9.82509 4.10413L10.4213 3.50788C10.6166 3.31261 10.9332 3.31261 11.1285 3.50788Z"
      fill={colour}
    />
  </svg>
);

export default Pinned;
