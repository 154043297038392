import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";

const TickMark = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.0877 13.3867L2.8877 9.18672L3.69395 8.38047L7.0877 11.7742L14.2877 4.57422L15.0939 5.38047L7.0877 13.3867Z"
      fill={colour}
    />
  </svg>
);

export default TickMark;
