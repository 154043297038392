import { ICompanyInfo, UserRoleEnum } from "@Types/global.types";

interface IUserData {
  role: UserRoleEnum | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  email: string | null;
  profilePictureUrl: string | null;
  userId: string | null;
  company: string | null;
}

export const getLoginUser = (): IUserData => {
  const userData = sessionStorage.getItem(process.env.USER);
  if (userData) {
    return JSON.parse(userData);
  }

  return {
    role: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    email: null,
    profilePictureUrl: null,
    userId: null,
    company: null,
  };
};

export const getCompanyInfo = (): ICompanyInfo => {
  const companyInfo = sessionStorage.getItem(process.env.CACHED_COMPANY_INFO);
  if (companyInfo) {
    return JSON.parse(companyInfo);
  }

  return {
    country: null,
    creditOrDebitCardProcessingFee: undefined,
    showLogo: false,
    companyName: null,
    postalCode: null,
    ceoPhone: null,
    showQROnReceipt: false,
    createdAt: null,
    askAdminPinForRateAndDiscount: false,
    contactNumber: null,
    addressLine1: null,
    logo: null,
    baseUnit: null,
    addressLine2: null,
    currency: {
      createdAt: null,
      symbol: null,
      code: null,
      _id: null,
      updatedAt: null,
    },
    addressLine3: null,
    ceoName: null,
    receiptFooterNote: null,
    ceoEmail: null,
    fax: null,
    email: null,
    updatedAt: null,
    companyCode: null,
    applyTaxOnWholesale: false,
    printSize: null,
    posPrintCopies: null,
    showInvoiceNumberAsBarcodeOnReceipt: false,
    quotationTermsAndCondition: null,
    _id: null,
    ceoAddress: null,
    vatNumber: null,
  } as ICompanyInfo;
};
