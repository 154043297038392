import onScan from "onscan.js";
import { getCompanyInfo } from "@Utils/get-login-user";

export const getSeverity = (
  userRole: string
): "success" | "info" | "warning" | "danger" => {
  if (userRole === "SUPERADMIN") {
    return "success";
  }
  if (userRole === "WAREHOUSE_ADMIN") {
    return "info";
  }
  if (userRole === "STORE_ADMIN") {
    return "warning";
  }
  return "danger";
};

export const handleScanBarcode = (ref: Node, cb: (code: string) => void) => {
  if (!onScan.isAttachedTo(ref)) {
    onScan.attachTo(ref, {
      suffixKeyCodes: [13], // enter-key expected at the end of a scan
      reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
      onScan(sCode) {
        cb(sCode);
      },
      onPaste(sCode) {
        cb(sCode);
      },
    });
  }
};

export const getCompanyName = () => {
  const { companyName } = getCompanyInfo();

  return companyName ?? "";
};

export const isValidEmail = (email: string) =>
  // security/detect-unsafe-regex
  // eslint-disable-next-line security/detect-unsafe-regex
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
