import React, { useState, useRef, useEffect } from "react";
import {
  DataTable,
  DataTableFilterMeta,
  DataTableRowData,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Text from "@Core/Text";
import {
  ActionContainer,
  CustomPrimeButton,
  TablePadding,
  TableWrapper,
} from "@Components/core/StyledComponents";
import CreatePurchaseInvoices from "@Components/organisms/CreatePurchaseInvoices/CreatePurchaseInvoices";
import TableSearch from "@Atoms/TableSearch";
import { IFilter, INetworkResponse, ToastLife } from "@Types/global.types";
import { FilterMatchMode } from "primereact/api";
import Modal from "@Atoms/Modal";
import { format } from "date-fns";
import { Fieldset } from "primereact/fieldset";
import { deleteRequest, getRequest } from "src/axios/requests";
import FormatCurrency from "@Atoms/FormatCurrency";
import {
  CalendarWrapper,
  PageWrapper,
  PurchaseInvoicesWrapper,
} from "./styles";

export interface IPurchaseItem {
  description: string;
  _description: string;
  rate: number;
  quantity: number;
  amount: number;
  id: string;
}
export interface IPurchaseInvoices {
  _id: string;
  name: string;
  invoiceNumber: string;
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  total: number;
  address: string;
  email: string;
  remark: string;
  date: string | Date;
  items: IPurchaseItem[];
}

export interface ICustomDate {
  fromDate: Date | null | string;
  toDate: Date | null | string;
}

interface IisDeletingType {
  state: boolean;
  _id?: string;
}
const minDate = new Date();

export const PurchaseInvoices = () => {
  const toast = useRef(null);
  const [purchaseInvoices, setPurchaseInvoices] = useState<
    IPurchaseInvoices[]
  >();
  const [expandedRows, setExpandedRows] = useState({});
  const [isDeleteLoading, setIsDeleteLoading] = useState<IisDeletingType>({
    state: false,
    _id: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [showPurchaseInvoicesForm, setShowPurchaseInvoicesForm] = useState(
    false
  );
  const [filters, setFilters] = useState<IFilter>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [formValues, setFormValues] = useState<ICustomDate>({
    fromDate: new Date(),
    toDate: new Date(),
  });
  const [selectPurchaseInvoice, setSelectPurchaseInvoice] = useState<
    IPurchaseInvoices | undefined | null
  >();

  const filterPurchaseInvoices = (path: string) => getRequest(path);
  const fetchPurchaseInvoiceReq = () => getRequest("/purchase-invoice");

  const deletePurchaseInvoices = (id: string) =>
    deleteRequest(`/purchase-invoice/${id}`);

  const onRowExpand = (event) => {
    setExpandedRows({ [event.data.id]: true });
  };

  const onRowCollapse = (event) => {
    setExpandedRows({ [event.data.id]: false });
  };

  const handleDateChange = (name: string, value: Date) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFetchPurchaseInvoices = () => {
    setLoading(true);
    fetchPurchaseInvoiceReq()
      .then((response) => {
        setPurchaseInvoices(response.data ?? []);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (toast && toast.current) {
          (toast.current as any).show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: ToastLife,
          });
        }
      });
  };

  useEffect(() => {
    handleFetchPurchaseInvoices();
  }, []);

  const handleFilterPurchaseInvoices = (path: string) => {
    setLoading(true);
    filterPurchaseInvoices(path)
      .then((response) => {
        setPurchaseInvoices(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (toast) {
          (toast.current as any).show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: ToastLife,
          });
        }
      });
  };

  const handleDeletePurchaseInvoice = (rowData: IPurchaseInvoices) => {
    setIsDeleteLoading({ _id: rowData._id, state: true });
    deletePurchaseInvoices(rowData._id)
      .then((response) => {
        handleFetchPurchaseInvoices();
        setIsDeleteLoading({ state: false });
        if (toast) {
          (toast.current as any).show({
            severity: "success",
            summary: "Success",
            detail: ((response as unknown) as INetworkResponse)?.message,
            life: ToastLife,
          });
        }
      })
      .catch((error) => {
        setIsDeleteLoading({ state: false });
        if (toast) {
          (toast.current as any).show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: ToastLife,
          });
        }
      });
  };

  const handleFilterClick = () => {
    handleFilterPurchaseInvoices(
      `/purchase-invoice/filter?startDateTime=${formValues.fromDate?.toString()}&endDateTime=${formValues.toDate?.toString()}`
    );
  };

  const refreshTableData = () => {
    handleFetchPurchaseInvoices();
  };

  const handleCancel = () => {
    setSelectPurchaseInvoice(null);
    setShowPurchaseInvoicesForm(false);
  };

  const actionButtons = (rowData: IPurchaseInvoices) => (
    <div style={{ display: "flex" }}>
      <Button
        size="small"
        type="button"
        icon="pi pi-pencil"
        severity="help"
        outlined
        onClick={() => {
          setSelectPurchaseInvoice(rowData);
          setShowPurchaseInvoicesForm(true);
        }}
        disabled={isDeleteLoading.state}
        style={{ marginRight: "5px" }}
      />
      <Button
        type="button"
        size="small"
        outlined
        icon="pi pi-trash"
        severity="danger"
        onClick={() => handleDeletePurchaseInvoice(rowData)}
      />
    </div>
  );

  const renderHeader = () => (
    <div>
      <Fieldset style={{ marginBottom: "7px" }}>
        <div style={{ display: "flex", gap: "5px" }}>
          <div style={{ marginTop: "20px" }}>
            <TableSearch filters={filters} setFilters={setFilters} />
          </div>
          <div>
            <Text noStack margin={false}>
              From date:
            </Text>
            <CalendarWrapper
              id="fromDate"
              showTime
              hourFormat="12"
              readOnlyInput
              showIcon
              placeholder="Select from date"
              name="fromDate"
              showButtonBar
              value={new Date(formValues.fromDate as string)}
              onChange={(e) => handleDateChange("fromDate", e.value as Date)}
            />
          </div>
          <div>
            <Text noStack margin={false}>
              To date:
            </Text>
            <CalendarWrapper
              id="toDate"
              readOnlyInput
              max={minDate}
              showIcon
              showTime
              showButtonBar
              hourFormat="12"
              placeholder="Select to date"
              name="toDate"
              value={new Date(formValues.toDate as string)}
              onChange={(e) => handleDateChange("toDate", e.value)}
            />
          </div>
          <div
            style={{
              marginTop: "23px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Button
              size="small"
              label="Filter Invoices"
              onClick={handleFilterClick}
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
  const header = renderHeader();

  const rowExpansionTemplate = (data: IPurchaseInvoices) => (
    <div style={{ marginBottom: "20px" }}>
      <Text h2>Invoice Items</Text>
      <DataTable showGridlines value={data.items as DataTableRowData<any>}>
        <Column
          body={(_, { rowIndex }) => rowIndex + 1}
          field="id"
          header="S/N"
          footer="S/N"
          style={{ width: "5%" }}
        />
        <Column
          field="description"
          header="Goods description"
          footer="Goods description"
        />
        <Column field="quantity" header="Qty" footer="Qty" />
        <Column field="rate" header="Rate" footer="Rate" />
        <Column field="amount" header="Amount" footer="Amount" />
      </DataTable>
    </div>
  );

  return (
    <>
      <PurchaseInvoicesWrapper>
        <ActionContainer>
          <Text h2 margin={false} align="center">
            Manage Purchase Invoices
          </Text>
          <CustomPrimeButton
            label="Add New Invoices"
            onClick={() => {
              setSelectPurchaseInvoice(null);
              setShowPurchaseInvoicesForm(true);
            }}
            severity="success"
            outlined
            raised
          />
        </ActionContainer>
        <PageWrapper>
          <TableWrapper>
            <TablePadding>
              <DataTable
                value={purchaseInvoices as DataTableRowData<any>}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                onRowExpand={onRowExpand}
                onRowCollapse={onRowCollapse}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="_id"
                tableStyle={{ minWidth: "60rem" }}
                severity="secondary"
                emptyMessage="No purchase invoice found."
                showGridlines
                // scrollHeight="400px"
                size="normal"
                rows={50}
                rowsPerPageOptions={[
                  5,
                  20,
                  50,
                  70,
                  100,
                  150,
                  200,
                  250,
                  500,
                  1000,
                ]}
                scrollable
                // size="small"
                header={header}
                loading={loading}
                filters={filters as DataTableFilterMeta}>
                <Column expander style={{ width: "5rem" }} />
                <Column
                  field="invoiceNumber"
                  header="Invoice number"
                  footer="Invoice number"
                  style={{ width: "20%" }}
                />
                <Column
                  field="name"
                  header="Name"
                  footer="Name"
                  style={{ width: "20%" }}
                />
                <Column
                  body={(row) => (
                    <>{row.date && format(new Date(row.date), "dd/MM/yyyy")}</>
                  )}
                  header="Date"
                  footer="Date"
                  style={{ width: "20%" }}
                />
                <Column
                  header="Telephone"
                  footer="Telephone"
                  style={{ width: "10%" }}
                  body={(row) => (
                    <div>
                      {row.phoneNumber && (
                        <p style={{ lineHeight: "0" }}>
                          <b>{row.phoneNumber}</b>
                        </p>
                      )}
                      {row.phoneNumber2 && (
                        <p style={{ lineHeight: "0" }}>
                          <b>{row.phoneNumber2}</b>
                        </p>
                      )}
                      {row.phoneNumber3 && (
                        <p style={{ lineHeight: "0" }}>
                          <b>{row.phoneNumber3}</b>
                        </p>
                      )}
                    </div>
                  )}
                  bodyStyle={{ minWidth: "150px" }}
                />
                <Column
                  field="email"
                  header="Email"
                  footer="Email"
                  style={{ width: "10%" }}
                />
                <Column
                  field="address"
                  header="Address"
                  footer="Address"
                  style={{ minWidth: "200px" }}
                />
                <Column
                  field="total"
                  header="Total"
                  body={(row) => <FormatCurrency inputValue={row.total} />}
                  footer="Total"
                  style={{ width: "15%" }}
                />
                <Column
                  header="Action"
                  footer="Action"
                  body={actionButtons}
                  bodyStyle={{ textAlign: "center", minWidth: "200px" }}
                />
              </DataTable>
            </TablePadding>
          </TableWrapper>
        </PageWrapper>
        <Toast ref={toast} />
      </PurchaseInvoicesWrapper>
      {showPurchaseInvoicesForm && (
        <div id="modal-create-product">
          <Modal
            isOpen={showPurchaseInvoicesForm}
            position="center"
            title={() => (
              <Text h3 noStack>
                {selectPurchaseInvoice ? "Update" : "Create"} invoice
              </Text>
            )}
            width="95%"
            isMaximized
            isMaximizable
            onCancel={handleCancel}>
            <CreatePurchaseInvoices
              selectedPurchaseInvoice={selectPurchaseInvoice}
              onCancel={handleCancel}
              refreshTableData={refreshTableData}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default PurchaseInvoices;
