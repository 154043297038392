import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const CategoriesIcon = ({
  colour = "white",
  size = IconSizes.XL,
}: IconSvgProps): ReactElement => (
  <svg
    fill={colour}
    height={size}
    width={size}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 122.88 100.16">
    <g>
      <path d="M98.74,78.39h6.32V62.24c0-0.31-0.13-0.6-0.34-0.81c-0.21-0.21-0.5-0.34-0.81-0.34l-38.58,0v17.3h6.31 c2,0,3.63,1.63,3.63,3.63v14.52c0,2-1.63,3.63-3.63,3.63l-20.51,0c-2,0-3.63-1.63-3.63-3.63V82.02c0-2,1.63-3.63,3.63-3.63l6.31,0 v-17.3H18.87c-0.31,0-0.6,0.13-0.81,0.34c-0.21,0.21-0.34,0.5-0.34,0.81v16.15h6.42c2,0,3.63,1.63,3.63,3.63v14.52 c0,2-1.63,3.63-3.63,3.63l-20.51,0c-2,0-3.63-1.63-3.63-3.63V82.02c0-2,1.63-3.63,3.63-3.63l6.23,0V62.24 c0-2.48,1.01-4.74,2.64-6.37c1.63-1.63,3.88-2.64,6.37-2.64h38.58V21.78h-6.31c-2,0-3.63-1.63-3.63-3.63V3.63 c0-2,1.63-3.63,3.63-3.63l20.51,0c2,0,3.63,1.63,3.63,3.63v14.52c0,2-1.63,3.63-3.63,3.63l-6.31,0v31.45h38.58 c2.48,0,4.74,1.01,6.37,2.64c1.63,1.63,2.64,3.89,2.64,6.37v16.15h6.33c2,0,3.63,1.63,3.63,3.63v14.52c0,2-1.63,3.63-3.63,3.63 l-20.51,0c-2,0-3.63-1.63-3.63-3.63V82.02C95.11,80.02,96.75,78.39,98.74,78.39L98.74,78.39L98.74,78.39z" />
    </g>
  </svg>
);

export default CategoriesIcon;
