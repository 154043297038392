import Text from "@Core/Text";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const LogoWrapper = styled.div`
  padding: 25px;
  margin: 30px;
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 50%;
  z-index: 999;
  font-weight: bolder;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.fill2};
  border: 3px solid ${({ theme }) => theme.text1};
`;

const Logo = () => {
  const navigate = useNavigate();

  return (
    <LogoWrapper onClick={() => navigate("/")}>
      <Text span bold>
        Push IT
      </Text>
    </LogoWrapper>
  );
};
export default Logo;
