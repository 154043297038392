import { MutableRefObject, useEffect, useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { IFilter, ToastLife } from "@Types/global.types";
import { ICategory } from "@Pages/Categories/Categories";
import { setItem } from "@Utils/cache";
import { KEYS } from "@Hooks/useCashierCache/useCashierCache";
import Alert from "@Atoms/Alert/Alert";
import { deleteRequest, getRequest } from "../../axios/requests";

interface IUseCategory {
  handleCancelEdit: () => void;
  filters: IFilter;
  deleteCategory: (rowData: ICategory) => void;
  onGlobalFilterChange: (e: { target: { value: string } }) => void;
  globalFilterValue: string;
  loading: boolean;
  toast: MutableRefObject<null>;
  setCategory: (
    value:
      | ((prevState: ICategory | null) => ICategory | null)
      | ICategory
      | null
  ) => void;
  setFilters: (value: ((prevState: IFilter) => IFilter) | IFilter) => void;
  categories: ICategory[];
  category: ICategory | null;
  isNewForm: boolean;
  setIsNewForm: (value: ((prevState: boolean) => boolean) | boolean) => void;
  handleFetchCategories: (showErrorMessage?: boolean) => void;
}

export const useCategory = (options: {
  disableAPICall: boolean;
}): IUseCategory => {
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [category, setCategory] = useState<ICategory | null>(null);
  const [loading, setLoading] = useState(true);
  const [isNewForm, setIsNewForm] = useState(true);
  const toast = useRef(null);
  const [filters, setFilters] = useState<IFilter>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const fetchCategories = () => getRequest("/categories");
  const deleteCategoryReq = (id: string) => deleteRequest(`/categories/${id}`);

  const handleFetchCategories = (showErrorMessage: boolean = true) => {
    setLoading(true);
    fetchCategories()
      .then((response) => {
        setCategories(response.data);
        setItem(KEYS.CATEGORY, JSON.stringify(response.data));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (showErrorMessage) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: ToastLife,
          });
        }
      });
  };

  const handleDeleteCategory = (id: string) => {
    setLoading(true);
    deleteCategoryReq(id)
      .then(() => {
        handleFetchCategories();
      })
      .catch((error) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: ToastLife,
        });
      });
  };

  const deleteCategory = (rowData) => {
    Alert(
      () => {
        handleDeleteCategory(rowData._id);
      },
      () => {},
      { message: "You will not be able to undo this action" }
    );
  };

  useEffect(() => {
    if (options.disableAPICall) return;
    handleFetchCategories();
  }, []);

  const handleCancelEdit = () => {
    setCategory(null);
  };

  return {
    setFilters,
    handleCancelEdit,
    toast,
    category,
    onGlobalFilterChange,
    globalFilterValue,
    categories,
    loading,
    isNewForm,
    setIsNewForm,
    filters,
    handleFetchCategories,
    setCategory,
    deleteCategory,
  };
};
