import { ReactElement } from "react";
import { IconSizes, IconSvgProps } from "@Types/global.types";
import { plusIconLabel } from "../../text";

const Plus = ({ colour, size = IconSizes.SM }: IconSvgProps): ReactElement => (
  <svg
    aria-label={plusIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}>
    <g fill={colour}>
      <path
        fill={colour}
        d="M15,7H9V1c0-0.6-0.4-1-1-1S7,0.4,7,1v6H1C0.4,7,0,7.4,0,8s0.4,1,1,1h6v6c0,0.6,0.4,1,1,1s1-0.4,1-1V9h6 c0.6,0,1-0.4,1-1S15.6,7,15,7z"
      />
    </g>
  </svg>
);

export default Plus;
