import { StyledComponentBase } from "styled-components";
import { CSSProperties, FC, ReactNode } from "react";
import { Theme, TextProps } from "@Types/global.types";
import { H1, H2, H3, H4, Span, Body, Error as Err } from "./styles";

export const Error: FC<{ children?: ReactNode }> = ({ children }) => (
  <Err>{children}</Err>
);

const Text: FC<TextProps> = ({
  children,
  margin,
  h1 = false,
  h2 = false,
  h3 = false,
  h4 = false,
  span = false,
  error = false,
  noStack = false,
  ellipsis = false,
  align,
  color,
  marginTop,
  fontSize,
  fontWeight,
  bold,
  ...otherProps
}) => {
  let VariantComponent: StyledComponentBase<
    keyof JSX.IntrinsicElements,
    Theme
  > = Body;
  if (h1) {
    VariantComponent = H1;
  } else if (h2) {
    VariantComponent = H2;
  } else if (h3) {
    VariantComponent = H3;
  } else if (h4) {
    VariantComponent = H4;
  } else if (span) {
    VariantComponent = Span;
  } else if (error) {
    VariantComponent = Err;
  }

  const styleOverrides: CSSProperties = {};
  if (bold) styleOverrides.fontWeight = 600;
  if (color) styleOverrides.color = color;
  if (align) styleOverrides.textAlign = align;
  if (margin === false) styleOverrides.margin = 0;
  if (marginTop) styleOverrides.marginTop = marginTop;
  if (fontSize) styleOverrides.fontSize = fontSize;
  if (fontWeight) styleOverrides.fontWeight = fontWeight;
  if (noStack) {
    styleOverrides.margin = 0;
    styleOverrides.lineHeight = 1.5;
  }
  if (ellipsis) {
    styleOverrides.overflow = "hidden";
    styleOverrides.maxWidth = "100%";
    styleOverrides.whiteSpace = "nowrap";
    styleOverrides.textOverflow = "ellipsis";
  }

  return (
    <VariantComponent style={styleOverrides} {...otherProps}>
      {children}
    </VariantComponent>
  );
};

export default Text;
