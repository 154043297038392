import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { loadingDotsIconLabel } from "../../text";

const LoadingDots = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={loadingDotsIconLabel}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 8"
    width="32"
    height="8">
    <circle fill={colour} stroke="none" cx="4" cy="4" r="4">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.1"
      />
    </circle>
    <circle fill={colour} stroke="none" cx="16" cy="4" r="4">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.2"
      />
    </circle>
    <circle fill={colour} stroke="none" cx="28" cy="4" r="4">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.3"
      />
    </circle>
  </svg>
);
export default LoadingDots;
