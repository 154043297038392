import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { chevronIconLabel } from "../../text";

const ChevronDown = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={chevronIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 20"
    width="16"
    height="16">
    <g fill={colour}>
      <path
        d="M14,4.586l-6,6-6-6L.586,6l6.707,6.707a1,1,0,0,0,1.414,0L15.414,6Z"
        fill={colour}
      />
    </g>
  </svg>
);

export default ChevronDown;
