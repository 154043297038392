import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { zoomIconLabel } from "../../text";

const Zoom = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={zoomIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16">
    <g strokeWidth="1" stroke="currentColor">
      <circle
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="7.5"
        cy="7.5"
        r="6"
      />
      <line
        fill="none"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="15.5"
        y1="15.5"
        x2="11.742"
        y2="11.742"
      />
    </g>
  </svg>
);

export default Zoom;
