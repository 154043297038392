/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { IButtonProps } from "./Button";

export const Button = styled.button<IButtonProps>`
  padding: ${({ theme }) => theme.space(1)} ${({ theme }) => theme.space(3)};
  border-radius: ${({ theme }) => theme.space(3)};
  display: ${({ flex }) => (flex ? "flex" : "")};
  align-items: center;
  outline: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: ${({ theme }) => theme.highlight};
  background-color: ${({ theme, checked, flex }) =>
    flex ? (checked ? theme.fillContrast1 : "#B3B3B3") : theme.highlight};
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.6;
  }

  border: ${({ theme, checked, flex }) =>
    flex ? `0.5px solid ${checked ? theme.fillContrast1 : "#B3B3B3"}` : "none"};

  ${({ variant, theme }) =>
    variant === "outlined" &&
    `
    background-color: transparent;
    color: ${theme.fillContrast1};
    border: 1px solid ${theme.fillContrast1};
  `}
`;

export const CancelButton = styled(Button)<IButtonProps>`
  background-color: ${({ theme }) => theme.fill6};
  color: ${({ variant }) => (variant === "spaced" ? "#ffffff" : "")};
  margin-right: ${({ variant, theme }) =>
    variant === "spaced" ? theme.space(3) : ""};
`;
