import { ReactElement } from "react";
import { IconSvgProps, IconSizes } from "@Types/global.types";

const LogIcon = ({
  colour = "white",
  size = IconSizes.XXL,
}: IconSvgProps): ReactElement => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 512 512"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="log-white"
        fill={colour}
        transform="translate(85.572501, 42.666667)">
        <path
          d="M236.349632,7.10542736e-15 
                        L1.68296533,7.10542736e-15 L1.68296533,234.666667 
                        L44.349632,234.666667 L44.349632,192 
                        L44.349632,169.6 L44.349632,42.6666667 
                        L218.642965,42.6666667 L300.349632,124.373333 
                        L300.349632,169.6 L300.349632,192 
                        L300.349632,234.666667 L343.016299,234.666667 
                        L343.016299,106.666667 L236.349632,7.10542736e-15 
                        L236.349632,7.10542736e-15 Z 
                        M4.26325641e-14,405.333333 
                        L4.26325641e-14,277.360521 L28.8096875,277.360521 
                        L28.8096875,382.755208 
                        L83.81,382.755208 L83.81,405.333333 
                        L4.26325641e-14,405.333333 Z M153.17,275.102708 
                        C173.279583,275.102708 188.692917,281.484792 
                        199.41,294.248958 C209.705625,306.47125 
                        214.853437,322.185625 214.853437,341.392083 
                        C214.853437,362.404792 208.772396,379.112604 
                        196.610312,391.515521 C186.134062,402.232604 
                        171.653958,407.591146 153.17,407.591146 
                        C133.060417,407.591146 117.647083,401.209062 
                        106.93,388.444896 C96.634375,376.222604 
                        91.4865625,360.267396 91.4865625,340.579271 
                        C91.4865625,319.988021 97.5676042,303.490937 
                        109.729687,291.088021 C120.266146,280.431146 
                        134.74625,275.102708 153.17,275.102708 Z 
                        M153.079687,297.680833 C142.663646,297.680833 
                        134.625833,302.015833 128.96625,310.685833 
                        C123.848542,318.512917 121.289687,328.567708 
                        121.289687,340.850208 C121.289687,355.059375 
                        124.330208,366.0775 130.41125,373.904583 
                        C136.131042,381.310208 143.717292,385.013021 
                        153.17,385.013021 C163.525833,385.013021 
                        171.59375,380.647917 177.37375,371.917708 
                        C182.491458,364.211042 185.050312,354.035833 
                        185.050312,341.392083 C185.050312,327.483958 
                        182.009792,316.616354 175.92875,308.789271 
                        C170.208958,301.383646 162.592604,297.680833 
                        153.079687,297.680833 Z M343.91,333.715521 
                        L343.91,399.011458 C336.564583,401.48 
                        331.386667,403.105625 328.37625,403.888333 
                        C319.043958,406.356875 309.019271,407.591146 
                        298.302187,407.591146 C277.229271,407.591146 
                        261.18375,402.292812 250.165625,391.696146 
                        C237.943333,380.015729 231.832187,363.729375 
                        231.832187,342.837083 C231.832187,318.813958 
                        239.418437,300.69125 254.590937,288.468958 
                        C265.609062,279.558125 280.480521,275.102708 
                        299.205312,275.102708 C315.220729,275.102708 
                        330.122292,278.022812 343.91,283.863021 
                        L334.065937,306.350833 C327.563437,303.099583 
                        321.87375,300.826719 316.996875,299.53224 
                        C312.12,298.23776 306.761458,297.590521 
                        300.92125,297.590521 C286.952917,297.590521 
                        276.657292,302.13625 270.034375,311.227708 
                        C264.435,318.934375 261.635312,329.079479 
                        261.635312,341.663021 C261.635312,356.775312 
                        265.849896,368.154687 274.279062,375.801146 
                        C281.022396,381.942396 289.391354,385.013021 
                        299.385937,385.013021 C305.226146,385.013021 
                        310.765312,384.019583 316.003437,382.032708 
                        L316.003437,356.293646 L293.967187,356.293646 
                        L293.967187,333.715521 L343.91,333.715521 Z"
          id="XLS"
        />
      </g>
    </g>
  </svg>
);

export default LogIcon;
