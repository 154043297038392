import styled from "styled-components";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ListBox } from "primereact/listbox";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

export const CreateProductContentWrapper = styled.div<{
  showProductForm: boolean;
}>``;

export const ProductForm = styled.form`
  width: 100%;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  padding: 3px 0;
  justify-content: space-between;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: block;
  }
`;

export const FieldWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-right: 5px;
`;

export const InputWrapper = styled(InputText)`
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.fill1};
`;

export const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.fill1};
`;

export const CalendarWrapper = styled(Calendar)`
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.fill1};
`;

export const DisplayErrorMessage = styled.div``;

export const ButtonWrapper = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.space(2)};
  padding: ${({ theme }) => theme.space(5)} 0;
  justify-content: center;
`;

export const FormContainer = styled.div`
  display: flex;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: block;
  }
`;

export const FormSection = styled.div`
  width: 50%;
  margin-right: 5px;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    width: 100%;
  }
`;

export const DropdownWrapper = styled(Dropdown)`
  width: 100%;
  height: 40px;
  padding: 0;
  color: ${({ theme }) => theme.fill1};
`;

export const ListBoxDropdownWrapper = styled(ListBox)`
  width: 100%;
  height: 40px;
  padding: 0;
  color: ${({ theme }) => theme.fill1};
`;

export const FloatingButtons = styled.div`
  position: absolute;
  width: 100%;
  top: 2%;
  height: 40px;
  overflow: hidden;
`;

export const FButtonWrapper = styled.div`
  gap: 10px;
  display: flex;
`;

export const FormCard = styled(Card)`
  min-height: 1000px;
`;

export const CustomButton = styled(Button)<{ width: string }>`
  width: ${({ width }) => width};
  font-weight: bold;
`;

export const CustomTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
export const CustomSpan = styled.span`
  height: 40px;
  cursor: pointer;
  font-weight: bolder;
  background-color: #6366f1;
  color: white;
`;

export const CustomTD = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const CustomTH = styled.th`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const CustomTR = styled.tr`
  tr:nth-child(even) {
    background-color: #dddddd;
  }
`;
