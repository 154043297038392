import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";

const Triangle = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    width="10"
    height="4"
    viewBox="0 0 10 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4.7538 4L0.135536 0L9.37207 0L4.7538 4Z" fill={colour} />
  </svg>
);

export default Triangle;
