import { Themes, Colour } from "@Types/global.types";

const gridSpacing = (multiplier: number): string => `${multiplier * 0.5}rem`;

// TODO: replace hard coded values with palette colors
const wellSketchLightTheme = {
  colour: {
    "base-100": "#0C0C0C",
    "base-inverted-100": "#FFFFFF",
    "well-sketch-grey-100": "#919191",
    "well-sketch-dark-grey-100": "#7A7A7A",
    "well-sketch-yellow-100": "#B08907",
    "well-sketch-green-100": "#3CA437",
    "well-sketch-blue-100": "#4F9C9C",
    "well-sketch-purple-100": "#AA78E8",
    "well-sketch-red-100": "#ED5555",
    "well-sketch-orange-100": "#E66F00",
    "well-sketch-brown-100": "#C08362",
    "pattern-primary": "#0C0C0C",
    "pattern-secondary": "rgba(12, 12, 12, 0.75)",
    "well-sketch-plot": "#ffff69",
    "well-sketch-font": "#ffffff",
    "well-sketch-background": "#0c0c0c",
    "well-sketch-plot-line": "#0c0c0c",
    "well-sketch-border": "#a4a4a4",
  },
};

const wellSketchDarkTheme = {
  colour: {
    "base-100": "#FFFFFF",
    "base-inverted-100": "#0C0C0C",
    "well-sketch-grey-100": "#BFBFBF",
    "well-sketch-dark-grey-100": "#9C9C9C",
    "well-sketch-yellow-100": "#F9DF39",
    "well-sketch-green-100": "#20B418",
    "well-sketch-blue-100": "#6FA9B3",
    "well-sketch-purple-100": "#B88CEE",
    "well-sketch-red-100": "#FF8080",
    "well-sketch-orange-100": "#FFA200",
    "well-sketch-brown-100": "#C68F71",
    "pattern-primary": "#FFFFFF",
    "pattern-secondary": "rgba(255, 255, 255, 0.75)",
    "well-sketch-plot": "#ffff69",
    "well-sketch-font": "#0c0c0c",
    "well-sketch-background": "#ffffff",
    "well-sketch-plot-line": "#0c0c0c",
    "well-sketch-border": "#e0e0e0",
  },
};

const baseTheme = {
  space: gridSpacing,
  fillContrast3: Colour.posGrey10,
  fillContrast4: Colour.posGrey11,
  fill6: Colour.posGrey5,
  success: Colour.success,
  warning: Colour.warning,
  info: Colour.info,
  modifierStroke: Colour.posGrey12,
  fill7: Colour.posGrey13,
  transparent: Colour.transparent,
};

export const themes: Themes = {
  dark: {
    ...baseTheme,
    fill1: Colour.posBlack,
    fill2: Colour.posGrey1,
    fill3: Colour.posGrey2,
    fill4: Colour.posGrey3,
    fill5: Colour.posGrey4,
    fill6: Colour.posGrey7,
    fillContrast1: Colour.posWhite,
    fillContrast2: Colour.posGrey6,
    fill10: Colour.posGrey25,
    fill17: Colour.posGrey16,
    text1: Colour.posWhite,
    text2: Colour.posGrey6,
    textContrast1: Colour.posBlack,
    textContrast2: Colour.posGrey2,
    highlight: Colour.posYellow,
    error: Colour.error,
    wellSketch: wellSketchDarkTheme,
    labelFill: Colour.posWhite,
    fill8: Colour.posGrey14,
    fill9: Colour.posGrey15,
    fill12: Colour.previewBlue,
  },
  light: {
    ...baseTheme,
    fill1: Colour.posGrey9,
    fill2: Colour.posWhite,
    fill3: Colour.posGrey7,
    fill4: Colour.posGrey8,
    fill5: Colour.posGrey6,
    fill6: Colour.posGrey7,
    fillContrast1: Colour.posBlack,
    fillContrast2: Colour.posGrey2,
    text1: Colour.posBlack,
    text2: Colour.posGrey2,
    textContrast1: Colour.posWhite,
    textContrast2: Colour.posGrey6,
    highlight: Colour.posDarkGreyBlue,
    error: Colour.posRed,
    wellSketch: wellSketchLightTheme,
    labelFill: Colour.posGrey2,
    fill8: Colour.posGrey14,
    fill9: Colour.posGrey15,
    fill10: Colour.posGrey25,
    fill17: Colour.posGrey16,
    fill12: Colour.previewBlue,
  },
};

export default themes;
