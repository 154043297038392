import Text from "@Core/Text";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { ICustomer } from "@Pages/Customer/Customer";
import Modal from "@Atoms/Modal";
import { classNames } from "primereact/utils";
import { FieldPath, SubmitHandler, useForm } from "react-hook-form";
import CashierCustomers from "@Components/molecules/CashierCustomers";
import { INetworkResponse, ToastLife } from "@Types/global.types";
import { postRequest } from "src/axios/requests";
import {
  ActionContainer,
  ButtonWrapper,
  CalendarWrapper,
  ComplainForm,
  CreateComplainWrapper,
  DisplayErrorMessage,
  FieldWrapper,
  FieldsWrapper,
  FormCard,
  FormContainer,
  FormSection,
  InputTextareaContentWrapper,
  InputTextareaWrapper,
  InputWrapper,
} from "./styles";

interface IComplainForm {
  name: string;
  phoneNumber: string;
  product: string;
  remark: string;
  dateOfPurchase: string | Date;
  invoiceNo: string;
  customerId: string;
}

interface IFormOptions {
  defaultValues: IComplainForm;
}

interface IFormValues {
  dateOfPurchase: string;
}

const initialValues = {
  name: "",
  phoneNumber: "",
  product: "",
  remark: "",
  dateOfPurchase: "",
  invoiceNo: "",
  customerId: "",
};

const initialFormValues: IFormValues = {
  dateOfPurchase: "",
};

const ComplainRegistar = () => {
  const formOptions: IFormOptions = {
    defaultValues: initialValues,
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IComplainForm>({ ...formOptions, mode: "onBlur" });

  const toast = useRef(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSelectCustomer, setIsSelectCustomer] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
  const [formValues, setFormValues] = useState<IFormValues>(initialFormValues);

  const CreateComplain = (complainData: IComplainForm) =>
    postRequest("/complains", complainData);

  const handleCreateComplain = (data: IComplainForm) => {
    setIsSaving(true);
    CreateComplain(data)
      .then((response) => {
        setIsSaving(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: ((response as unknown) as INetworkResponse).message,
          life: ToastLife,
        });
        reset();
      })
      .catch((error) => {
        setIsSaving(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: ToastLife,
        });
      });
  };

  const handleDateChange = (name: string, value: Date | null) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCustomerSelected = (customer: ICustomer) => {
    setIsSelectCustomer(false);
    setSelectedCustomer(customer);
  };

  const onSubmitHandler: SubmitHandler<IComplainForm> = (data) => {
    const complainInput = {
      ...data,
      ...formValues,
    };
    if (complainInput) {
      handleCreateComplain(complainInput as IComplainForm);
    }
  };

  useEffect(() => {
    if (selectedCustomer) {
      setValue("name" as FieldPath<IComplainForm>, selectedCustomer.name);
      setValue(
        "phoneNumber" as FieldPath<IComplainForm>,
        selectedCustomer.phoneNumber
      );
      setValue("customerId" as FieldPath<IComplainForm>, selectedCustomer._id);
    }
  }, [selectedCustomer]);

  return (
    <div>
      <div>
        <CreateComplainWrapper>
          <ActionContainer>
            <Text h2 align="center">
              Register complain
            </Text>
          </ActionContainer>
          <ComplainForm onSubmit={handleSubmit(onSubmitHandler)}>
            <FormContainer>
              <FormSection>
                <FormCard>
                  <ActionContainer>
                    <Text span noStack>
                      Fill in all (*) fields
                    </Text>
                    <Button
                      style={{ marginLeft: "auto" }}
                      label="Select customer"
                      size="large"
                      type="button"
                      severity="success"
                      onClick={() => setIsSelectCustomer(true)}
                    />
                  </ActionContainer>
                  <FieldsWrapper>
                    <FieldWrapper>
                      <Text bold noStack>
                        Name(*):
                      </Text>
                      <InputWrapper
                        id="name"
                        type="text"
                        disabled
                        readOnly
                        placeholder="Please choose or create a customer"
                        {...register("name" as FieldPath<IComplainForm>, {
                          required: "Customer name is required",
                        })}
                        aria-invalid={errors.name ? "true" : "false"}
                        className={classNames(
                          {
                            "p-invalid": errors.name?.type === "required",
                          },
                          "p-inputtext-lg"
                        )}
                      />
                      {errors.name && (
                        <DisplayErrorMessage>
                          <small className="p-error">
                            {errors.name.message}
                          </small>
                        </DisplayErrorMessage>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Text bold noStack>
                        Phone Number(*).:
                      </Text>
                      <InputWrapper
                        id="phoneNumber"
                        type="tel"
                        disabled
                        readOnly
                        placeholder="Please choose or create a customer to input phone number"
                        {...register(
                          "phoneNumber" as FieldPath<IComplainForm>,
                          {
                            required: "Phone number is required",
                          }
                        )}
                        aria-invalid={errors.phoneNumber ? "true" : "false"}
                        className={classNames(
                          {
                            "p-invalid":
                              errors.phoneNumber?.type === "required",
                          },
                          "p-inputtext-lg"
                        )}
                      />
                      {errors.phoneNumber && (
                        <DisplayErrorMessage>
                          <small className="p-error">
                            {errors.phoneNumber.message}
                          </small>
                        </DisplayErrorMessage>
                      )}
                    </FieldWrapper>
                  </FieldsWrapper>
                  <FieldsWrapper>
                    <FieldWrapper>
                      <Text bold noStack>
                        product/key note of complain(*):
                      </Text>
                      <InputWrapper
                        id="product"
                        type="text"
                        placeholder="Enter key note of complain"
                        {...register("product" as FieldPath<IComplainForm>, {
                          required: "Product name is required",
                        })}
                        aria-invalid={errors.product ? "true" : "false"}
                        className={classNames(
                          {
                            "p-invalid": errors.product?.type === "required",
                          },
                          "p-inputtext-lg"
                        )}
                      />
                      {errors.product && (
                        <DisplayErrorMessage>
                          <small className="p-error">
                            {errors.product.message}
                          </small>
                        </DisplayErrorMessage>
                      )}
                    </FieldWrapper>
                  </FieldsWrapper>
                  <FieldsWrapper>
                    <FieldWrapper>
                      <Text bold noStack>
                        Date of purchase(*):
                      </Text>
                      <CalendarWrapper
                        showIcon
                        id="dateOfPurchase"
                        placeholder="Date"
                        required
                        value={
                          formValues?.dateOfPurchase
                            ? new Date(formValues?.dateOfPurchase as string)
                            : new Date()
                        }
                        name="dateOfPurchase"
                        onChange={(e) =>
                          handleDateChange("dateOfPurchase", e.value as Date)
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <Text bold noStack>
                        Invoice number:
                      </Text>
                      <div className="p-inputgroup flex-1">
                        <InputWrapper
                          id="invoiceNo"
                          type="text"
                          placeholder="Invoice number"
                          {...register("invoiceNo" as FieldPath<IComplainForm>)}
                        />
                      </div>
                    </FieldWrapper>
                  </FieldsWrapper>
                  <FieldsWrapper>
                    <InputTextareaContentWrapper>
                      <Text bold noStack>
                        Remark(*):
                      </Text>
                      <InputTextareaWrapper
                        id="remark"
                        rows={4}
                        cols={30}
                        placeholder="Give a detail explanation of complain"
                        {...register("remark" as FieldPath<IComplainForm>, {
                          required: "Remark is required",
                        })}
                        aria-invalid={errors.remark ? "true" : "false"}
                        className={classNames(
                          {
                            "p-invalid": errors.remark?.type === "required",
                          },
                          "p-inputtext-lg"
                        )}
                      />
                      {errors.remark && (
                        <DisplayErrorMessage>
                          <small className="p-error">
                            {errors.remark.message}
                          </small>
                        </DisplayErrorMessage>
                      )}
                    </InputTextareaContentWrapper>
                  </FieldsWrapper>
                  <ButtonWrapper>
                    <Button
                      type="submit"
                      label={isSaving ? "Saving" : "Save"}
                      size="medium"
                      severity="success"
                    />
                  </ButtonWrapper>
                </FormCard>
              </FormSection>
            </FormContainer>
          </ComplainForm>
        </CreateComplainWrapper>
      </div>
      <Toast ref={toast} />

      {isSelectCustomer && (
        <div id="modal-select-customer">
          <Modal
            isOpen={isSelectCustomer}
            position="center"
            title="Select customer"
            width="80vw"
            isMaximized
            isMaximizable
            onCancel={() => setIsSelectCustomer(false)}>
            <CashierCustomers onCustomerSelected={handleCustomerSelected} />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ComplainRegistar;
