export const loadingDotsIconLabel = "Loading icon";
export const zoomIconLabel = "Zoom icon";
export const handIconLabel = "Hand icon";
export const warningIconLabel = "Warning icon";
export const autoScaleIconLabel = "Autoscale icon";
export const crosshairIconLabel = "Crosshair icon";
export const cursorValuesIconLabel = "Cursor values icon";
export const chevronIconLabel = "Chevron icon";
export const plusIconLabel = "Plus icon";
export const successIconLabel = "Success icon";
export const minusIconLabel = "Minus icon";
export const crossIconLabel = "Remove icon";
export const addCircleconLabel = "Add icon";
export const chartconLabel = "Chart icon";
export const eyeOpenLabel = "Eye Open icon";
export const eyeClosedLabel = "Eye Closed icon";
export const scissorsLabel = "Scissors icon";
export const settingsLabel = "Settings icon";
export const seriesLabel = "Series icon";
export const questionMarkLabel = "Question Mark icon";
export const chartIconLabel = "Chart icon";
export const dragHandleLabel = "Drag Handle icon";
export const checkboxIconLabel = "Checkbox icon";
export const checkboxTickedIconLabel = "Checkbox with tick icon";
export const listIconLabel = "List icon";
export const arrowRightIconLabel = "Arrow Right icon";
export const infoIconLabel = "Info icon";
export const calendarIconLabel = "Calendar icon";
export const dataExportIconLabel = "Data Export icon";
export const dragIconLabel = "Drag icon";
export const annotateIconLabel = "Annotate icon";
export const drawRangeIconLabel = "Draw range icon";
export const pinIconLabel = "Pin icon";
export const pinnedIconLabel = "Pinned icon";
