import styled from "styled-components";

export const ProductsWrapper = styled.div``;
export const ProductListsWrapper = styled.div``;
export const ProductCreateWrapper = styled.div`
  margin-bottom: 40px;
`;

export const ProductContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-device-width: 320px) {
    display: block;
    margin-bottom: 10px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: block;
    margin-bottom: 10px;
  }
  @media only screen and (min-device-width: 480px) and (max-device-width: 768px) {
    display: block;
    margin-bottom: 10px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;

  @media only screen and (max-device-width: 320px) {
    display: block;
    margin-top: 10px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: block;
    margin-top: 10px;
  }
  @media only screen and (min-device-width: 480px) and (max-device-width: 768px) {
    display: block;
    margin-top: 10px;
  }
`;

export const ScannerWrapper = styled.div`
  //width: 350px;
  padding-right: 20px;

  @media only screen and (max-device-width: 320px) {
    display: none;
    padding: 10px 0;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: none;
    padding: 25px 0;
  }
  @media only screen and (min-device-width: 480px) and (max-device-width: 768px) {
    display: none;
    padding: 25px 0;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  overflow: auto;
  height: 100%;
  gap: 15px;
  width: 100%;
  padding-top: 10px;
`;

export const AccordionPadding = styled.div`
  margin-top: 20px;
`;

export const ProductContentWrapper = styled.div<{
  showProductForm: boolean;
}>`
  display: ${({ showProductForm }) => (showProductForm ? "unset" : "none")};
`;
