import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { minusIconLabel } from "../../text";

const Minus = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={minusIconLabel}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16">
    <g fill={colour}>
      <line y1="8.5" x2="16" y2="8.5" stroke="white" strokeWidth="2" />
    </g>
  </svg>
);

export default Minus;
