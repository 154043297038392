import { ReactElement } from "react";
import { IconSvgProps } from "@Types/global.types";
import { chevronIconLabel } from "../../text";

const ChevronLeft = ({ colour }: IconSvgProps): ReactElement => (
  <svg
    aria-label={chevronIconLabel}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <path fill={colour} d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
  </svg>
);

export default ChevronLeft;
